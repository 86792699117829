import moment from 'moment';
import { OpenSeaAsset, OpenSeaEvent, WyvernSellOrder, SeaportSellOrder, SellOrder } from './opensea';
import vhttp from './vhttp';

export type ItemPriceData =
  | [[number, number?], number] // ETH basic
  | [[number, number?], number, string] // non-ETH basic (WETH = auctions)
  | [[number, number], [number, number]] // Dutch ETH [begin, end], []
  | [[number, number], [number, number], string]; // Dutch non-ETH

export interface ProjectPriceData {
  prices: {
    [tokenId: string]: ItemPriceData;
  };
  startDate: number;
  v: number;
}

export interface InterpretedPriceData {
  price_eth: number; // price value in ETH
  price: number; // current price
  price_begin: number; // begin price
  price_end?: number;
  symbol?: string; // will be set if not ETH
  type: number; // 0 = basic, 1 = dutch, 2 = english
  begin: number; // begin date ms
  end?: number; // end date ms
}

export function newProjectPriceData() {
  return {
    prices: {},
    startDate: moment('2020-01-01', 'YYYY-MM-DD').unix(),
    v: 0,
  };
}

var ethPerSymbols: {
  [symbol: string]: number;
} = {};

export async function getETHPerSymbol(symbol: string) {
  if (!ethPerSymbols[symbol]) {
    var result = (await vhttp.get(`https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=ETH`)).data?.ETH;
    if (result) {
      ethPerSymbols[symbol] = result;
    } else {
      ethPerSymbols[symbol] = -1;
    }
  }
  return ethPerSymbols[symbol];
}

export function isAuction(priceData: ItemPriceData) {
  return priceData[2] == 'WETH';
}

function convertDecimals(quantity: string, decimals: number) {
  return Number.parseInt(quantity) / Math.pow(10, decimals);
}

export function isWyvernOrder(sellOrder: SellOrder): sellOrder is WyvernSellOrder {
  return 'payment_token_contract' in sellOrder;
}

export function isSeaportOrder(sellOrder: SellOrder): sellOrder is SeaportSellOrder {
  return !('payment_token_contract' in sellOrder);
}

export function extractFirstSellOrderFromAsset(asset: OpenSeaAsset): SellOrder | null {
  if (asset.seaport_sell_orders?.length > 0) {
    return asset.seaport_sell_orders[0];
  }

  if (asset.sell_orders?.length > 0) {
    return asset.sell_orders[0];
  }

  return null;
}

export interface NormalizedSellOrder {
  listing_begin: Date;
  listing_end: Date | null;
  sale_kind: number;
  price: number;
  symbol: string;
  extra?: number | null;
}

export function normalizeWyvernOrder(sell_order: WyvernSellOrder): NormalizedSellOrder {
  const { listing_time, created_date, closing_date, base_price, sale_kind, extra, payment_token_contract } = sell_order;

  const listing_begin = listing_time ? new Date(listing_time * 1000) : moment(created_date + 'Z').toDate();
  const listing_end = closing_date ? moment(closing_date + 'Z').toDate() : null;

  const { symbol, decimals = 0 } = payment_token_contract;

  return {
    listing_begin,
    listing_end,
    sale_kind,
    price: convertDecimals(base_price, sell_order.payment_token_contract?.decimals || 0),
    symbol,
    extra: extra ? convertDecimals(extra, decimals) : null,
  };
}

const ADDRESS_TO_SYMBOL: Record<string, string> = {
  '0x0000000000000000000000000000000000000000': 'ETH',
  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2': 'WETH',
  '0x4d224452801ACEd8B2F0aebE155379bb5D594381': 'APE',
  '0x0D8775F648430679A709E98d2b0Cb6250d2887EF': 'BAT',
  '0x64d91f12ece7362f91a6f8e7940cd55f05060b92': 'ASH',
  '0x9355372396e3f6daf13359b7b607a3374cc638e0': 'WHALE',
  '0xdf801468a808a32656d2ed2d2d80b72a129739f4': 'CUBE',
  '0x6B175474E89094C44Da98b954EedeAC495271d0F': 'DAI',
  '0x15D4c048F83bd7e37d49eA4C83a07267Ec4203dA': 'GALA',
};

const ORDER_TYPE_TO_SALE_KIND: Record<string, number> = {
  basic: 0,
  dutch: 1,
  english: 2,
};

export function normalizeSeaportOrder(
  sell_order: SeaportSellOrder,
  logError?: (error: Error) => void
): NormalizedSellOrder | null {
  const { listing_time, created_date, closing_date, order_type, current_price, protocol_data } = sell_order;

  const listing_begin = listing_time ? new Date(listing_time * 1000) : moment(created_date + 'Z').toDate();
  const listing_end = closing_date ? moment(closing_date + 'Z').toDate() : null;

  const payingAssetAddress = protocol_data.parameters?.consideration[0]?.token;

  if (!payingAssetAddress) {
    return null;
  }

  const symbol = ADDRESS_TO_SYMBOL[payingAssetAddress];

  if (!symbol) {
    logError?.(new Error(`Could not map symbol for token with address ${payingAssetAddress}`));
  }

  return {
    listing_begin,
    listing_end,
    sale_kind: ORDER_TYPE_TO_SALE_KIND[order_type] ?? undefined,
    price: convertDecimals(current_price, 18), // Is there a list of decimals based o the token?
    symbol,
  };
}

interface GetInterpretedPriceDataArgs {
  end?: number;
  sale_kind?: number;
  begin: number;
  symbol: string;
  startingPrice: number;
  endingPrice: number;
}

function getInterpretedPriceData({
  end,
  begin,
  symbol,
  sale_kind,
  startingPrice,
  endingPrice,
}: GetInterpretedPriceDataArgs): InterpretedPriceData | null {
  const now = new Date().valueOf();

  if ((end && now > end) || startingPrice < endingPrice) {
    return null;
  }

  const actualSymbol = symbol !== 'ETH' ? symbol : undefined;
  const isETH = symbol == 'ETH';

  // English Auction
  if (startingPrice === endingPrice) {
    // If there is a sale_kind, use it (but this attribute does not exists on events, only on listing)
    const type = sale_kind !== undefined ? sale_kind : actualSymbol === 'WETH' ? 2 : 0;

    return {
      begin,
      end,
      type,
      price: startingPrice,
      price_begin: startingPrice,
      price_eth: type == 0 && isETH ? startingPrice : 0,
      symbol: isETH ? symbol : undefined,
    };
  }

  // Dutch Auction

  if (!end) {
    return null;
  }

  const currentPrice = startingPrice - (startingPrice - endingPrice) * Math.min((now - begin) / (end - begin), 1);

  return {
    price_eth: isETH ? currentPrice : 0,
    price: currentPrice,
    price_begin: startingPrice,
    price_end: endingPrice,
    type: sale_kind !== undefined ? sale_kind : 1, // use 1 as default for Dutch Auctions
    begin,
    end,
  };
}

export function interpretPriceFromOpenSeaAsset(asset: OpenSeaAsset): InterpretedPriceData | null {
  const sellOrder = extractFirstSellOrderFromAsset(asset);

  if (!sellOrder) {
    return null;
  }

  const normalizedOrder = isWyvernOrder(sellOrder) ? normalizeWyvernOrder(sellOrder) : normalizeSeaportOrder(sellOrder);

  if (!normalizedOrder) {
    return null;
  }

  const { listing_begin, listing_end, price, sale_kind, symbol, extra } = normalizedOrder;

  return getInterpretedPriceData({
    end: listing_end?.valueOf(),
    begin: listing_begin.valueOf(),
    symbol,
    sale_kind,
    startingPrice: price,
    endingPrice: price - (extra || 0),
  });
}

export function interpretPriceFromOpenSeaEvent(
  event: OpenSeaEvent,
  logError?: (error: Error) => void
): InterpretedPriceData | null {
  if (!event.payment_token) {
    return null;
  }

  const startingPrice = convertDecimals(event.starting_price, event.payment_token.decimals);
  const endingPrice = convertDecimals(event.ending_price, event.payment_token.decimals);

  if (endingPrice > startingPrice) {
    logError?.(
      new Error(`Unhandled event for token ${event.asset?.token_id} of contract ${event.asset?.asset_contract.address}`)
    );
    return null;
  }

  const begin = new Date(event.created_date + 'Z').valueOf();

  return getInterpretedPriceData({
    end: event.duration ? begin + parseInt(event.duration) * 1000 : undefined,
    begin,
    symbol: event.payment_token.symbol,
    startingPrice,
    endingPrice,
  });
}

export function interpretPriceFromPriceData(startDate: number, priceData: ItemPriceData): InterpretedPriceData | null {
  if (!priceData) return null;

  var begin = (priceData[0][0] + startDate) * 1000;
  var end: number | undefined;

  if (priceData[0][1]) end = (priceData[0][0] + priceData[0][1]! + startDate) * 1000;

  if (priceData.length == 2 && typeof priceData[1] == 'number') {
    // ETH basic
    return {
      price_eth: priceData[1],
      price: priceData[1],
      price_begin: priceData[1],
      type: 0,
      begin,
      end,
    };
  }
  if (priceData.length == 3 && typeof priceData[1] == 'number' && typeof priceData[2] == 'string') {
    // non-ETH basic (WETH = auctions)
    var symbol = priceData[2];
    return {
      price_eth: 0, //symbol == 'WETH' ? priceData[1] : 0,
      price: priceData[1],
      price_begin: priceData[1],
      type: symbol == 'WETH' ? 2 : 0,
      symbol,
      begin,
      end: (priceData[0][0] + priceData[0][1]! + startDate) * 1000,
    };
  }
  if (end && (priceData.length == 2 || (priceData.length == 3 && typeof priceData[2] == 'string'))) {
    var isETH = priceData.length == 2;
    // Dutch ETH [begin, end]
    var now = new Date().valueOf();
    var priceBegin: number = (priceData as any)[1][0];
    var priceEnd: number = (priceData as any)[1][1];
    if (end - begin > 0) {
      /*
			console.log('--------------------------------')
			console.log('begin is ' + moment(begin).format())
			console.log('end is ' + moment(end).format())
			console.log('now - begin = ' + (now - begin))
			console.log('end - begin = ' + (end - begin))
			console.log('(now - begin) / (end - begin) = ' + (now - begin) / (end - begin))
			console.log('Math.min((now - begin) / (end - begin), 1) = ' + Math.min((now - begin) / (end - begin), 1))
			console.log('price is ' + priceBegin)
			console.log('priceEnd is ' + priceEnd)
			console.log('price - priceEnd is ' + (priceBegin - priceEnd))
			console.log('(price - priceEnd) * ratio result =' + (priceBegin - priceEnd) * Math.min((now - begin) / (end - begin), 1))
			console.log('--------------------------------')
			*/

      var currentPrice = priceBegin - (priceBegin - priceEnd) * Math.min((now - begin) / (end - begin), 1);
      var res: InterpretedPriceData = {
        price_eth: isETH ? currentPrice : 0,
        price: currentPrice,
        price_begin: priceBegin,
        price_end: priceEnd,
        type: 1,
        begin,
        end,
      };
      if (!isETH) {
        res.symbol = priceData[2];
      }
      return res;
    }
  }
  return null;
}
