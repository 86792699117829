import vhttp from './vhttp';

var queryBegin = `
query GetAxieDetail(`;

var queryEnd = `
}

fragment AxieDetail on Axie {
	id
	name
	owner
	auction {
		currentPrice
		currentPriceUSD
		startingTimestamp
	}
	ownerProfile {
		name
	}
}
`;

// var query = `
// query GetAxieDetail($axieId1: ID!, $axieId2: ID!) {
// 	axie_1: axie(axieId: $axieId1) {
// 		...AxieDetail
// 	}
// 	axie_2: axie(axieId: $axieId2) {
// 		...AxieDetail
// 	}
// }

// fragment AxieDetail on Axie {
// 	id
// 	name
// 	owner
// 	auction {
// 		currentPrice
// 		currentPriceUSD
// 		startingTimestamp
// 		endingTimestamp
// 	}
// 	ownerProfile {
// 		name
// 	}
// }
// `;

export interface AxieDetail {
  id: string;
  name: string;
  owner: string;
  auction?: {
    currentPrice: string;
    startingTimestamp: string;
    endingTimestamp: string;
  };
  ownerProfile: {
    name: string;
  };
}

export async function getAxieIds(ids: string[]) {
  var postUrl = 'https://graphql-gateway.axieinfinity.com/graphql';
  var operationName = 'GetAxieDetail';
  var useQuery = queryBegin;
  var variables: any = {};
  var paramsPart = '';
  var axiesPart = '';

  for (var id of ids) {
    axiesPart += `
			axie_${id}: axie(axieId: $axieId${id}) {
				...AxieDetail
			}`;
    if (paramsPart != '') paramsPart += ', ';
    paramsPart += `$axieId${id}: ID!`;
    variables[`axieId${id}`] = id;
  }
  useQuery += paramsPart + ') {' + axiesPart + queryEnd;

  //console.log(useQuery)
  //console.log(variables)

  var results = await vhttp.post(postUrl, {
    operationName,
    query: useQuery,
    variables,
  });
  //console.log(JSON.stringify(results.data, undefined, "\t"))

  var returnVal: { [id: string]: AxieDetail } = {};
  if (results.data?.data) {
    for (var alias in results.data.data) {
      var axie: AxieDetail = results.data.data[alias];
      if (axie) returnVal[axie.id] = axie;
    }
  }
  return returnVal;
}

/////////////////////////////////////////////////////////////////////

// var priceQuery = `
// query GetAxieBriefList($auctionType: AuctionType, $criteria: AxieSearchCriteria, $from: Int, $sort: SortBy, $size: Int, $owner: String) {
// 	axies(auctionType: $auctionType, criteria: $criteria, from: $from, sort: $sort, size: $size, owner: $owner) {
// 		total
// 		results {
// 			...AxieDetail
// 		}
// 	}
// }

// fragment AxieDetail on Axie {
// 	id
// 	name
// 	owner
// 	auction {
// 		currentPrice
// 		currentPriceUSD
// 		startingTimestamp
// 		endingTimestamp
// 	}
// 	ownerProfile {
// 		name
// 	}
// }
// `;

export async function getOriginAxiesForSale(): Promise<AxieDetail[]> {
  var size = 100;
  // var postUrl = 'https://axieinfinity.com/graphql-server-v2/graphql';
  // var operationName = 'GetAxieBriefList';
  // var variables = {
  //   from: 0,
  //   size: size,
  //   sort: 'IdAsc',
  //   auctionType: 'Sale',
  //   owner: null,
  //   criteria: {
  //     region: null,
  //     parts: null,
  //     bodyShapes: null,
  //     classes: null,
  //     stages: null,
  //     numMystic: null,
  //     pureness: null,
  //     title: ['Origin'],
  //     breedable: null,
  //     breedCount: null,
  //     hp: [],
  //     skill: [],
  //     speed: [],
  //     morale: [],
  //   },
  // };

  var from = 0;
  // var totalCount = 0;
  var axies: any[] = [];
  while (true) {
    var url = `https://axieinfinity.com/graphql-server-v2/graphql?query={axies(auctionType:Sale,criteria:{title:[%22Origin%22]},from:${from},sort:IdAsc,size:100,owner:null){total,results{...AxieBrief}}}fragment%20AxieBrief%20on%20Axie{id,name,owner,auction{currentPrice,currentPriceUSD,startingTimestamp,endingTimestamp}ownerProfile{name}}`;
    var results = await vhttp.get(url, {
      headers: {
        'User-Agent': 'Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.0)',
      },
    });
    var newAxies: any[] = results.data.data.axies.results;
    for (var axie of newAxies) {
      axies.push(axie);
    }
    // totalCount += newAxies.length;
    if (newAxies.length < size) break;
    from += size;
  }
  return axies;
}
