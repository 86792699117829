


























































import AppVue from '@/components/AppVue';
import { Component, Prop, Watch } from 'nuxt-property-decorator';

// import Web3 from 'web3'

@Component({})
export default class Paging extends AppVue {
  @Prop()
  totalPages!: number;

  @Prop()
  value!: number;

  @Prop()
  showLeftSide?: boolean;

  @Prop()
  emitOnNextPage?: boolean;

  pageText: string = '1';

  mouted() {
    this.pageText = this.value.toString();
  }

  updated() {
    this.pageText = this.value.toString();
  }

  prevPage() {
    this.gotoPage(this.value - 1);
  }

  nextPage() {
    if (this.emitOnNextPage) this.$bus.$emit('nextPage');
    this.gotoPage(this.value + 1);
  }

  gotoPage(page: number) {
    if (!this.project) return;
    var newPage = page;
    if (newPage < 1) newPage = 1;
    if (newPage > this.totalPages) newPage = this.totalPages;

    this.$emit('input', newPage);
  }

  get firstPages() {
    var usableCount = Math.min(Math.floor(this.totalPages / 2), 3);
    var result: number[] = [];
    for (var i = 1; i <= usableCount; i++) result.push(i);
    return result;
  }

  get lastPages() {
    var usableCount = Math.min(this.totalPages - this.firstPages.length, 3);
    var result: number[] = [];
    for (var i = this.totalPages - usableCount + 1; i <= this.totalPages; i++) result.push(i);
    return result;
  }

  get isFirstPage() {
    if (!this.project) return true;

    return this.value == 1;
  }

  get isLastPage() {
    if (!this.project) return true;

    return this.value == this.totalPages;
  }

  @Watch('pageText')
  onPageChange(newValue: number, oldValue: number) {
    if (newValue != oldValue) {
      var page = parseInt(this.pageText);
      if (page >= 1 && page <= this.totalPages) this.gotoPage(page);
    }
  }
}
