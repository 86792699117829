import { CalculationMethod } from './CalculationMethod';

export class RarityScoreCalculationMethod extends CalculationMethod {
  public rank() {
    //console.log('RarityScoreCalcMethod rank')
    this.prepareWeightsAndPropValueScores();
    this.calculateItemScores();
    this.sortByScoresDescending();
    this.assignRanks();
  }

  // normalize uniqueness weight back
  protected get uniquenessWeightMultiplier() {
    return this.uniquenessWeight / this.uniquenessRawTotalWeight;
  }

  protected calculatePropValueScore(valueCount: any, pvCount: number, weight: number): number {
    if (this.totalWeight == 0) return 0;
    var score = 1 / valueCount;
    if (this.options.normalize) {
      score = (score * 1000000) / (this.totalWeight * pvCount);
    } else {
      score *= this.itemCount; // old version non-normalized
    }
    return (score *= weight);
  }

  protected initialScore(): number {
    return 0;
  }
  protected accumulateScore(accumulatedScore: number, newScore: number): number {
    return accumulatedScore + newScore;
  }
  protected finalizeScore(accumulatedScore: number): number {
    return accumulatedScore;
  }

  protected mergeUniquenessScore(normalScore: number, uniquenesScore: number): number {
    return normalScore + uniquenesScore;
  }
}

/*
normalizing rarity score:
total / (no. matchIds * no. items) = 1

meaning we need to divide score by
( [no. of props] + [no. of combination matches] ) * no. items

or rather, we need to divide score by
( weights total ) * no. items

but the no.items part can be cancelled out, so we just divide by
( weights total )

*/
