




























































































































import AppVue from '@/components/AppVue';
import { Component } from 'nuxt-property-decorator';
import { Project, ExploredTraitValue } from '@/lib/rarity';

@Component({})
export default class ExploreTraits extends AppVue {
  sortMode = 'count';

  mounted() {}
  get exploredTraitValues() {
    if (this.project && this.exploringTrait) {
      return this.project.getExploredTraitValues(this.exploringTrait, this.sortMode);
    }
  }

  get hasScore() {
    if (this.project && this.exploringTrait == 'Top Traits') return true;
    if (!this.project || !this.exploringTrait) return false;
    if (this.project.calcOptions?.method != 'score') return false;
    return this.project.getPropWeight(this.project.getPropDef(this.exploringTrait!)!) > 0;
  }

  get showExamples() {
    if (this.exploredTraitValues?.length)
      return this.exploredTraitValues.length < Project.MAX_EXPLORE_TRAIT_VALUES_SHOW;
    return false;
  }

  goto(row: ExploredTraitValue) {
    if (this.project) {
      this.$set(this.project, 'filtersActive', {});
      this.$set(this.project.filtersActive, row.propName, {});
      this.$set(this.project.filtersActive[row.propName], row.valueIndex, true);
      this.checkApplyUrlFromFilters();
      // and go to the project

      var query = this.project.queryParamsOverridedWith({});

      this.$router.push({
        path: `/${this.projectId}`,
        query,
      } as any);

      this.$bus.$emit('eventNFTsFiltered', this.project, this.projectCollection?.details?.blockchain);
    }
  }
}
