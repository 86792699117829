var Vue: any = undefined;
var vlog: any = undefined;

try {
  Vue = require('vue').default;
} catch (e) {}
try {
  vlog = require('../vlog').default;
} catch (e) {}

// imports

var set = (obj: any, prop: any, value: any) => {
  obj[prop] = value;
};
var del = (obj: any, prop: any) => {
  delete obj[prop];
};
if (Vue) {
  set = Vue.set;
  del = Vue.delete;
}

var debug = (input: any) => {
  console.log(input);
};
var warn = (input: any) => {
  console.warn(input);
};
var error = (input: any) => {
  console.error(input);
};
var logError = (input: any) => {
  console.error(input);
};
var info = (input: any) => {
  console.info(input);
};

var isServer = false;

if (vlog) {
  debug = vlog.debug;
  warn = vlog.warn;
  error = vlog.error;
  info = vlog.info;
  logError = vlog.logError;
  isServer = true;
}

export { set, del, debug, warn, error, logError, info, isServer };
