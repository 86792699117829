



























































import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';
import { BasePropDef } from '@/lib/rarity';

// import Web3 from 'web3'

@Component({})
export default class NFTImage extends AppVue {
  @Prop()
  nftId!: string;

  @Prop({ default: 1 })
  scale!: number;

  @Prop({ default: null })
  aspectRatioPropDef!: BasePropDef;

  get link() {
    return this.linkToNFT(this.nftId);
  }

  get imgWidth(): number {
    if (this.project) {
      var result = this.project.config.images.sizeThumb[0] * this.scale;
      return result;
    }
    return 0;
  }

  get imgHeight(): number {
    if (this.project) {
      var result = this.project.config.images.sizeThumb[1] * this.scale;
      return result;
    }
    return 0;
  }

  get imgWidthAspected(): number {
    if (this.project) {
      var result = this.project.config.images.sizeThumb[0] * this.scale;
      if (this.aspectRatioPropDef) {
        var aspectRatio = this.project.getPropertyEncodedValue(
          this.aspectRatioPropDef,
          this.project.itemLookup[this.nftId],
        );
        if (aspectRatio) {
          if (aspectRatio < 1) {
            // it's tall, need to reduce width
            return this.imgHeight * aspectRatio;
          }
        }
      }
      return result;
    }
    return 0;
  }

  get imgHeightAspected(): number {
    if (this.project) {
      var result = this.project.config.images.sizeThumb[1] * this.scale;
      if (this.aspectRatioPropDef) {
        var aspectRatio = this.project.getPropertyEncodedValue(
          this.aspectRatioPropDef,
          this.project.itemLookup[this.nftId],
        );
        if (aspectRatio) {
          if (aspectRatio > 1) {
            // it's wide, need to reduce height
            return this.imgWidth * (1 / aspectRatio);
          }
        }
      }
      return result;
    }
    return 0;
  }

  get imgStyle() {
    if (this.project) {
      var imgPart = `width: ${this.imgWidthAspected}px; height: ${this.imgHeightAspected}px`;
      if (this.project.config.images.keepAspectRatio) {
        imgPart = `height: ${this.imgHeightAspected}px`;
      }
      return (
        imgPart +
        (this.project.config.images.bgColor ? `; background-color: ${this.project.config.images.bgColor}` : '')
      );
    }
    return '';
  }

  get imagesBgColor() {
    if (this.project) {
      if (this.project.config.images.bgColor) {
        return `background-color: ${this.project.config.images.bgColor}`;
      } else if (this.project.config.images.useBgColorData) {
        var propDef = this.project.getPropDef('bgColor');
        var id = this.nftId;
        var arrayItem = this.project.itemLookup[id];
        var bgColor = this.project.getPropertyEncodedValue(propDef!, arrayItem);
        return `background-color: #${bgColor}`;
      }
    }
    return '';
  }
}
