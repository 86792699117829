//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { Blockchain } from '~/lib/rarity';
import SolanaLogo from '../assets/solana.svg?inline';
import PolygonLogo from '../assets/polygon.svg?inline';

export default {
  props: {
    type: Blockchain,
  },

  components: {
    SolanaLogo,
    PolygonLogo,
    EthereumLogo: Vue.component('EthereumLogo', {
      render(createElement) {
        return createElement('img', { attrs: { src: require('~/assets/ethereum.png') } });
      },
    }),
  },

  computed: {
    displayName() {
      if (this.type.toLowerCase() === 'matic') {
        return Blockchain.POLYGON;
      }
      return this.type;
    },

    currentLogo() {
      switch (this.type.toLowerCase()) {
        case Blockchain.ETHEREUM.toLowerCase():
          return 'EthereumLogo';
        case Blockchain.SOLANA.toLowerCase():
          return 'SolanaLogo';
        case Blockchain.POLYGON.toLowerCase():
        case 'matic':
          return 'PolygonLogo';
        default:
          return '';
      }
    },
  },
};
