import { CalculationMethod } from './CalculationMethod';

export class AverageTraitRarityCalculationMethod extends CalculationMethod {
  public rank() {
    //console.log('AverageTraitRarityCalcMethod rank')
    this.prepareWeightsAndPropValueScores();
    this.calculateItemScores();
    this.sortByScoresAscending();
    this.assignRanks();
  }

  protected get uniquenessWeightMultiplier(): number {
    return 1;
  }

  protected calculatePropValueScore(valueCount: number, pvCount: number, weight: number): number {
    var score = valueCount / this.itemCount;
    if (this.options.normalize) {
      score = score / pvCount;
    }
    score = score * (weight * weight);
    return score;
  }

  count = 0;
  protected initialScore(): number {
    this.count = 0;
    return 0;
  }
  protected accumulateScore(accumulatedScore: number, newScore: number): number {
    this.count++;
    return accumulatedScore + newScore;
  }
  protected finalizeScore(accumulatedScore: number): number {
    return accumulatedScore / this.count;
  }

  itemsc = 0;
  protected mergeUniquenessScore(normalScore: number, uniquenesScore: number): number {
    this.itemsc++;

    var result =
      (normalScore * (this.totalWeight - this.uniquenessWeight) +
        uniquenesScore * (this.uniquenessWeight * this.uniquenessWeight)) /
      this.totalWeight;
    if (this.itemsc < 10) console.log('normal score ' + normalScore + ' result ' + result);
    return result;
  }
}
