export enum Blockchain {
  ETHEREUM = 'ETHEREUM',
  POLYGON = 'POLYGON',
  SOLANA = 'SOLANA',
}

export type OpenSeaStats = {
  one_day_volume: number;
  one_day_sales: number;
  one_day_change: number;
  one_day_average_price: number;
  seven_day_volume: number;
  seven_day_change: number;
  seven_day_sales: number;
  seven_day_average_price: number;
  total_volume: number;
  total_sales: number;
  total_supply: number;
  num_owners: number;
  average_price: number;
  floor_price: number;
  market_cap: number;

  // runtime
  owner_percent?: number;
  est_market_cap?: number;
  added?: any; // (moment)
};

export type MetaImageExtensions = [string | null, string | null, string | null];

export type ExternalIds = {
  openSea?: {
    slug: string;
  };
};

export interface CollectionData {
  slug: string; // Slug which we use to reference item in our system; consumer of this data should try to use the openSeaSlug first and then default to this if that does not exists (when fetching from OpenSea)
  openSeaSlug?: string; // This should be undefined when our slug and OpenSea slug matches, but should exists if they differ (i.e. OpenSea slug has been updated)
  seven_day_volume?: number;
  image_url: string;

  contracts?: {
    contract: string;
    tokenStandard: 'ERC721' | 'ERC1155';
  }[];
  stats?: OpenSeaStats;
  details?: {
    name: string;
    blockchain: Blockchain;
    externalIds?: ExternalIds;
    chainProps?: unknown;
    created_date: string;
    description?: string;
    discord_url?: string;
    external_url?: string;
    large_image_url?: string;
    banner_image_url?: string;
    medium_username?: string;
    twitter_username?: string;
    instagram_username?: string;
  };

  // runtime
  added?: string;

  images?: MetaImageExtensions;
}

export interface ImgFileContract {
  contract: string;
  baseUrl?: string;
  ext?: string;
  indexPadZeroes?: number;
  commonUrl?: string;
  urls?: { [tokenId: string]: string };
}

/**
 * The image file is constructed based on the original images url from the contracts.
 */
export interface ImgFile {
  contractImgs: { [contract: string]: ImgFileContract };
}

export type ImageSrc = string;
export type VideoSrc = { poster: string; animation: string };

export type MediaSrc = ImageSrc | VideoSrc;

export function isVideoSrc(mediaSrc: MediaSrc): mediaSrc is VideoSrc {
  return typeof mediaSrc === 'object' && Boolean(mediaSrc.animation);
}

/**
 * The media file is what will be used by the frontend to resolve items media urls.
 * These will point to urls from our CDN. It ressembles the ImgFile, and will eventually
 * replace it.
 */
export interface MediaFile {
  // This is set if all images share the same extension
  extension?: string;

  // This is set if images have different extensions
  urls?: {
    [tokenId: string]: MediaSrc;
  };
}

export function getImgFromImgFile(imgFile: ImgFile, contract: string, tokenId: string) {
  if (imgFile && imgFile.contractImgs && imgFile.contractImgs[contract]) {
    var contractImgs = imgFile.contractImgs[contract];
    if (contractImgs.baseUrl) {
      var imgIndex = tokenId;
      if (contractImgs.indexPadZeroes) {
        imgIndex = imgIndex.padStart(4, '0');
      }
      return contractImgs.baseUrl + imgIndex + contractImgs.ext;
    } else if (contractImgs.urls) {
      var prefix = contractImgs.commonUrl || '';
      var mainPart = contractImgs.urls[tokenId];
      if (mainPart) {
        return prefix + mainPart;
      }
    }
  }
  return undefined;
}

export interface Upcoming2Record {
  id: string;
  Project: string;
  'Image Count': number;
  'Short Description': string;
  'Featured Description': string;
  Featured?: number;
  'Max Items Text'?: string;
  'Max Items'?: string;
  'Price Text'?: string;
  Price?: number;
  Currency?: string;
  'Sale Date Text'?: string;
  'Presale Date'?: string;
  'No Presale Time'?: boolean;
  'Sale Period'?: string;
  'Sale Month'?: string;
  'Sale Year'?: string;
  'Sale Date'?: string;
  'No Sale Time'?: boolean;
  Website?: string;
  Discord?: string;
  TwitterId?: string;
  'Listed Date'?: string;
}

export interface InvoiceRecord {
  id: string;
  createdAt: string;
  status: string;
  toAddress: string;
  customer: string;
  amount: number;
  asset?: string;
  forLine: string;
}
