import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

/// wrapping needed due to: https://github.com/axios/axios/issues/647, https://stackoverflow.com/questions/36690451/timeout-feature-in-the-axios-library-is-not-working
/// use setDefaultTimeout first

var defaultTimeout = 0;

/// fixes axios timeout, set timeout in config
export async function get<T = any>(url: string, config?: AxiosRequestConfig) {
  return await (async () => {
    const source = axios.CancelToken.source();
    var response: AxiosResponse<T> | null = null;
    if (!config) config = {};
    if (typeof config.timeout == 'undefined') {
      config.timeout = defaultTimeout;
    }
    var timeout = config.timeout;
    delete config.timeout;

    if (timeout !== 0) {
      config.cancelToken = source.token;
      setTimeout(() => {
        if (response === null) {
          source.cancel('Request timed out: GET ' + url);
        }
      }, timeout);
    }
    response = await axios.get<T>(url, config);
    if (!response) throw new Error('No response: GET ' + url);
    return response;
  })();
}

/// fixes axios timeout, set timeout in config
export async function post(url: string, data: any, config?: AxiosRequestConfig) {
  return await (async () => {
    const source = axios.CancelToken.source();
    var response: AxiosResponse | null = null;
    if (!config) config = {};
    if (typeof config.timeout == 'undefined') {
      config.timeout = defaultTimeout;
    }
    var timeout = config.timeout;
    delete config.timeout;

    if (timeout !== 0) {
      config.cancelToken = source.token;
      setTimeout(() => {
        if (response === null) {
          source.cancel('Request timed out: POST ' + url);
        }
      }, timeout);
    }
    response = await axios.post(url, data, config);
    if (!response) throw new Error('No response: POST ' + url);
    return response;
  })();
}

export async function $get(url: string, config?: AxiosRequestConfig) {
  return (await get(url, config))?.data;
}

export async function $post(url: string, data: any, config?: AxiosRequestConfig) {
  return (await post(url, data, config))?.data;
}

export function setDefaultTimeout(ms: number) {
  defaultTimeout = ms;
}

export default {
  setDefaultTimeout,
  get,
  post,
  $get,
  $post,
};
