import { CollectionIdentifier, TokenIdentifier } from './types';
import { getLinkTarget } from './registry';

export function generateCollectionLink(targetId: string, identifier: CollectionIdentifier, customData: unknown) {
  const target = getLinkTarget(targetId);
  return target.makeCollectionLink?.(identifier, customData);
}

export function generateTokenLink(
  targetId: string,
  identifier: TokenIdentifier,
  customTokenData: unknown,
  customCollectionData: unknown
) {
  const target = getLinkTarget(targetId);
  return target.makeTokenLink?.(identifier, customTokenData, customCollectionData);
}
