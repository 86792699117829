import * as iso from '../iso';
import { CalculationMethod } from './CalculationMethod';

export class TraitRarityCalculationMethod extends CalculationMethod {
  itemProbs: { [id: string]: number[] } = {};

  public rank() {
    //console.log('TraitRarityCalcMethod rank')
    this.prepareWeightsAndPropValueScores();

    // we go through all the items and create list of sorted probs for them
    this.prepareItemProbs();

    // next we sort items by item probs
    this.sortByItemProbs();

    this.assignRanks();
  }

  protected get uniquenessWeightMultiplier(): number {
    return 1;
  }

  protected prepareItemProbs() {
    // caculate scores for each item
    for (var arrItem of this.data.items) {
      var scores = [];
      var maxBaseDefs = this.data.basePropDefs.length;
      for (var i = 1; i <= maxBaseDefs; i++) {
        var itemVal = arrItem[i];
        if (i == maxBaseDefs) {
          if (this.data.derivedPropDefs) {
            // this is the derivedProps value array
            for (var j = 0; j < itemVal.length; j++) {
              var jitemVal = itemVal[j];
              var prop = this.data.derivedPropDefs[j];
              var weight = CalculationMethod.getPropWeight(prop, this.options, this.matches);
              if (weight != 0) {
                var score = this.getPropValueArray(this.data.derivedPropDefs[j], jitemVal)![2];
                scores.push(score);
              }
            }
          }
        } else {
          // sum the score
          var propDef = this.data.basePropDefs[i];
          if (propDef.type != 'data') {
            var weight = CalculationMethod.getPropWeight(propDef, this.options, this.matches);
            if (weight != 0) {
              var score = propDef.pvs![itemVal][2];
              scores.push(score);
            }
          }
        }
      }

      if (this.options.uniqueness) {
        var uniquenessScore = 1;
        //var probs: number[] = []
        for (var combination of this.data.combinations) {
          // get match id
          var matchId = this.getMatchId(combination);
          // get the value
          var matchVal = this.getMatchValue(arrItem, combination);
          // get the score
          var score = this.data.combinedProps[matchId][matchVal][1];
          //probs.push(prob)

          //console.log('unique add ' + score)
          //scores.push(Math.pow(score, this.uniquenessWeight))
          uniquenessScore *= score;
          //console.log('totalScore now ' + totalScore)
        }
        scores.push(uniquenessScore / this.uniquenessWeight);
      }

      scores.sort();
      this.itemProbs[arrItem[0]] = scores;
    }
  }

  protected calculatePropValueScore(valueCount: number, pvCount: number, weight: number): number {
    var score = valueCount / this.itemCount;
    if (this.options.normalize) {
      score = score * pvCount;
    }
    //score = Math.pow(score, weight)
    score = score / weight;
    if (weight == 0) return 0;
    return score;
  }

  protected sortByItemProbs() {
    // sort all items
    // var scoreIndex = this.data.basePropDefs.length + 1;
    this.data.items.sort((a: any, b: any) => {
      var probsA = this.itemProbs[a[0]];
      var probsB = this.itemProbs[b[0]];
      for (var i = 0; i < probsA.length; i++) {
        if (probsA[i] != probsB[i]) {
          return probsA[i] - probsB[i];
        }
      }
      return 0;
    });
  }

  // overridden! We use the first val
  protected assignRanks() {
    var scoreIndex = this.data.basePropDefs.length + 1;

    // append item rank
    var rankIndex = this.data.basePropDefs.length + 2;
    var currentRank = 1;
    var countedItems = 0;
    for (var i = 0; i < this.data.items.length; i++) {
      if (!this.project.passesFilterSet(this.data.items[i])) continue;

      countedItems++;
      var item = this.data.items[i];
      iso.set(item, rankIndex, currentRank);
      item[scoreIndex] = this.itemProbs[item[0]][0];
      currentRank = countedItems;
    }
  }
}
