








































import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';

// import Web3 from 'web3'

@Component({})
export default class PropFilterProp extends AppVue {
  @Prop()
  propName!: string;

  collapsed = true;

  mounted() {
    this.$bus.$on('collapseAll', (params: any) => {
      this.collapsed = params;
    });
  }

  get valueSets() {
    var def = this.project?.getPropDef(this.propName);
    if (def) return def.pvs;
    return undefined;
  }

  get valueCount() {
    return this.valueSets?.length || 0;
  }
}
