









import AppVue from '@/components/AppVue';
import { Component, Watch } from 'nuxt-property-decorator';

@Component({})
export default class HomeLayout extends AppVue {
  async mounted() {
    await Promise.all([this.$app.checkLoadCollections(), this.$app.checkLoadCurrent()]);

    this.$app.checkLoadStats();
  }
}
