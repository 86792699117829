import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6354e64f&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&lang=css&"
import style1 from "./default.vue?vue&type=style&index=1&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopBar: require('/opt/build/repo/components/TopBar.vue').default,LoadingIndicator: require('/opt/build/repo/components/LoadingIndicator.vue').default,ExploreTraitsSidebar: require('/opt/build/repo/components/ExploreTraitsSidebar.vue').default,PropFilterCategory: require('/opt/build/repo/components/PropFilterCategory.vue').default,AdBlock: require('/opt/build/repo/components/AdBlock.vue').default,ProjectBadge: require('/opt/build/repo/components/ProjectBadge.vue').default,BlockchainBadge: require('/opt/build/repo/components/BlockchainBadge.vue').default,ProjectLinks: require('/opt/build/repo/components/ProjectLinks.vue').default,ProjectInfo: require('/opt/build/repo/components/ProjectInfo.vue').default,QueryLink: require('/opt/build/repo/components/QueryLink.vue').default,Paging: require('/opt/build/repo/components/Paging.vue').default,NFTImage: require('/opt/build/repo/components/NFTImage.vue').default,NFTCard: require('/opt/build/repo/components/NFTCard.vue').default,AdBlockVertMobile: require('/opt/build/repo/components/AdBlockVertMobile.vue').default,AdBlockVert: require('/opt/build/repo/components/AdBlockVert.vue').default,ExploreTraits: require('/opt/build/repo/components/ExploreTraits.vue').default,ProjectBox: require('/opt/build/repo/components/ProjectBox.vue').default,ProjectList: require('/opt/build/repo/components/ProjectList.vue').default,LegalNotice: require('/opt/build/repo/components/LegalNotice.vue').default})
