import mixpanel from 'mixpanel-browser';
import { Blockchain } from '~/lib/rarity';
import type { Route } from 'vue-router';

// PageName is inferred by Nuxt router implementation.
export type PageName =
  | 'index'
  | 'project'
  | 'project-mode'
  | 'project-view-id'
  | 'upcoming'
  | 'list'
  | 'contact'
  | 'terms'
  | 'privacy';

export type MarketplaceName = 'OpenSea' | 'Axie';

const enum EventName {
  APP_INITIALIZED = 'app Initialized',
  HOME_PAGE = 'homePage Visit',
  COLLECTIONS_PAGE = 'collectionPage Visit',
  COLLECTION_ITEM_PAGE = 'collectionItemPage Visit',
  UPCOMING_PAGE = 'upcomingPage Visit',
  SUBMISSION_PAGE = 'submissionPage Visit',
  CONTACT_PAGE = 'contactPage Visit',
  TOS_PAGE = 'tosPage Visit',
  PRIVACY_PAGE = 'privacyPage Visit',
  LISTING_FORM_SUBMITTED = 'listingForm Submitted',
  MARKETPLACE_CTA = 'marketplaceCTA Submitted',
  TWITTER_CONTACT = 'twitterContact Clicked',
  COLLECTION_CLICKED = 'nftCollection Clicked',
}

export const enum CollectionCardLocation {
  TOP_NAV_NEW = 'TopNavBar - New',
  TOP_NAV_ALL = 'TopNavBar - All',
  HOME_LATEST_FEATURED = 'Homepage - Latest(Featured)',
  HOME_LATEST = 'Home Page - Latest',
  HOME_RECENT = 'Home Page - More Recently Added',
  HOME_TOP = 'Home Page - Top Collections',
  HOME_ALL = 'Home Page - All Collections',
  COLLECTION_LATEST = 'Collection Page - Latest',
  COLLECTION_TOP = 'Collection Page - Top',
}

const mapPageNameToEventName = (pageName: PageName): EventName => {
  switch (pageName) {
    case 'index':
      return EventName.HOME_PAGE;
    case 'project':
    case 'project-mode':
      return EventName.COLLECTIONS_PAGE;
    case 'project-view-id':
      return EventName.COLLECTION_ITEM_PAGE;
    case 'upcoming':
      return EventName.UPCOMING_PAGE;
    case 'list':
      return EventName.SUBMISSION_PAGE;
    case 'contact':
      return EventName.CONTACT_PAGE;
    case 'terms':
      return EventName.TOS_PAGE;
    case 'privacy':
      return EventName.PRIVACY_PAGE;
  }
};

const parsePageName = (pageName: PageName): string => {
  switch (pageName) {
    case 'index':
      return 'Home Page';
    case 'project':
    case 'project-mode':
      return 'Collection Page';
    case 'project-view-id':
      return 'Collection Item Page';
    case 'upcoming':
      return 'Upcoming Page';
    case 'list':
      return 'Submission Page';
    case 'contact':
      return 'Contact Page';
    case 'terms':
      return 'ToS Page';
    case 'privacy':
      return 'Privacy Page';
  }
};

export const isPageName = (pageName?: string | null): pageName is PageName =>
  mapPageNameToEventName(pageName as PageName) !== undefined;

export enum CollectionPageType {
  COLLECTION = 'collection',
  TRAITS = 'traits',
  WALLET = 'wallet',
  CHART = 'chart',
  OTHER = 'unknown',
}

export const isCollectionPageType = (pageType: string | undefined): pageType is CollectionPageType => {
  return Object.values(CollectionPageType).includes(pageType as CollectionPageType);
};

export interface CollectionPageVisitOptions {
  collectionName: string;
  isWalletConnected: boolean;
  pageType: CollectionPageType;
  filters?: Record<string, string[] | string>;
  blockchain?: Blockchain;
  sortBy?: string;
  rankingPreset?: string;
}

const track = (eventName: EventName, options?: Record<string, string | boolean | number | string[] | undefined>) => {
  if (!process.env.MIXPANEL_TOKEN) {
    console.log('Mixpanel not initialized. Event: ', eventName);
    return;
  }

  mixpanel.track(eventName, options);
};

export const trackPageVisit = (pageName: PageName, from?: PageName, options?: CollectionPageVisitOptions) => {
  track(mapPageNameToEventName(pageName), {
    'CTA Location': from ? parsePageName(from) : '',
    'Collection Name': options?.collectionName,
    'Page Type': options?.pageType,
    'Project blockchain': options?.blockchain,
    'Ranking Preset': options?.rankingPreset,
    'Wallet Connected': options?.isWalletConnected,
    'Sort by': options?.sortBy,
    ...options?.filters,
  });
};

export const trackListingFormSubmitted = () => {
  track(EventName.LISTING_FORM_SUBMITTED);
};

export const trackOpenExternalMarket = ({
  marketplaceName: marketplaceName,
  collectionName,
  collectionId,
  itemId,
  price,
  rarityScore,
  rarityRank,
  blockchain,
}: {
  marketplaceName: MarketplaceName;
  collectionName: string;
  collectionId: string;
  itemId: string;
  price: string;
  rarityScore: string;
  rarityRank: string | undefined;
  blockchain?: Blockchain;
}) => {
  track(EventName.MARKETPLACE_CTA, {
    'Marketplace name': marketplaceName,
    'Collection name': collectionName,
    'Collection ID': collectionId,
    'Item ID': itemId,
    'Rarity Score': rarityScore,
    'Rarity Rank': rarityRank,
    Price: price,
    'Project blockchain': blockchain,
  });
};

export const twitterContactClicked = (
  location: 'TopNavBar' | 'Contact Page',
  twitterAccount: 'rarity.tools' | 'rarity.tools Listings',
) => {
  track(EventName.TWITTER_CONTACT, {
    Location: location,
    'Twitter Account': twitterAccount,
  });
};

export const collectionClicked = (location: CollectionCardLocation, route: Route) => {
  track(EventName.COLLECTION_CLICKED, {
    'Card Location': location,
    'Current Page': isPageName(route.name) ? parsePageName(route.name) : route.path,
  });
};

if (process.env.MIXPANEL_TOKEN) {
  mixpanel.init(process.env.MIXPANEL_TOKEN, {
    debug: process.env.NODE_ENV === 'development',
    api_host: process.env.MIXPANEL_PROXY_DOMAIN ? `${process.env.MIXPANEL_PROXY_DOMAIN}/api/v0/proxy/mp` : undefined,
    loaded: () => {
      track(EventName.APP_INITIALIZED);
    },
  });

  // Netlify build variable
  if (process.env.BRANCH) {
    // https://developer.mixpanel.com/docs/javascript#super-properties
    mixpanel.register({
      branch: process.env.BRANCH,
    });
  }
}

export default mixpanel;
