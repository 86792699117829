import Project, { BasePropDef, ProjectData, PropDef } from '../Project';

import * as iso from '../iso';

export interface CalculationOptions {
  id?: string;
  name?: string;
  typeName?: string;
  method?: 'score' | 'statistical' | 'average' | 'trait' | 'preRanked';
  dataItem?: string;
  normalize?: boolean;
  uniqueness?: boolean;
  weights?: boolean;
  combined?: boolean;
  propWeights?: { [propName: string]: number };
  valueWeights?: {
    [propName: string]: { [value: string]: number };
  };
}

export class CalculationMethod {
  itemCount: number;
  uniquenessRawTotalWeight: number = 0;

  // in-case a method needs
  totalWeight = 0;

  data!: ProjectData;
  matches!: boolean;
  projectId!: string;

  // the main method
  public constructor(readonly project: Project, readonly options: CalculationOptions) {
    this.data = project.data;
    this.matches = project.matches;
    this.projectId = project.id;
    this.itemCount = this.data.items.length;
  }

  protected sumWeights(propDefs: PropDef[]) {
    for (var propDef of propDefs) {
      if ((propDef as BasePropDef).type != 'primaryKey' && (propDef as BasePropDef).type != 'data') {
        // sum totalWeights
        var propWeight = CalculationMethod.getPropWeight(propDef, this.options, this.matches);
        if (this.options.weights && typeof propWeight != 'undefined') {
          this.totalWeight += propWeight;
        } else {
          this.totalWeight++;
        }
      }
    }
  }

  protected prepareWeightsAndPropValueScores() {
    this.totalWeight = 0;
    this.uniquenessRawTotalWeight = 0;
    this.sumWeights(this.data.basePropDefs);
    if (this.data.derivedPropDefs) this.sumWeights(this.data.derivedPropDefs);
    this.updateUniquenessRawTotalWeight();
    if (this.options.uniqueness) {
      this.totalWeight += this.uniquenessWeight;
    }
    console.log('totalWeight = ' + this.totalWeight);
    this.recalcPropValueScores(this.data.basePropDefs);
    if (this.data.derivedPropDefs) this.recalcPropValueScores(this.data.derivedPropDefs);
    if (this.options.uniqueness) {
      this.recalcCombinedScores();
    }
  }

  public rank() {
    throw new Error('Unimplemented');
  }

  protected calculatePropValueScore(_valueCount: number, _pvCount: number, _weight: number): number {
    throw new Error('Unimplemented');
  }

  //////////////////////////////////////

  get uniquenessWeight() {
    if (this.options.weights) {
      if (this.options.propWeights && typeof this.options.propWeights['Uniqueness'] != 'undefined') {
        return this.options.propWeights['Uniqueness'];
      }
    }
    return 1;
  }

  protected getCombinationWeight(combination: number[]) {
    var propWeightsSum = 0;
    var propCount = 0;
    for (var propId of combination) {
      var weight = CalculationMethod.getPropWeight(this.data.basePropDefs[propId], this.options, this.matches);
      if (weight == 0) return 0;
      propWeightsSum += weight;
      propCount++;
    }
    return propWeightsSum / propCount;
  }

  updateUniquenessRawTotalWeight() {
    this.uniquenessRawTotalWeight = 0;
    if (this.options.uniqueness) {
      for (var combination of this.data.combinations) {
        var matchId = this.getMatchId(combination);
        var matchWeight = this.getCombinationWeight(combination); // we average the weights on each combination
        //console.log('matchId ' + matchId + ' matchWeight = ' + matchWeight)
        this.uniquenessRawTotalWeight += matchWeight;
        var matchVals = this.data.combinedProps[matchId];
        (matchVals as any)['weight'] = matchWeight;
      }
    }
    //console.log('uniquenessRawTotalWeight = ' + this.uniquenessRawTotalWeight)
  }

  getMatchId(combination: number[]) {
    return combination.join('-');
  }

  getMatchValue(item: any[], combination: number[]) {
    return combination
      .map((propId) => {
        return item[propId];
      })
      .join('|');
  }

  static getPropWeight(propDef: PropDef, options: CalculationOptions, matches: boolean) {
    if (propDef.isMatch && !matches) return 0;
    if (options.combined && propDef.hasCombined) return 0;
    if (!options.combined && propDef.isCombined) return 0;
    if (
      options.propWeights &&
      typeof options.propWeights[propDef.name] != 'undefined' &&
      options.weights &&
      (propDef as BasePropDef).type == 'data' &&
      options.method == 'score'
    )
      return options.propWeights[propDef.name];
    if ((propDef as BasePropDef).type == 'primaryKey' || (propDef as BasePropDef).type == 'data') return 0;
    if (!options.weights) return 1;
    if (!options.propWeights) return 1;
    if (typeof options.propWeights[propDef.name] == 'undefined') return 1;
    return options.propWeights[propDef.name];
  }

  // uses calculatePropValueScore
  protected recalcPropValueScores(propDefs: PropDef[]) {
    var useValueWeights =
      this.project.config.rankings.enableValueWeights && this.options.weights && this.options.valueWeights;

    for (var propDef of propDefs) {
      if (propDef.pvs && propDef.pvs.length > 0) {
        // calculate scores
        for (var possibleValue of propDef.pvs) {
          var weight = CalculationMethod.getPropWeight(propDef, this.options, this.matches);
          if (useValueWeights) {
            if (this.options.valueWeights![propDef.name]) {
              var valueWeight = this.options.valueWeights![propDef.name][possibleValue[0]!];
              if (typeof valueWeight != 'undefined') weight *= valueWeight;
            }
          }

          possibleValue[2] = this.calculatePropValueScore(possibleValue[1], propDef.pvs.length, weight);
        }
      }
    }
  }

  protected get uniquenessWeightMultiplier(): number {
    throw new Error('unimplemented');
  }

  protected recalcCombinedScores() {
    for (var matchId in this.data.combinedProps) {
      var matchVals = this.data.combinedProps[matchId];

      var weight = (matchVals as any)['weight'];

      // spread weight between all combines
      weight = weight * this.uniquenessWeightMultiplier;

      var weightMinus = matchVals['weight'] ? 1 : 0;
      var pvCount = Object.keys(matchVals).length - weightMinus;

      for (var matchVal in matchVals) {
        if (matchVal == 'weight') continue;
        var matchValArr = matchVals[matchVal];

        matchValArr[1] = this.calculatePropValueScore(matchValArr[0], pvCount, weight);
      }
    }
  }

  // utility method to sort items by their current scores
  protected sortByScoresDescending() {
    // sort all items
    var scoreIndex = this.data.basePropDefs.length + 1;

    if (this.project.config.sameRankOrder) {
      var sameRankOrder = this.project.config.sameRankOrder;
      this.data.items.sort((a: any, b: any) => {
        var bscore = b[scoreIndex];
        var ascore = a[scoreIndex];

        if (ascore == bscore) {
          var aindex = sameRankOrder.indexOf(a[0]);
          var bindex = sameRankOrder.indexOf(b[0]);
          if (aindex == bindex) return 0;
          if (aindex == -1) aindex = 99999999;
          if (bindex == -1) bindex = 99999999;
          return aindex - bindex;
        }

        return bscore - ascore;
      });
    } else {
      this.data.items.sort((a: any, b: any) => {
        return b[scoreIndex] - a[scoreIndex];
      });
    }
  }

  protected sortByScoresAscending() {
    // sort all items
    var scoreIndex = this.data.basePropDefs.length + 1;
    this.data.items.sort((a: any, b: any) => {
      return a[scoreIndex] - b[scoreIndex];
    });
  }

  // utility method to assign new ranks to items after sorting is done
  protected assignRanks() {
    var scoreIndex = this.data.basePropDefs.length + 1;

    // append item rank
    var currentRank = 0;
    var prevScore = -1;
    var rankIndex = this.data.basePropDefs.length + 2;
    var totalScore = 0;
    var countedItems = 0;
    for (var i = 0; i < this.data.items.length; i++) {
      var item = this.data.items[i];
      if (!this.project.passesFilterSet(item)) continue;

      var score = item[scoreIndex];
      totalScore += score;
      countedItems++;
      if (score != prevScore) {
        currentRank = countedItems;
      }

      iso.set(item, rankIndex, currentRank);

      prevScore = score;
    }
    if (this.options.method == 'score') console.log('ranking total score = ' + totalScore);
  }

  // utility method used to find the PropValue array inside a propDef for a given value
  protected getPropValueArray(propDef: PropDef, value: any) {
    if (propDef.pvs)
      for (var propValArr of propDef.pvs) {
        if (propValArr[0] === value) return propValArr;
      }
    return undefined;
  }

  //////////////////////////////

  protected initialScore(): number {
    throw new Error('unimplemented');
  }
  protected accumulateScore(_accumulatedScore: number, _newScore: number): number {
    throw new Error('unimplemented');
  }
  protected finalizeScore(_accumulatedScore: number): number {
    throw new Error('unimplemented');
  }
  protected mergeUniquenessScore(_normalScore: number, _uniquenesScore: number): number {
    throw new Error('unimplemented');
  }

  protected calculateItemScores() {
    var isStatsScore = this.options.name && this.options.name.indexOf('StatsScore') != -1;
    // caculate scores for each item
    for (var arrItem of this.data.items) {
      var normalScore = this.initialScore();
      var maxBaseDefs = this.data.basePropDefs.length;
      for (var i = 1; i <= maxBaseDefs; i++) {
        var itemVal = arrItem[i];
        if (i == maxBaseDefs) {
          // this is the derivedProps value array
          if (this.data.derivedPropDefs) {
            for (var j = 0; j < itemVal.length; j++) {
              var jitemVal = itemVal[j];
              var score = this.getPropValueArray(this.data.derivedPropDefs[j], jitemVal)![2];
              normalScore = this.accumulateScore(normalScore, score);
            }
          }
        } else {
          // sum the score
          var basePropDef = this.data.basePropDefs[i];
          var type = basePropDef.type;
          if (type == 'category' || type == 'range') {
            var score = this.data.basePropDefs[i].pvs![itemVal][2];
            normalScore = this.accumulateScore(normalScore, score);
          } else if (type == 'tags') {
            var foundTag = false;
            for (var tag of itemVal) {
              foundTag = true;
              var score = this.data.basePropDefs[i].pvs![tag][2];
              normalScore = this.accumulateScore(normalScore, score);
            }
            if (!foundTag && this.project.data.tagNones) {
              if (!basePropDef.tagNonePv) {
                for (var pv of basePropDef.pvs!) {
                  if (pv[0] == '@@_rt_no_tags') {
                    iso.set(basePropDef, 'tagNonePv', pv);
                    iso.set(basePropDef, 'tagNoneIndex', basePropDef.pvs!.indexOf(pv));
                    break;
                  }
                }
                if (!basePropDef.tagNonePv) {
                  console.error('no tagNonePv');
                  basePropDef.tagNonePv = [0, 0, 0];
                }
              }
              if (basePropDef.tagNonePv) {
                //this.project.getPropValueSetAndIndex()
                var score = basePropDef.tagNonePv![2];
                normalScore = this.accumulateScore(normalScore, score);
              }
            }
          } else if (
            type == 'data' &&
            this.options.weights &&
            this.options.propWeights &&
            this.options.method == 'score'
          ) {
            if (typeof itemVal == 'number') {
              var weight = this.options.propWeights[basePropDef.name];
              if (typeof weight != 'undefined') {
                if (isStatsScore)
                  normalScore += (basePropDef.statDividend! / (basePropDef.maxLevel! + 1 - itemVal)) * weight;
                else if (
                  this.project.config.rankings.squareLevels &&
                  this.project.config.rankings.squareLevels.indexOf(basePropDef.name) != -1
                ) {
                  normalScore += itemVal * itemVal * weight;
                } else {
                  if (
                    this.project.config.rankings.invertLevels &&
                    this.project.config.rankings.invertLevels.indexOf(basePropDef.name) != -1
                  )
                    normalScore += (basePropDef.maxLevel! + 1 - itemVal) * weight;
                  else normalScore += itemVal * weight;
                }
              }
            }
          }
        }
      }

      var finalScore = this.finalizeScore(normalScore);

      if (this.options.uniqueness) {
        var uniquenessScore = this.initialScore();
        //var probs: number[] = []
        for (var combination of this.data.combinations) {
          // get match id
          var matchId = this.getMatchId(combination);
          // get the value
          var matchVal = this.getMatchValue(arrItem, combination);
          // get the score
          var score = this.data.combinedProps[matchId][matchVal][1];
          //probs.push(prob)

          //console.log('unique add ' + score)
          uniquenessScore = this.accumulateScore(uniquenessScore, score);
          //console.log('totalScore now ' + totalScore)
        }
        var finalUniquenessScore = this.finalizeScore(uniquenessScore);

        finalScore = this.mergeUniquenessScore(finalScore, finalUniquenessScore);
      }

      // set item score
      //arrItem[this.data.basePropDefs.length + 1] = totalScore
      iso.set(arrItem, this.data.basePropDefs.length + 1, finalScore);
    }
  }
}
