


















import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';

// import Web3 from 'web3'

@Component({})
export default class PropFiRankingPresetSelector extends AppVue {
  @Prop()
  queryOverride!: { [queryParam: string]: string | null }; // setting nulls means they should not exist

  @Prop()
  text!: string;

  get toLink() {
    return this.linkToQueryParams(this.queryOverride) as any;
  }

  get routeMatch() {
    var to = this.toLink;
    if (to) {
      return this.$route.fullPath == this.$router.resolve(to).route.fullPath;
    }
    return false;
  }
}
