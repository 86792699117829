import { ContractInterface, ethers } from 'ethers';
import { opensea } from './opensea';
import AsyncRetry from 'async-retry';
import * as iso from './iso';

export const abiStyles: { [index: string]: ContractInterface } = {
  chubbies: [
    // tokensOfOwner
    {
      constant: true,
      inputs: [{ name: '_owner', type: 'address' }],
      name: 'tokensOfOwner',
      outputs: [{ name: 'tokens', type: 'uint256[]' }],
      type: 'function',
    },
  ],
  mooncats: [
    // tokensIdsByOwner
    {
      constant: true,
      inputs: [{ name: 'owner', type: 'address' }],
      name: 'tokensIdsByOwner',
      outputs: [{ name: 'tokens', type: 'uint256[]' }],
      type: 'function',
    },
  ],
  meebits: [
    // balanceOf
    {
      constant: true,
      inputs: [{ name: '_owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: 'balance', type: 'uint256' }],
      type: 'function',
    },
    // tokenOfOwnerByIndex
    {
      constant: true,
      inputs: [
        { name: '_owner', type: 'address' },
        { name: '_index', type: 'uint256' },
      ],
      name: 'tokenOfOwnerByIndex',
      outputs: [{ name: 'token', type: 'uint256' }],
      type: 'function',
    },
  ],
  hashmasks: [
    // balanceOf
    {
      constant: true,
      inputs: [{ name: 'owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: 'balance', type: 'uint256' }],
      type: 'function',
    },
    // tokenOfOwnerByIndex
    {
      constant: true,
      inputs: [
        { name: 'owner', type: 'address' },
        { name: 'index', type: 'uint256' },
      ],
      name: 'tokenOfOwnerByIndex',
      outputs: [{ name: 'token', type: 'uint256' }],
      type: 'function',
    },
    // tokenNameByIndex
    {
      constant: true,
      inputs: [{ name: 'owner', type: 'address' }],
      name: 'tokenNameByIndex',
      outputs: [{ name: 'name', type: 'string' }],
      type: 'function',
    },
  ],
};

export async function tryLoadTokenIds(
  contractAddress: string,
  ownerAddress: string,
  abiStyle: string,
  provider: ethers.providers.BaseProvider,
  max: number = -1,
  collection?: string,
  useDataRT: boolean = false
) {
  try {
    var result: number[] = [];
    var abi = abiStyles[abiStyle];
    if (abiStyle == 'hashmasks' || abiStyle == 'meebits') {
      var contract = new ethers.Contract(contractAddress, abi, provider);
      try {
        var total = await contract.balanceOf(ownerAddress);
      } catch (e) {
        if (!iso.isServer) console.error(e);
      }

      //console.log('max is ' + max)
      if (max > 0) {
        total = Math.min(total, max);
      }
      //console.log('total is ' + total)

      var loadSingle = async (index: number) => {
        await AsyncRetry(
          async () => {
            var indexOut = await contract.tokenOfOwnerByIndex(ownerAddress, index);
            //console.log('indexOut got ' + indexOut)
            result.push(indexOut);
          },
          { retries: 3, minTimeout: 500, maxTimeout: 1000 }
        );
      };

      var parallel = 10;
      //var parallel = 1
      for (var i = 0; i < total; ) {
        try {
          var waits: Promise<void>[] = [];
          var beginI = i;
          var j = i;
          for (; j < i + parallel && j < total; j++) {
            //console.log('loading ' + j)
            waits.push(loadSingle(j));
          }
          await Promise.all(waits);
          i = j;
          if (i == beginI) {
            console.error('same i, breaking');
            break;
          }
        } catch (e) {
          if (!iso.isServer) console.error(e);
          break;
        }
      }
    } else if (abiStyle == 'chubbies') {
      //console.log('chubbies')
      var contract = new ethers.Contract(contractAddress, abi, provider);
      try {
        result = await contract.tokensOfOwner(ownerAddress);
      } catch (e) {
        if (!iso.isServer) console.error(e);
      }
    } else if (abiStyle == 'mooncats') {
      //console.log('mooncats')
      var contract = new ethers.Contract(contractAddress, abi, provider);
      try {
        result = await contract.tokensIdsByOwner(ownerAddress);
      } catch (e) {
        if (!iso.isServer) console.error(e);
      }
    } else if (abiStyle == 'opensea' && collection) {
      //console.log('opensea')
      return await opensea.getAddressAssets(collection, ownerAddress, useDataRT);
      /*
			// graphql version
			var hasMore = true
			var cursor: string | null = null
			var result2: any[] = []
			while (hasMore) {
				var gqlResult: OpenSeaAddressAssetsResult
				try {
					gqlResult = await getAddressAssetsGQL([collection], cursor, ownerAddress)
				}
				catch (e) {
					console.error('error getting assets')
				}
				if (!gqlResult!.search)
					break

				var edges = gqlResult!.search.edges
				// go through all edges
				for (var edge of edges) {
					result2.push(edge.node.asset.tokenId)
				}

				// limit max
				if (result2.length > max)
					break

				hasMore = gqlResult!.search.pageInfo.hasNextPage
				cursor = gqlResult!.search.pageInfo.endCursor

				if (!cursor)
					break
			}
			result = result2 as any
			*/
    }
    //console.log('result is')
    //console.log(result)
    //console.log('total = ' + result.length)
    return result;
  } catch (e) {
    return undefined;
  }
}
