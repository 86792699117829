import { render, staticRenderFns } from "./PropFilterProp.vue?vue&type=template&id=4ce7864e&"
import script from "./PropFilterProp.vue?vue&type=script&lang=ts&"
export * from "./PropFilterProp.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PropFilterValue: require('/opt/build/repo/components/PropFilterValue.vue').default})
