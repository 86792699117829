//
//
//
//
//
//
//
//
//
//
//

import CollectionMenuItemVue from './Item.vue';

export default {
  props: {
    filter: String,
    collections: { type: Array, required: true },
    projectsLookup: { type: Object, required: true },
    formatETH: { type: Function, required: true },
    onItemClick: { type: Function, default: () => {} },
  },

  data() {
    return {
      itemComponent: CollectionMenuItemVue,
      projectLookup: this.projectsLookup,
    };
  },

  computed: {
    items() {
      const collections = this.collections.map((collection, index) => ({ ...collection, order: index + 1 }));
      if (!this.filter) {
        return collections;
      }

      return collections.filter(collection => {
        const { name } = this.$app.projectsJson.lookup[collection.slug];
        return name.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
      });
    },
    extraProps() {
      return { projectLookup: this.projectsLookup, formatETH: this.formatETH, onItemClick: this.onItemClick };
    },
  },
};
