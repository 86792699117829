import Vue from 'vue';

import humanizeString from 'humanize-string';
import AppData from '@/lib/AppData';
import moment from 'moment';
import axios from 'axios';

import { collectionIdForProjectId, Project, ProjectConfig, ProjectPriceData, ProjectQueryParams } from '@/lib/rarity';
import { getAPIUrl } from '~/lib/api';
import * as Sentry from '@sentry/browser';

var markdownTruncate = require('markdown-truncate');

export default class AppVue extends Vue {
  // this is needed since the default is too strict
  $refs: any;

  $app!: AppData;
  $bus!: Vue;
  $localForage!: any;

  /**
   * This returns a list of collection slugs that have been deployed, i.e. those for
   * which a "project" exists. In practice, each collection slug will serve as a valid,
   * working, project id; in case of the default ranking set + no job suffix, the project
   * ids are "pure" and are equivalent to the collection ids.
   *
   * Conceptually however, if the project you had deployed had a suffix, then the collection
   * slug returned here would not double as a valid project id. It might return `byac`, even
   * though the only deployed project id for this collection is `byac_acdefg`.
   *
   * This case is avoided by never deploying a job with a suffix publicly; by deploying such
   * jobs in hidden mode, they will not get an entry in the list returned here.
   */
  get projectIds() {
    return this.$app.projectsJson.list;
  }

  /**
   * This looks up a project by a "pure" project id, that is, one that matches a
   * collection slug because it lacks any suffixes. It will return undefined for
   * other project ids.
   */
  projectLookup(id: string) {
    return this.$app.projectsJson.lookup[id];
  }

  get listedProjectIds() {
    var result: string[] = [];
    for (var projectId of this.projectIds) {
      var project = this.$app.projectsJson.lookup[projectId];
      if (project && !project.unlisted) {
        //console.log('added:' + projectId)
        result.push(projectId);
      } else {
        //console.log('skipped ' + projectId)
      }
    }
    result.sort((id1, id2) => {
      var proj1 = this.getProjectShortName(id1) || id1;
      var proj2 = this.getProjectShortName(id2) || id2;
      return proj1?.localeCompare(proj2);
    });
    return result;
  }

  pageSize = 48;

  async checkLoadProject() {
    try {
      await this.checkLoad(this.projectId);
    } catch (error) {
      if (error.message !== 'Request failed with status code 429') {
        Sentry.captureMessage(`ERROR.FAILED_TO_LOAD_PROJECT Failed to load collection "${this.projectId}".`, {
          extra: {
            collection: this.projectId,
            message: error.message,
          },
          level: Sentry.Severity.Error,
        });

        throw error;
      }
    }

    if (this.project) {
      this.pageSize = this.project.config.images.pageSize || 48;
    }
  }

  async checkLoad(projectId: string) {
    // The project id either is pure (it matches the collection slug and thus is listed
    // in `projectIds`), or it has been validated previously.
    if (this.projectIds.indexOf(projectId) != -1 || this.$app.validUnlisted == projectId) {
      if (!this.$app.projects[projectId]) {
        var projectData: any = {};
        var projectConfig: ProjectConfig | undefined = undefined;
        var pricesData: ProjectPriceData | undefined = undefined;

        var requests: Promise<any>[] = [
          (async () => {
            pricesData = await Project.loadPricesData(projectId);
          })(),
        ];

        var lookupId = collectionIdForProjectId(projectId) || projectId;

        var projectLookup = this.$app.projectsJson.lookup[lookupId];

        const configPath = getAPIUrl(`collections/${projectId}/artifacts/config`);
        const dataPath = getAPIUrl(`collections/${projectId}/artifacts/data`);

        requests.push(
          (async () => {
            projectConfig = (await axios.get(configPath)).data;
          })(),
        );
        requests.push(
          (async () => {
            projectData = (await axios.get(dataPath)).data;
          })(),
        );

        await Promise.all(requests);
        if (!projectLookup && this.$app.validUnlisted == projectId) {
          var nameToUse = this.getProjectName(projectId);
          projectLookup = {
            name: nameToUse,
            added: '01/01/2001',
          };
        }

        const project = new Project(projectData, projectConfig!, projectLookup, pricesData);

        // The combo `useMediaCdn` and `projectConfig.images.ext` means that files have the same extension;
        // there is no need to load a media mapping, the urls can be constructed through `itemId + ext`.
        // New deploys from api.rt no longer use `projectConfig.images.ext`; instead, the media file itself
        // as a trimmed down version which only specifies a shared extension.
        if (projectConfig!.images.useMediaCdn && !projectConfig!.images.ext) {
          const mediaFile = (await axios.get(getAPIUrl(`collections/${projectId}/artifacts/media`))).data;
          project.setMediaFile(mediaFile);
        }

        this.$set(this.$app.projects, projectId, project);
        this.$bus.$emit('eventNFTsFiltered', project, this.projectCollection?.details?.blockchain);
      }
    }
  }

  roundToTwo(num: any) {
    return +(Math.round((num + 'e+2') as any) + 'e-2');
  }

  /**
   * Checks if the current project id is valid by attempting to fetch its config file. Called
   * once on mount and route change.
   *
   * If valid, will set `$app.validUnlisted` to the project id. In many places, code then
   * checks if the project id is valid via the conditional `$app.validUnlisted == projectId`.
   */
  async hasValidProjectId() {
    if (!this.projectId) {
      return false;
    }

    if (this.$app.projectsJson.lookup[this.projectId]) {
      return true;
    }

    try {
      const configPath = getAPIUrl(`collections/${this.projectId}/artifacts/config`);

      const projectConfig = await this.$axios.$get(configPath);
      if (projectConfig) {
        this.$app.validUnlisted = this.projectId;
        return true;
      }
    } catch (e) {}
    return false;
  }

  /**
   * Returns a `Project` instance for the given project id.
   */
  getProject(projectId: string) {
    if (projectId == 'none') {
      return undefined;
    }

    // Validate that the project id is either pure (and in the global list of collections),
    // or that it has been validated by `checkLoad`.
    if (this.projectIds.indexOf(projectId) != -1 || this.$app.validUnlisted == projectId) {
      return this.$app.projects[projectId];
    }

    return undefined;
  }

  getProjectName(projectId: string) {
    // If this project id is "pure", then it will match a collection id and be in the lookup.
    if (this.$app.projectsJson.lookup[projectId]) {
      return this.$app.projectsJson.lookup[projectId].name;
    }

    // Otherwise, we apparently just return the name from the currently loaded project,
    // assuming it might match the one that was queried. This branch handles all cases
    // where the project id is unpure and contains a ranking set handle or a suffix.
    if (this.project?.config.previewName) {
      return this.project.config.previewName;
    }

    return '';
  }

  getProjectShortName(projectId: string) {
    if (!this.$app.projectsJson.lookup[projectId]) return '';
    if (this.$app.projectsJson.lookup[projectId].shortName) return this.$app.projectsJson.lookup[projectId].shortName;
    return this.getProjectName(projectId);
  }

  get projectId() {
    try {
      return this.$route.params.project;
    } catch (e) {
      return 'none';
    }
  }

  get projectName() {
    return this.getProjectName(this.projectId);
  }

  get project() {
    if (this.projectId) {
      return this.getProject(this.projectId);
    }
    return undefined;
  }

  get collectionId() {
    return collectionIdForProjectId(this.projectId);
  }

  get projectCollection() {
    return this.$app.collectionForProjectId(this.projectId);
  }

  get mode() {
    return this.$route.params.mode || 'all';
  }

  get isRarityScoreDisabled() {
    return this.project?.config.rankings.disableRarityScore === true;
  }

  /**
   * Returns the 3-tuple of file extensions for the metadata images.
   */
  getMetaImageExtensions(projectId: string): [string | null, string | null, string | null] | undefined {
    return this.projectLookup(projectId)?.images;
  }

  get exploringTrait() {
    if (this.project) {
      if (this.$route.query?.trait) {
        return this.$route.query?.trait as string;
      }
      return this.project.defaultExploreTrait;
    }
    return undefined;
  }

  humanize(text: string) {
    if (!text) return '';
    return humanizeString(text).replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
  }

  noneIfNull(value: any) {
    if (value == null) return '<none>';
    if (value == '@@_rt_no_tags') return '<none>';
    return value;
  }

  linkToQueryParams(queryParams: ProjectQueryParams) {
    if (this.project) {
      var query = this.project.queryParamsOverridedWith(queryParams);
      if (Object.keys(query).length > 0) {
        return {
          path: this.$route.path,
          query,
        };
      }
    }
    return this.$route.path;
  }

  checkApplyUrlFromFilters() {
    var linkTo = this.linkToQueryParams({}) as any;
    if (linkTo) this.$router.push(linkTo);
  }

  checkApplyFiltersFromUrl() {
    if (this.project) {
      this.project.applyQueryParams(this.$route.query);
    }
  }

  linkToNFT(nftId: string) {
    if (this.mode == 'all')
      return {
        path: `/${this.projectId}/view/${nftId}`,
        query: this.$route.query,
      };
    else if (this.mode == 'traits') {
      if (this.$route.params.item) {
        return {
          path: `/${this.projectId}/traits/${this.$route.params.item}/view/${nftId}`,
          query: this.$route.query,
        };
      } else {
        return {
          path: `/${this.projectId}/traits/view/${nftId}`,
          query: this.$route.query,
        };
      }
    } else if (this.mode)
      return {
        path: `/${this.projectId}/${this.mode}/view/${nftId}`,
        query: this.$route.query,
      };
  }

  formatETH3(num: number) {
    if (!num) return '0 ETH';

    return num.toLocaleString('en-US', { maximumFractionDigits: 3 }) + ' ETH';
  }

  formatETH2(num: number) {
    if (!num) return '0 ETH';

    return num.toLocaleString('en-US', { maximumFractionDigits: 2 }) + ' ETH';
  }

  formatETH2Fix(num: number) {
    if (!num) return '0 ETH';

    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ETH';
  }

  shortenDescription(description: string) {
    return markdownTruncate(description, {
      limit: 250,
      ellipsis: true,
    });
  }

  /// DD/MM/YYYY format
  adMoment(datestring: string) {
    return moment(`${datestring} 03:00 -05:00', 'DD/MM/YYYY HH:mm ZZ`);
  }

  /////////////////////////////////////////

  formatPropScore(score: number) {
    if (this.project) {
      if (this.project.calcOptions.method == 'score') return '+' + score.toFixed(2);
      return score.toPrecision(4);
    }
  }

  getFallbackDescription(name: string) {
    return `Browse ${name} on rarity.tools to compare rarity scores and discover the collection's most unique pieces.`;
  }
}
