

















import AppVue from '@/components/AppVue';
import { Component, Prop, Watch } from 'nuxt-property-decorator';

// import Web3 from 'web3'

@Component({})
export default class ProjectTopLink extends AppVue {
  @Prop()
  forProjectId!: string;

  @Prop({ default: () => () => {} })
  onClick?: () => void;
}
