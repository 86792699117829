import type { Context } from '@nuxt/types';
import type { Route } from 'vue-router';
import {
  isPageName,
  PageName,
  CollectionPageVisitOptions,
  trackPageVisit,
  CollectionPageType,
  isCollectionPageType,
} from '@/plugins/mixpanel';
import { appData, vueEventBus } from '@/plugins/app';
import type { Blockchain, Project, ProjectQueryParams } from '~/lib/rarity';
import { parse as parseQuery } from 'urlon';

export default function ({ route, from, ...rest }: Context) {
  if (!isPageName(route.name) || !isPageName(from.name)) {
    console.error('Unexpected Behavior should never happen');
    return;
  }

  const routeName: PageName = route.name;
  const fromName: PageName = from.name;

  if (routeName === 'project' || routeName === 'project-mode') {
    const pageType =
      routeName === 'project-mode' && isCollectionPageType(route.params.mode)
        ? route.params.mode
        : CollectionPageType.OTHER;

    const currentProject = appData.projects[route.params.project];

    // Project already loaded, don't need to wait for it, mixpanel event is send right away
    if (currentProject) {
      const blockchain: Blockchain | undefined = appData.collectionForProjectId(route.params.project)?.details
        ?.blockchain;
      sendCollectionPageEvent(
        routeName,
        fromName,
        pageType,
        currentProject,
        route.query as ProjectQueryParams,
        blockchain,
      );
      return;
    }

    // Project not yet loaded, after `eventNFTsFiltered` is called project will be loaded and mixpanel event can be send
    vueEventBus.$once('eventNFTsFiltered', (project?: Project, blockchain?: Blockchain) => {
      if (!project) {
        console.debug('PROJECT WAS UNDEFINED');
        return;
      }
      sendCollectionPageEvent(routeName, fromName, pageType, project, route.query as ProjectQueryParams, blockchain);
    });

    return;
  }

  trackPageVisit(routeName, routeName !== fromName && isPageName(fromName) ? fromName : undefined);
}

const singleValueKeys = new Set(['minPrice', 'maxPrice', 'auction', 'buyNow', 'minRank', 'maxRank']);

const sendCollectionPageEvent = (
  routeName: PageName,
  fromName: PageName,
  routeMode: CollectionPageType,
  project: Project,
  queryParams: ProjectQueryParams,
  blockchain?: Blockchain,
) => {
  const { filters: queryFilters, sort: sortBy, matches, preset: rankingPreset = project.defaultPreset } = queryParams;
  const isWalletConnected = Boolean(appData.web3.currentAddress);

  let options: CollectionPageVisitOptions = {
    collectionName: project.name,
    pageType: routeMode,
    blockchain,
    sortBy,
    rankingPreset,
    isWalletConnected,
  };

  if (queryFilters) {
    const parsedQueryFilters: Record<string, Record<string, boolean>> = parseQuery(queryFilters);
    const filters = Object.entries(parsedQueryFilters).reduce<Record<string, string[] | string>>(
      (acc, [key, value]) => {
        if (singleValueKeys.has(key)) {
          acc[`Filter - ${key}`] = Object.keys(value)[0];
          return acc;
        }

        acc[`Filter - Traits`] = [...(acc[`Filter - Traits`] ? acc[`Filter - Traits`] : []), ...Object.keys(value)];
        return acc;
      },
      {},
    );

    options = {
      ...options,
      filters,
    };
  }

  trackPageVisit(routeName, routeName !== fromName && isPageName(fromName) ? fromName : undefined, options);
};
