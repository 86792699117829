import { render, staticRenderFns } from "./ProjectTopLink.vue?vue&type=template&id=50209e84&"
import script from "./ProjectTopLink.vue?vue&type=script&lang=ts&"
export * from "./ProjectTopLink.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProjectBadge: require('/opt/build/repo/components/ProjectBadge.vue').default})
