import AppData from './AppData';
import Vue from 'vue';
import { ethers } from 'ethers';
import { Project, tryLoadTokenIds } from '@/lib/rarity';
import * as Sentry from '@sentry/browser';

const USE_DATA_PROXY_RT_WEB_3 = process.env.USE_DATA_PROXY_RT_WEB_3 === 'true';

export default class RTWeb3 {
  _registeredAccountChanged = false;

  _provider?: ethers.providers.BaseProvider;

  public currentAddress: string | null = null;

  get ethereum() {
    return (<any>window).ethereum;
  }

  constructor(readonly appData: AppData) {
    this._readAddress(false);
  }

  async _readAddress(popupMetamask: boolean) {
    if (this.currentAddress) return;
    try {
      var method = popupMetamask ? 'eth_requestAccounts' : 'eth_accounts';
      var addresses = await this.ethereum.request({ method });
      if (addresses.length > 0) {
        this._updateCurrentAddress(addresses[0]);
      } else {
        this._updateCurrentAddress(null);
      }

      if (!this._registeredAccountChanged) {
        this._registeredAccountChanged = true;
        this.ethereum.on('accountsChanged', (addresses: any[]) => {
          if (addresses.length > 0) {
            this._updateCurrentAddress(addresses[0]);
          } else {
            this._updateCurrentAddress(null);
          }
        });
      }
    } catch {}
  }

  _updateCurrentAddress(newAddress: string | null) {
    if (this.currentAddress != newAddress) {
      console.log(`this.currentAddress from ${this.currentAddress} to ${newAddress}`);
      Vue.set(this, 'currentAddress', newAddress);
      this.appData.onAddressChange();
    }
  }

  async connect() {
    if (this.ethereum && this.ethereum.isConnected()) {
      await this._readAddress(true);
    }
    this.getProvider();
  }

  async getProvider() {
    try {
      if (this.ethereum && (!this.ethereum.isConnected() || !this.currentAddress)) {
        await this._readAddress(false);
      }

      if (this.ethereum?.isConnected() && this.currentAddress) {
        if (!this._provider) this._provider = new ethers.providers.Web3Provider(this.ethereum);
        return this._provider;
      }
    } catch (err) {
      Sentry.captureException(err);
    }

    this.currentAddress = null;
    return undefined;
  }

  async loadTokenIds(project: Project) {
    console.log('loading tokenIds');
    if (!this.currentAddress) return [];

    var provider = await this.getProvider();
    if (provider) {
      project.modeLists['wallet'].loading = true;
      try {
        var ids: any[] = [];
        for (var contract of project.config.contracts) {
          var abiStyle = contract.abiStyle;
          var contractAddress = contract.contract;

          var result = await tryLoadTokenIds(
            contractAddress,
            this.currentAddress,
            abiStyle,
            provider,
            600,
            project.id,
            USE_DATA_PROXY_RT_WEB_3,
          );
          if (result) {
            ids = ids.concat(result);
          }
        }
        project.assignWalletIds(ids);
      } finally {
        project.modeLists['wallet'].loading = false;
      }
    }
  }
}
