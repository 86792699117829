














































































































import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';
import moment from 'moment-timezone';

function isDescendant(parent: any, child: any) {
  var node = child.parentNode;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

@Component({})
export default class UpcomingRow extends AppVue {
  @Prop()
  projectJson!: any;

  formatDate(date: Date) {
    return moment(date).format('dddd, MMMM Do YYYY');
  }

  formatTime(date: Date) {
    return moment(date).format('h:mm a');
  }

  formatInt(num: number | undefined) {
    if (num) return num.toLocaleString('en-US');
    return '';
  }

  cleanUrl(url: string) {
    var https = 'https://';
    var http = 'http://';
    if (url) {
      if (url.startsWith(https)) {
        url = url.substr(https.length);
      }
      if (url.startsWith(http)) {
        url = url.substr(http.length);
      }
      if (url.endsWith('/')) {
        url = url.substr(0, url.length - 1);
      }
    }
    return url;
  }

  ensureUrl(url: string) {
    var https = 'https://';
    var http = 'http://';
    if (url) {
      if (!url.startsWith(https) && !url.startsWith(http)) {
        url = https + url;
      }
    }
    return url;
  }

  get guessTimeZone() {
    return moment.tz.guess();
  }
}
