
















































































































































import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';
import moment from 'moment-timezone';
import { Upcoming2RecordRuntime } from '~/pages/upcoming.vue';

function isDescendant(parent: any, child: any) {
  var node = child.parentNode;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

@Component({})
export default class UpcomingRow extends AppVue {
  @Prop()
  upcoming!: Upcoming2RecordRuntime;

  @Prop()
  dayName!: string;

  imgUrl(no: number) {
    //return 'https://ik.imagekit.io/cqjxdcnnf/upcomings/tr:w-150,q-95/' + this.upcoming.id + '_' + no
    return `https://upcoming.images.rarity.tools/${this.upcoming.id}_${no}?width=150&optimizer=image`;
  }

  formatDateNoTime(date: Date) {
    var dateFormatted = moment(date).utc().format('dddd, MMMM Do YYYY');

    if (dateFormatted == moment().format('dddd, MMMM Do YYYY')) {
      return 'Today';
    }
    if (dateFormatted == moment().subtract(1, 'day').format('dddd, MMMM Do YYYY')) {
      return 'Yesterday';
    }
    if (dateFormatted == moment().add(1, 'day').format('dddd, MMMM Do YYYY')) {
      return 'Tomorrow';
    }
    return dateFormatted;
  }

  formatDate(date: Date) {
    var dateFormatted = moment(date).format('dddd, MMMM Do YYYY');
    //if (dateFormatted == this.dayName)
    //return ''

    if (dateFormatted == moment().format('dddd, MMMM Do YYYY')) {
      return 'Today';
    }
    if (dateFormatted == moment().subtract(1, 'day').format('dddd, MMMM Do YYYY')) {
      return 'Yesterday';
    }
    if (dateFormatted == moment().add(1, 'day').format('dddd, MMMM Do YYYY')) {
      return 'Tomorrow';
    }
    return dateFormatted;
  }

  formatTime(date: Date) {
    return moment(date).format('h:mm a');
  }

  formatInt(num: number | undefined) {
    if (num) return num.toLocaleString('en-US');
    return '';
  }

  cleanUrl(url: string) {
    var https = 'https://';
    var http = 'http://';
    if (url) {
      if (url.startsWith(https)) {
        url = url.substr(https.length);
      }
      if (url.startsWith(http)) {
        url = url.substr(http.length);
      }
      if (url.endsWith('/')) {
        url = url.substr(0, url.length - 1);
      }
    }
    return url;
  }

  ensureUrl(url: string) {
    var https = 'https://';
    var http = 'http://';
    if (url) {
      if (!url.startsWith(https) && !url.startsWith(http)) {
        url = https + url;
      }
    }
    return url;
  }

  get guessTimeZone() {
    return moment.tz.guess();
  }

  usePresaleDate(upcoming: Upcoming2RecordRuntime) {
    if (upcoming['Presale Date']) {
      var cutoff = moment().subtract(1, 'days');
      var presaleDate = moment(new Date(upcoming['Presale Date']));
      if (presaleDate.isAfter(cutoff)) {
        return true;
      }
    }
    return false;
  }

  priceText(upcoming: Upcoming2RecordRuntime) {
    return upcoming.Price ? upcoming.Price + ' ' + (upcoming.Currency || 'ETH') : '';
  }

  maxItemsText(upcoming: Upcoming2RecordRuntime) {
    return upcoming['Max Items'] ? this.formatInt(parseInt(upcoming['Max Items'] as string)) + ' Total' : '';
  }
}
