













import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';

// import Web3 from 'web3'

@Component({})
export default class ExploreTraitsCategory extends AppVue {
  @Prop()
  catName!: string;

  @Prop()
  props!: string[];
}
