import { render, staticRenderFns } from "./NFTCard.vue?vue&type=template&id=162af80e&"
import script from "./NFTCard.vue?vue&type=script&lang=ts&"
export * from "./NFTCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NFTImage: require('/opt/build/repo/components/NFTImage.vue').default})
