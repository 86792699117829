import { LinkTarget } from './types';
import { AxieInfinityMarketplace, LooksRare, MagicEden, OpenSea } from './modules';

const LinkTargets: { [id: string]: LinkTarget<any, any> } = {};

export function registerLinkTarget(module: LinkTarget<any, any>) {
  LinkTargets[module.id] = module;
}

export function getLinkTarget(id: string): LinkTarget<any, any> {
  return LinkTargets[id];
}

export function getLinkTargetIds() {
  return Object.keys(LinkTargets);
}

export function getLinkTargets() {
  return Object.values(LinkTargets);
}

// Register by  priority; e.g. Axie should be before OpenSea!
registerLinkTarget(AxieInfinityMarketplace);
registerLinkTarget(MagicEden);
registerLinkTarget(OpenSea);
registerLinkTarget(LooksRare);
