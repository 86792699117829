import * as urlon from 'urlon';
import humanizeString from 'humanize-string';

import {
  CalculationMethod,
  CalculationOptions,
  AverageTraitRarityCalculationMethod,
  RarityScoreCalculationMethod,
  TraitRarityCalculationMethod,
  StatisticalRarityCalculationMethod,
  PreRankedCalculationMethod,
} from './calculation-methods';

import * as iso from './iso';
import * as opensea from './opensea/opensea';
import * as priceData from './priceData';
import * as axie from './axie';
import { getImgFromImgFile, ExternalIds, ImgFile, isVideoSrc, MediaFile } from './types';
import vhttp from './vhttp';
import { generateTokenLink } from './linkMaker/helpers';
import { TokenIdentifier } from './linkMaker/types';
import { OS_REFERRER_QUERY } from './linkMaker/modules';

const STATIC_HOST_AND_BASE = (() => {
  if (typeof window !== 'undefined' && window && window.location.href.indexOf('localhost') >= 0) {
    return 'http://localhost:9005';
  }

  try {
    if (process.env.STATIC_HOST_AND_BASE) {
      return process.env.STATIC_HOST_AND_BASE;
    } else if (process.env.PROJECTS_ORIGIN) {
      return `${process.env.PROJECTS_ORIGIN}/static`;
    }
  } catch (error) {}

  return 'https://projects.rarity.tools/static';
})();

const DATA_ORIGIN = process.env.DATA_ORIGIN ?? 'https://data.rarity.tools';

export interface ProjectsJsonItem {
  name: string;
  added: string;
  chain?: PublicBlockchain;
  contracts?: string[];
  rankingSets?: string[];

  externalIds?: ExternalIds;

  // If this project is part of the dynamic refresh cycle, it's update frequency.
  // If set to a non-zero value, affects location of image & data files.
  dynamic?: number;
  // If the project updates dynamically, this usually explains why
  hasDynamicTraits?: boolean;
  isMinting?: boolean;
  maxItems?: number;

  cv?: number;

  shortName?: string;
  itemName?: string;

  customHeader?: string;
  customBanner?: string;
  customBadge?: string;

  /* Images to load from the CDN: [badge, banner, header] */
  images?: [string | null, string | null, string | null];

  manual?: boolean;

  featured?: boolean;
  featuredOrder?: number;

  unlisted?: boolean;
  useOwner?: string;
}

export interface ProjectsJson {
  list: string[];
  lookup: { [projectId: string]: ProjectsJsonItem };
}

export interface CombinedProps {
  [matchId: string]: {
    // count, score
    // 'weight' will be single number
    [matchVal: string]: [number, number];
  };
}

export interface PropWithValues {
  pvs?: [string | number | null, number, number][];

  // runtime-only prop: for quick lookup of tagNonePv
  tagNonePv?: [string | number | null, number, number];
  tagNoneIndex?: number;
}

export interface PropDef extends PropWithValues {
  name: string;
  skipUniqueness?: boolean;
  isMatch?: boolean;
  isCombined?: boolean;
  hasCombined?: boolean;
  maxLevel?: number;
  statDividend?: number;
}

export interface BasePropDef extends PropDef {
  type: 'primaryKey' | 'category' | 'data' | 'tags' | 'range';
}

export interface DerivedPropDef extends PropDef {
  type: 'combinedProperty' | 'propertyMatch' | 'propertyMatchCount' | 'propertySetMatch';
  matchingValue?: any;
  propertyValueInclusion?: {
    [propertyName: string]: any | any[];
  };
  propertyValueMapping?: {
    [propertyName: string]: {
      [value: string]: any;
    };
  };
  matchValueMapping?: {
    [value: string]: any;
  };
  sets?: {
    [setName: string]: {
      [propertyName: string]: string[];
    };
  };
  excludeValues?: any[];
  properties?: string[];
}

export interface ProjectData {
  basePropDefs: BasePropDef[];
  derivedPropDefs?: DerivedPropDef[];
  tagNones?: boolean;
  combinedProps: CombinedProps; // this is for uniqueness
  combinations: number[][]; // this is for uniqueness
  items: any[];
}

type PropCategory = [string, string[]];

export interface ProjectContract {
  contract: string;
  abiStyle: string;
  tokenStandard?: 'ERC1155' | 'ERC721';
}

export type PublicBlockchain = 'ethereum' | 'matic' | 'solana';

export interface ProjectConfig {
  id: string;

  chain?: 'ethereum' | 'matic' | 'solana';

  previewName?: string;
  dynamicWarning?: string;

  // other rankingSets so we can link to them
  rankingSets?: { projectId: string; name?: string }[];
  rankingSetsTitle?: string;

  filterSets?: {
    id: string | null;
    name: string;

    /** exclude if these trait_types match the specified values (processed on rawJson), higher priority than includeIf */
    excludeIf?: { [trait_type: string]: any };

    /** include only if these trait_types match the specified values (processed on rawJson) */
    includeIf?: { [trait_type: string]: any };

    /** include only if all these traits have a value */
    includeIfHasTrait?: string;

    /** include only if all these traits do not have a value */
    includeIfNotHasTrait?: string;
  }[];
  filterSetsTitle?: string;

  // v2 lists contracts
  contracts: ProjectContract[];
  tryContracts?: boolean;

  notes?: {
    id: number;
    date: string;
    title: string;
    text: string;
  }[];

  options?: {
    hideOwners?: boolean;
    defaultExploreTrait?: string;
    canHaveImgOverride?: boolean;
    useOwnName?: boolean;
    dontHumanizeTraitTypes?: boolean;
  };

  images: {
    baseUrl?: string;
    ext?: string;
    useBackup?: boolean;
    previewBaseUrl?: string;
    previewExt?: string;
    indexOffset?: number;
    indexMax?: number;
    indexPadZeroes?: number;
    hasPadding?: boolean;
    pixellate?: boolean;
    bgColor?: string;
    sizeFull: [number, number];
    sizeThumb: [number, number];
    pageSize?: number;
    useMediaCdn?: boolean;
    // @deprecated
    useOpenSeaImg?: boolean;
    // @deprecated
    useOpenSeaImgForPreview?: boolean;
    // @deprecated, use useMediaCdn instead
    useImgFile?: boolean;
    useBgColorData?: boolean; // uses color in data field bgColor
    keepAspectRatio?: boolean;
  };

  rankings: {
    defaultPreset?: string;
    defaultMatches?: boolean;
    enableMethods?: boolean;
    enableUniqueness?: boolean;
    enableMatches?: boolean;
    enableCombined?: boolean;
    enableValueWeights?: boolean;
    disableNormalization?: boolean;
    disableSettings?: boolean;
    disableRarityScore?: boolean;
    showWeights?: boolean;
    squareLevels?: string[];
    invertLevels?: string[];
    presets?: CalculationOptions[];
    tagNones?: boolean;
  };

  propCategories: PropCategory[];
  levelsCat?: string;
  levelProps?: string[];

  sameRankOrder?: string[];
}

export interface NFTExtraData {
  //price?: number
  //priceDate?: Date
  //currency?: string

  priceData?: priceData.InterpretedPriceData | null;

  // priceData2 is when queried from OpenSea when already hasPrices
  priceData2?: priceData.InterpretedPriceData | null;

  ownerUser?: string;
  ownerAddress?: string;
  useContract?: string;
  useTokenId?: string;
  name?: string;
  filteredOut?: boolean;
  openSeaPreview?: string;
  openSeaFull?: string;
  model?: string;
  modelUsdz?: string;
  animationUrl?: string;
  noOpenSea?: boolean; // used when opensea doesn't have image, requires images.useBackup in project config
}

export class ModeList {
  ids: string[] = [];
  filteredIds: string[] = [];
  alreadyContains: { [id: string]: boolean } = {};
  loading: boolean = false;

  // according to filters
  priceFloor: number = 0;
  //priceAverage: number = 0
  minRank: number = 0;
  maxRank: number = 0;

  resetIds() {
    iso.set(this, 'ids', []);
    iso.set(this, 'alreadyContains', {});
    iso.set(this, 'filteredIds', []);
  }

  setFilteredIds(newVal: string[]) {
    iso.set(this, 'filteredIds', newVal);
  }

  updateStats(project: Project) {
    // calculate stats
    this.priceFloor = 0;
    //this.priceAverage = 0
    this.minRank = 0;
    this.maxRank = 0;
    var idsWithPriceCount = 0;
    //var idsWithPricePriceTotal = 0
    var currFloor = Number.MAX_VALUE;
    var minRank = Number.MAX_VALUE;
    var now = new Date().valueOf();
    for (var id of this.filteredIds) {
      var ethPrice = project.getPriceEth(id, now);
      if (ethPrice) {
        idsWithPriceCount++;
        //idsWithPricePriceTotal += ethPrice
        if (ethPrice < currFloor) currFloor = ethPrice;
      }
      var rank = project.rankOf(id);
      if (rank > this.maxRank) this.maxRank = rank;
      if (rank < minRank) minRank = rank;
    }
    if (idsWithPriceCount > 0) {
      this.priceFloor = currFloor;
      //this.priceAverage = parseFloat((idsWithPricePriceTotal / idsWithPriceCount).toFixed(2))
    }
    if (this.filteredIds.length > 0) {
      this.minRank = minRank;
    }
  }
}

/*
var standardPresets: CalcOptions[] = [
	{
		id: 'rarity-tools-2',
		name: "rarity.tools v2",
		method: 'score',
		normalize: true,
		weights: false,
		uniqueness: false
	},
	{
		id: 'rarity-tools-1',
		name: 'rarity.tools v1',
		method: 'score',
		normalize: false,
		weights: true,
		uniqueness: false
	}
]
*/

var standardPresets: CalculationOptions[] = [
  {
    id: 'rarity-tools-2',
    name: 'rarity.tools v2',
    method: 'score',
    normalize: true,
    weights: false,
    uniqueness: false,
  },
  {
    id: 'rarity-tools-1',
    name: 'rarity.tools v1',
    method: 'score',
    normalize: false,
    weights: true,
    uniqueness: false,
  },
];

/**
 * Extra the collection id from the project id. For example, for the collection
 * `byac`, the project id could be one of these:
 *
 *     - byac
 *     - byac@set
 *     - byac_suffix
 *     - byac@set_suffix
 */
export function collectionIdForProjectId(projectId: string) {
  return splitProjectId(projectId)?.collectionId;
}

export type ProjectIdSplit = {
  collectionId: string;
  rankingSetHandle: string;
  suffix: string;
};

export function splitProjectId(projectId: string): ProjectIdSplit | undefined {
  if (!projectId) {
    return undefined;
  }

  // Since the underscore `_` can be part of the collection id, but was historically
  // also used as a separator between collection id and suffix, we have to account
  // for a number of special cases.
  const formats = [
    // 1. To avoid the issue entirely, new-style project ids use the marker `__sfx__`
    // instead of a simple underscore. Once all collections have been migrated, this
    // should be the only regex we need (with the suffix part made optional).
    //
    // Going forward, all cases involving `__sfx__` are dealt with.
    /^(?<collection>.*?)(?:@(?<handle>.*?))?__sfx__(?<suffix>.*)$/,

    // 2. If there is a handle, the @ separator avoids any dispute about the role of an underscore.
    // Going forward, all cases involving a ranking set handle are dealt with.
    /^(?<collection>.*?)@(?<handle>.*?)(?:_(?<suffix>.*))?$/,

    // 3. Generally, the suffix in old-style project ids is 32 hex characters.
    // Going forward, all cases where we are able to recognize a suffix are dealt with.
    /^(?<collection>.*?)_(?<suffix>[0-9a-f]{32})?$/,

    // 4. Therefore, we must assume all of it is the collection id.
    /^(?<collection>.*)$/,
  ];

  for (const f of formats) {
    const match = projectId.match(f);
    if (match) {
      return {
        collectionId: match?.groups?.['collection'] ?? '',
        rankingSetHandle: match?.groups?.['handle'] ?? '',
        suffix: match?.groups?.['suffix'] ?? '',
      };
    }
  }

  return undefined;
}

export interface ProjectQueryParams {
  filterSet?: string;
  filters?: string;

  sort?: string;

  preset?: string;
  matches?: 'on' | 'off';

  method?: 'score' | 'statistical' | 'average' | 'trait' | 'preRanked';
  normalize?: 'on' | 'off';
  uniqueness?: 'on' | 'off';
  weights?: 'on' | 'off';
  combined?: 'on' | 'off';
  dataItem?: string;
  propWeights?: string;
  valueWeights?: string;
}

export interface ExploredTraitValue {
  propName: string;
  encodedValue: any;
  actualValue: string | number | null | undefined;
  valueIndex: number;
  count: number;
  score: number;
  floorPrice: number;
  cheapestTokenId?: string | null;
  exampleTokenIds: string[];
}

//

declare var window: any;

export class Project {
  filtersActive: { [prop: string]: { [encodedValue: string]: boolean } } = {};
  filterSet?: string;
  lastAppliedQueryString?: string;
  lastAppliedSort?: string;

  sort: string = 'rarity';

  calcOptions!: CalculationOptions;
  matches!: boolean;

  presetsLookup: { [id: string]: CalculationOptions } = {};
  presetsList: CalculationOptions[] = standardPresets;

  get defaultMatches() {
    return this.config.rankings?.defaultMatches || false;
  }
  get defaultPreset() {
    return this.config.rankings?.defaultPreset || 'rarity-tools-2';
  }
  get showWeights() {
    return this.config.rankings?.showWeights || false;
  }
  get enableMethods() {
    return this.config.rankings?.enableMethods;
  }
  get enableUniqueness() {
    return this.config.rankings?.enableUniqueness;
  }
  get enableMatches() {
    return this.config.rankings?.enableMatches;
  }
  get enableCombined() {
    return this.config.rankings?.enableCombined;
  }
  get disableNormalization() {
    return this.config.rankings?.disableNormalization;
  }
  get disableSettings() {
    return this.config.rankings?.disableSettings;
  }

  get hideOwners() {
    if (this.config.options?.hideOwners) return true;

    for (var contract of this.config.contracts) if (contract.tokenStandard == 'ERC1155') return true;

    return false;
  }

  ///////////////////////////////////////////

  modeLists: { [modeName: string]: ModeList } = {
    all: new ModeList(),
    wallet: new ModeList(),
    recentlyListed: new ModeList(), // only for direct opensea query (!hasPrice)
  };

  // used for mooncats
  tokenIdsToIds: { [id: string]: string } = {};

  oldestRecentlyListedDate?: Date;

  cheapestIds: string[] = [];
  mostExpensiveIds: string[] = [];

  itemLookup: { [id: string]: any[] } = {};
  extraDataLookup: { [id: string]: NFTExtraData } = {};

  basePropDefLookup: { [name: string]: BasePropDef } = {};
  derivedPropDefLookup: { [name: string]: DerivedPropDef } = {};

  propValDrillDownCounts: {
    [propName: string]: {
      [encodedValue: string]: number;
    };
  } = {};

  propValPriceFloors: {
    [propName: string]: {
      [encodedValue: string]: number;
    };
  } = {};

  /** @deprecated We should use the MediaFile to use the CDN. */
  imgFile?: ImgFile;

  mediaFile?: MediaFile;

  enableDrillDown = true;

  ///////////////////////////////////////////

  static OTHER_FILTERS = ['minPrice', 'maxPrice', 'minRank', 'maxRank', 'ownerAddress', 'buyNow', 'auction'];

  constructor(
    readonly data: ProjectData,
    readonly config: ProjectConfig,
    readonly json: ProjectsJsonItem,
    readonly pricesData?: priceData.ProjectPriceData
  ) {
    for (var item of this.data.items) {
      // first item is always the id
      this.itemLookup[item[0]] = item;
      // save all ids
      this.modeLists.all.ids.push(item[0]);
    }
    for (var def of this.data.basePropDefs) {
      this.basePropDefLookup[def.name] = def;
    }

    if (this.data.derivedPropDefs) {
      for (var ddef of this.data.derivedPropDefs) {
        this.derivedPropDefLookup[ddef.name] = ddef;
      }
    }

    iso.set(this, 'matches', this.defaultMatches);

    if (config.rankings?.presets) {
      iso.set(this, 'presetsList', config.rankings.presets);
    }
    var lookup: any = {};
    for (var preset of this.presetsList) {
      lookup[preset.id!] = preset;
    }
    iso.set(this, 'presetsLookup', lookup);

    iso.set(this, 'calcOptions', this.presetsLookup[this.defaultPreset]);

    if (this.pricesData && this.hasPrices) {
      var now = new Date().valueOf();
      for (var item of this.data.items) {
        var tokenId = item[0];
        var extraData = this.extraDataOf(tokenId);
        iso.set(
          extraData,
          'priceData',
          priceData.interpretPriceFromPriceData(this.pricesData.startDate, this.pricesData.prices[tokenId])
        );

        this.updateFloors(item, now);
      }

      // now calculate value scores
      /*
			for (var item of this.data.items) {
				var tokenId = item[0]
				var vscore = 0
				for (var prop of this.data.basePropDefs) {
					if (this.propValPriceFloors[prop.name])
						vscore += this.propValPriceFloors[prop.name][this.getPropertyEncodedValue(prop, item)]
				}
				for (var bprop of this.data.derivedPropDefs) {
					if (this.propValPriceFloors[bprop.name])
						vscore += this.propValPriceFloors[bprop.name][this.getPropertyEncodedValue(bprop, item)]
				}
				this.valueScore[tokenId] = vscore
			}
			*/
    }

    this.applyRankings();
    this.applyFiltersAndSort();
  }

  setMediaFile(mediaFile: MediaFile) {
    this.mediaFile = mediaFile;
  }

  static get isDevelopment() {
    return window.location.href.indexOf('localhost') >= 0;
  }

  static get dataBaseURL() {
    return process.env.DATA_ORIGIN ?? 'https://data.rarity.tools';
  }

  get chain() {
    // Assume Ethereum by default.
    return this.config.chain || 'ethereum';
  }

  get supportsSortByRecentlyListed() {
    return this.chain == 'ethereum';
  }

  /**
   * True if price data has been loadsed
   */
  get hasPrices() {
    return this.pricesData?.v;
  }

  static async loadPricesData(projectId: string) {
    try {
      var datas = (await vhttp.get(`${this.dataBaseURL}/prices/${projectId.split('@')[0]}`)).data;
      return datas;
    } catch (error) {
      return null;
    }
  }

  ////////////////////////////////
  // Id Lookup Cache (for api server)

  displayIdLookup?: { [id: string]: any[] };
  minDisplayId?: number;
  maxDisplayId?: number;

  buildDisplayIdCache() {
    var propDef = this.basePropDefLookup['displayId'];
    this.displayIdLookup = {};
    for (var item of this.data.items) {
      var displayId = item[0];
      if (propDef) {
        var replDisplayId = this.getPropertyEncodedValue(propDef, item);
        if (replDisplayId) displayId = replDisplayId;
      }
      if (typeof displayId == 'undefined') {
        displayId = item[0];
      }

      if (displayId.length < 10) {
        var displayIdNum = parseInt(displayId);
        if (!isNaN(displayIdNum)) {
          if (typeof this.minDisplayId == 'undefined') {
            this.minDisplayId = displayIdNum;
          } else {
            if (displayIdNum < this.minDisplayId) this.minDisplayId = displayIdNum;
          }
          if (typeof this.maxDisplayId == 'undefined') {
            this.maxDisplayId = displayIdNum;
          } else {
            if (displayIdNum > this.maxDisplayId) this.maxDisplayId = displayIdNum;
          }
        }
      }
      this.displayIdLookup[displayId] = item;
    }
  }

  lookupByDisplayId(displayId: string) {
    if (!this.displayIdLookup) {
      this.buildDisplayIdCache();
    }
    return this.displayIdLookup![displayId];
  }

  ////////////////////////////////
  // General

  get nftCount() {
    return this.data.items.length;
  }

  getContractAndTokenId(id: string) {
    if (id.startsWith('c') && id[2] == '_') {
      var contractNo = parseInt(id[1]) - 1;
      return {
        contract: this.config.contracts[contractNo].contract,
        tokenId: id.split('_')[1],
      };
    }
    return {
      contract: this.config.contracts[0].contract,
      tokenId: id,
    };
  }

  constructId(tokenId: string, contractAddress: string) {
    if (this.config.contracts.length == 1) {
      return tokenId;
    }

    for (var i = 0; i < this.config.contracts.length; i++) {
      if (this.config.contracts[i].contract == contractAddress) {
        if (i == 0) {
          return tokenId;
        } else {
          return `c${i + 1}_${tokenId}`;
        }
      }
    }

    // not found
    return tokenId;
  }

  /////

  equals(obj1: any, obj2: any) {
    for (var i in obj1) {
      if (obj1.hasOwnProperty(i)) {
        if (!obj2.hasOwnProperty(i)) return false;
        if (obj1[i] != obj2[i]) return false;
      }
    }
    for (var i in obj2) {
      if (obj2.hasOwnProperty(i)) {
        if (!obj1.hasOwnProperty(i)) return false;
        if (obj1[i] != obj2[i]) return false;
      }
    }
    return true;
  }

  get queryParamsRaw(): ProjectQueryParams {
    return this.getQueryParamsRawUsing(this.calcOptions);
  }

  getQueryParamsRawUsing(calcOptions: CalculationOptions): ProjectQueryParams {
    var params: ProjectQueryParams = {
      filters: this.filterQueryValue,
      filterSet: this.filterSet,
      sort: this.sort,
      matches: this.enableMatches ? (this.matches || false ? 'on' : 'off') : undefined,
      method: calcOptions.method,
      normalize: calcOptions.normalize || false ? 'on' : 'off',
      uniqueness: this.enableUniqueness ? (calcOptions.uniqueness || false ? 'on' : 'off') : undefined,
      weights: this.showWeights ? (calcOptions.weights || false ? 'on' : 'off') : undefined,
      combined: this.enableCombined ? (calcOptions.combined || false ? 'on' : 'off') : undefined,
      dataItem: calcOptions.dataItem,
    };
    if (calcOptions.propWeights) {
      params.propWeights = urlon.stringify(calcOptions.propWeights);
    }
    if (calcOptions.valueWeights) {
      params.valueWeights = urlon.stringify(calcOptions.valueWeights);
    }
    return params;
  }

  get queryParamsMinimized() {
    return this.minimizeQueryParams(this.queryParamsRaw);
  }

  get currentPreset() {
    return this.getPresetMatching(this.queryParamsRaw);
  }

  getPresetMatching(queryParams: ProjectQueryParams) {
    // we ignore
    // see if we match any presets
    var matchingPreset: string | null = null;
    for (var presetId in this.presetsLookup) {
      var preset = this.presetsLookup[presetId];
      var rawParams = this.getQueryParamsRawUsing(preset);
      // assign sort to rawParams
      rawParams.sort = queryParams.sort;
      rawParams.filterSet = queryParams.filterSet;
      if (this.equals(rawParams, queryParams)) {
        matchingPreset = presetId;
        break;
      }
    }
    return matchingPreset;
  }

  minimizeQueryParams(queryParams: ProjectQueryParams) {
    // see if we match any presets (match to same matches as preset doesn't include matches)
    var matcher = Object.assign({}, queryParams);
    matcher.matches = this.queryParamsRaw.matches;

    var matchingPreset = this.getPresetMatching(matcher);

    var result: ProjectQueryParams = {};
    if (matchingPreset) {
      result.preset = matchingPreset;
      result.matches = queryParams.matches;
      if (matchingPreset == this.defaultPreset) delete result.preset;
    } else {
      result = queryParams;
    }

    if (result.matches == (this.defaultMatches ? 'on' : 'off')) {
      delete result.matches;
    }
    if (!this.enableMethods) delete result.method;
    if (this.disableNormalization) delete result.normalize;

    result.filters = this.filterQueryValue;
    result.sort = queryParams.sort || 'rarity';
    result.filterSet = queryParams.filterSet;

    if (!result.filterSet) delete result.filterSet;

    if (result.sort == 'rarity') delete result.sort;

    return result;
  }

  queryParamsOverridedWith(queryParams: ProjectQueryParams) {
    if (queryParams.preset) {
      return this.minimizeQueryParams(this.getQueryParamsRawUsing(this.presetsLookup[queryParams.preset]));
    }
    if (queryParams.matches) {
    }
    var newParams: ProjectQueryParams = Object.assign({}, this.queryParamsRaw, queryParams);
    return this.minimizeQueryParams(newParams);
  }

  sanitizePropWeights(newWeights: { [index: string]: number }) {
    var result: { [index: string]: number } = {};
    for (var propName in newWeights) {
      var value = newWeights[propName];
      var actualName = this.getPropNameFromAnyCase(propName);
      var propDef = this.getPropDef(actualName);
      if ((typeof propDef != 'undefined' || actualName == 'Uniqueness') && typeof value == 'number') {
        result[actualName] = value;
      }
    }
    return result;
  }

  getCalcOptionsFromInputQueryParams(queryParams: ProjectQueryParams, specifiedOnly: boolean) {
    if (
      !queryParams.preset &&
      !queryParams.method &&
      !queryParams.weights &&
      !queryParams.normalize &&
      !queryParams.combined &&
      !queryParams.uniqueness &&
      !queryParams.dataItem
    ) {
      // use default preset
      return this.presetsLookup[this.defaultPreset];
    } else if (queryParams.preset) {
      // use specified preset
      return this.presetsLookup[queryParams.preset];
    } else {
      // use specified values
      if (specifiedOnly) {
        var calcOptions: CalculationOptions = {};
        if (typeof queryParams.method != 'undefined') calcOptions.method = queryParams.method;
        if (typeof queryParams.normalize != 'undefined') calcOptions.normalize = queryParams.normalize == 'on';
        if (typeof queryParams.uniqueness != 'undefined') calcOptions.uniqueness = queryParams.uniqueness == 'on';
        if (typeof queryParams.weights != 'undefined') calcOptions.weights = queryParams.weights == 'on';
        if (typeof queryParams.propWeights != 'undefined')
          calcOptions.propWeights = this.sanitizePropWeights(urlon.parse(queryParams.propWeights));
        if (typeof queryParams.valueWeights != 'undefined')
          calcOptions.valueWeights = urlon.parse(queryParams.valueWeights);
        if (typeof queryParams.combined != 'undefined') calcOptions.combined = queryParams.combined == 'on';
        if (typeof queryParams.dataItem != 'undefined') calcOptions.dataItem = queryParams.dataItem;
        return calcOptions;
      } else {
        var calcOptions: CalculationOptions = {};
        calcOptions.method = queryParams.method || 'score';
        calcOptions.normalize = queryParams.normalize == 'on';
        calcOptions.uniqueness = queryParams.uniqueness == 'on';
        calcOptions.weights = queryParams.weights == 'on';
        calcOptions.combined = queryParams.combined == 'on';
        if (queryParams.propWeights)
          calcOptions.propWeights = this.sanitizePropWeights(urlon.parse(queryParams.propWeights));
        if (queryParams.valueWeights) queryParams.valueWeights = urlon.parse(queryParams.valueWeights);
        if (queryParams.dataItem) calcOptions.dataItem = queryParams.dataItem;
        calcOptions.name = 'Custom';
      }
      return calcOptions;
    }
  }

  applyQueryParams(queryParams: ProjectQueryParams) {
    if (!this.enableMethods && queryParams.method) delete queryParams.method;
    if (!this.enableUniqueness && queryParams.uniqueness) delete queryParams.uniqueness;

    // see if rankings must change
    var testTheirs = Object.assign({}, queryParams);
    var testOurs = Object.assign({}, this.queryParamsMinimized);
    delete testTheirs.filters;
    delete testOurs.filters;

    if (queryParams.sort) {
      this.sort = queryParams.sort;
    } else {
      this.sort = 'rarity';
    }
    iso.set(this, 'filterSet', queryParams.filterSet);

    var rankingsMustChange = !this.equals(testOurs, testTheirs);

    if (rankingsMustChange) {
      // set the data
      this.calcOptions = this.getCalcOptionsFromInputQueryParams(queryParams, false);

      if (!queryParams.matches) {
        this.matches = this.defaultMatches;
      } else {
        this.matches = queryParams.matches == 'on';
      }

      this.applyRankings();
    }

    this.applyFiltersAndSortFromRoute(queryParams.filters, this.sort, rankingsMustChange);
  }

  applyFiltersAndSortFromRoute(newQuery: string | undefined, newSort: string, forceApply: boolean) {
    if (forceApply || this.lastAppliedQueryString != newQuery || this.lastAppliedSort != newSort) {
      try {
        if (newQuery === null || typeof newQuery == 'undefined') {
          iso.set(this, 'filtersActive', {});
        } else {
          // covert query -> filtersActive
          iso.set(this, 'filtersActive', urlon.parse(newQuery));
        }
        iso.set(this, 'lastAppliedQueryString', newQuery);
        iso.set(this, 'lastAppliedSort', newSort);
        this.applyFiltersAndSort();
      } catch (e) {
        // null
      }
    }
  }

  get filterQueryValue() {
    if (!this.hasFiltersActive) {
      return undefined;
    }
    for (var propName in this.filtersActive) {
      var propFilters = this.filtersActive[propName];
      var found = false;
      for (var propValue in propFilters) {
        if (!propFilters[propValue]) {
          iso.del(propFilters, propValue);
        } else {
          found = true;
        }
      }
      if (!found) {
        iso.del(this.filtersActive, propName);
      }
    }
    // convert filtersActive -> query
    return urlon.stringify(this.filtersActive);
  }

  applyFiltersAndSort() {
    iso.set(this, 'propValDrillDownCounts', {});

    // filter items and mark
    var drillDown = this.hasFiltersActive && this.enableDrillDown;
    var otherFiltersParsed = this.otherFiltersParsed;
    var now = new Date().valueOf();
    for (var item of this.data.items) {
      // first item is always the id
      var passesFilter = this.passesFilter(item, otherFiltersParsed, now);
      if (passesFilter && drillDown) this.countDrillDowns(item);

      this.extraDataOf(item[0]).filteredOut = !passesFilter;
    }

    // filter each modeList
    for (var modeName in this.modeLists) {
      var mode = this.modeLists[modeName] as ModeList;
      var results = [];
      for (var id of mode.ids) {
        if (!this.extraDataOf(id).filteredOut) results.push(id);
      }

      // sort results
      this._sortIdsWithCurrentSort(results);

      // apply to modeList
      mode.setFilteredIds(results);
      mode.updateStats(this);
    }
  }

  // ids are expected to be sorted by rarity
  _sortIdsWithCurrentSort(ids: string[]) {
    return this._sortIdsWithSort(ids, this.sort);
  }

  _sortIdsWithSort(ids: string[], sort: string) {
    var now = new Date().valueOf();
    if (sort == 'rarity') {
      return;
    } else if (sort == 'priceLowHigh') {
      ids.sort((id1, id2) => {
        var id1Price = this.getPriceEthIncAuctions(id1, now);
        var id2Price = this.getPriceEthIncAuctions(id2, now);
        if (id1Price && !id2Price) return -1;
        if (id2Price && !id1Price) return 1;
        if (!id1Price && !id2Price) return 0;
        return id1Price! - id2Price!;
      });
    } else if (sort == 'priceHighLow') {
      ids.sort((id1, id2) => {
        var id1Price = this.getPriceEthIncAuctions(id1, now);
        var id2Price = this.getPriceEthIncAuctions(id2, now);
        if (id1Price && !id2Price) return -1;
        if (id2Price && !id1Price) return 1;
        if (!id1Price && !id2Price) return 0;
        return id2Price! - id1Price!;
      });
    } else if (sort == 'recentlyListed') {
      ids.sort((id1, id2) => {
        var id1Price = this.extraDataOf(id1)?.priceData;
        var id2Price = this.extraDataOf(id2)?.priceData;
        if (id1Price && now < id1Price.begin) {
          id1Price = undefined;
        }
        if (id2Price && now < id2Price.begin) {
          id2Price = undefined;
        }
        if (id1Price && !id2Price) return -1;
        if (id2Price && !id1Price) return 1;
        if (!id1Price && !id2Price) return 0;
        return id2Price!.begin - id1Price!.begin;
      });
    } else if (sort == 'byId') {
      var displayIdProp = this.basePropDefLookup['displayId'];
      if (displayIdProp) {
        ids.sort((id1, id2) => {
          var displayId1 = this.getPropertyEncodedValue(displayIdProp, this.itemLookup[id1]);
          var displayId2 = this.getPropertyEncodedValue(displayIdProp, this.itemLookup[id2]);
          try {
            return parseInt(displayId1) - parseInt(displayId2);
          } catch (e) {
            return 0;
          }
        });
      } else {
        ids.sort((id1, id2) => {
          try {
            return BigInt(id1) > BigInt(id2) ? 1 : -1;
          } catch (e) {
            return 0;
          }
        });
      }
    }
    /*
		else if (sort == 'valueScore') {
			ids.sort((id1, id2) => {
				return this.valueScore[id2] - this.valueScore[id1]
			})
		}
		*/
  }

  getFilteredIdsForMode(modeName: string) {
    return this.modeLists[modeName].filteredIds;
  }

  get hasFiltersActive() {
    for (var prop in this.filtersActive) {
      var valueIndexes = this.filtersActive[prop];
      for (var valueIndex in valueIndexes) {
        if (valueIndexes[valueIndex]) return true;
      }
    }
    return false;
  }

  get otherFilters() {
    var result: any = {};
    for (var prop of Project.OTHER_FILTERS) {
      if (this.filtersActive[prop]) {
        for (var value in this.filtersActive[prop]) {
          result[prop] = value;
          break;
        }
      }
    }
    return result;
  }

  get otherFiltersParsed() {
    var result: any = {};
    for (var prop of Project.OTHER_FILTERS) {
      if (this.filtersActive[prop]) {
        for (var value in this.filtersActive[prop]) {
          if (prop == 'minPrice' || prop == 'maxPrice') {
            result[prop] = parseFloat(value);
          } else if (prop == 'minRank' || prop == 'maxRank') {
            result[prop] = parseInt(value);
          } else if (prop == 'buyNow' || prop == 'auction') {
            result[prop] = true;
          }
          break;
        }
      }
    }
    return result;
  }

  passesFilter(item: any[], otherFiltersParsed: any, now: number) {
    var now = new Date().valueOf();
    var ethPrice = this.getPriceEth(item[0], now);
    for (var prop in otherFiltersParsed) {
      var value = otherFiltersParsed[prop];
      if (prop == 'minPrice') {
        if (!ethPrice) return false;
        if (ethPrice < value) return false;
      } else if (prop == 'maxPrice') {
        if (!ethPrice) return false;
        if (ethPrice > value) return false;
      } else if (prop == 'minRank') {
        if (this.rankOf(item[0]) < value) return false;
      } else if (prop == 'maxRank') {
        if (this.rankOf(item[0]) > value) return false;
      }
    }

    var extraData = this.extraDataOf(item[0]);
    var isBuyNow = this.getPriceEth(item[0], now);
    var isAuction =
      extraData?.priceData &&
      extraData.priceData?.type == 2 &&
      now > extraData.priceData.begin &&
      now < extraData.priceData.end!;

    if (otherFiltersParsed.buyNow && otherFiltersParsed.auction) {
      if (!isBuyNow && !isAuction) return false;
    } else if (otherFiltersParsed.buyNow) {
      if (!isBuyNow) return false;
    } else if (otherFiltersParsed.auction) {
      if (!isAuction) return false;
    }

    for (var prop in this.filtersActive) {
      var valueIndexes = this.filtersActive[prop];
      var propDef = this.getPropDef(prop)!;
      if (!propDef) continue;
      var derivedPropDef = this.derivedPropDefLookup[prop];
      var hasMatch = false;
      var hasValue = false;
      var hasMiss = false;
      for (var valueIndex in valueIndexes) {
        var encodedValue: any = valueIndex;
        hasValue = true;
        if ((propDef as BasePropDef).type == 'tags') {
          var itemPv = (propDef as BasePropDef).pvs![parseInt(valueIndex)];
          var tagsInItem = this.getPropertyEncodedValue(propDef, item);
          if (itemPv[0] == '@@_rt_no_tags') {
            if (tagsInItem.length == 0) hasMatch = true;
            else hasMiss = true;
          } else {
            if (tagsInItem.indexOf(parseInt(valueIndex)) != -1) hasMatch = true;
            else hasMiss = true;
          }
        } else {
          if (derivedPropDef) {
            encodedValue = derivedPropDef.pvs![parseInt(valueIndex)][0];
          }
          // lookup the index
          if (this.getPropertyEncodedValue(propDef, item) == encodedValue) {
            hasMatch = true;
          }
        }
      }
      if ((hasValue && !hasMatch) || (hasMiss && this.enableDrillDown)) {
        return false;
      }
    }

    if (!this.passesFilterSet(item)) return false;

    return true;
  }

  passesFilterSet(item: any[]) {
    if (this.filterSet && this.config.filterSets) {
      for (var filterSet of this.config.filterSets) {
        if (filterSet.id == this.filterSet) {
          // also use filterSet settings
          if (filterSet.excludeIf) {
            var allMatch = false;
            for (var trait in filterSet.excludeIf) {
              var pDef = this.getPropDef(trait);
              if (pDef) {
                var value = this.getPropertyEncodedValue(pDef, item);
                var actualValue = this.getPropActualValue(trait, value);
                if (actualValue == filterSet.excludeIf[trait]) {
                  allMatch = true;
                } else {
                  allMatch = false;
                }
              }
            }
            if (allMatch) {
              return false;
            }
          }

          if (filterSet.includeIf) {
            var allMatch = false;
            for (var trait in filterSet.includeIf) {
              var pDef = this.getPropDef(trait);
              if (pDef) {
                var value = this.getPropertyEncodedValue(pDef, item);
                var actualValue = this.getPropActualValue(trait, value);
                if (actualValue == filterSet.includeIf[trait]) {
                  allMatch = true;
                } else {
                  allMatch = false;
                }
              }
            }
            if (!allMatch) {
              return false;
            }
          }

          if (filterSet.includeIfHasTrait) {
            var found = false;
            var pDef = this.getPropDef(filterSet.includeIfHasTrait);
            if (pDef) {
              var value = this.getPropertyEncodedValue(pDef, item);
              if ((pDef as BasePropDef).type != 'tags') {
                var actualValue = this.getPropActualValue(filterSet.includeIfHasTrait, value);
                if (actualValue) {
                  found = true;
                  break;
                }
              } else {
                if (value && value.length) {
                  found = true;
                  break;
                }
              }
            }
            if (!found) return false;
          }

          if (filterSet.includeIfNotHasTrait) {
            var pDef = this.getPropDef(filterSet.includeIfNotHasTrait);
            if (pDef) {
              var value = this.getPropertyEncodedValue(pDef, item);
              if ((pDef as BasePropDef).type != 'tags') {
                var actualValue = this.getPropActualValue(filterSet.includeIfNotHasTrait, value);
                if (actualValue) {
                  return false;
                }
              } else {
                if (value && value.length) {
                  return false;
                }
              }
            }
          }
        }
      }
    }
    return true;
  }

  updateFloors(item: any[], now: number) {
    var ethPrice = this.getPriceEth(item[0], now);
    if (!ethPrice) return;

    for (var propIndex = 1; propIndex <= this.data.basePropDefs.length; propIndex++) {
      if (propIndex == this.data.basePropDefs.length) {
        if (this.data.derivedPropDefs) {
          // count derived props
          var derivedValues = item[propIndex];
          for (var derivedPropIndex = 0; derivedPropIndex < this.data.derivedPropDefs.length; derivedPropIndex++) {
            var derivedProp = this.data.derivedPropDefs[derivedPropIndex];
            var encodedValue = derivedValues[derivedPropIndex];

            if (!this.propValPriceFloors[derivedProp.name]) {
              iso.set(this.propValPriceFloors, derivedProp.name, {});
            }
            if (!this.propValPriceFloors[derivedProp.name][encodedValue]) {
              iso.set(this.propValPriceFloors[derivedProp.name], encodedValue, ethPrice);
            } else {
              if (ethPrice < this.propValPriceFloors[derivedProp.name][encodedValue])
                iso.set(this.propValPriceFloors[derivedProp.name], encodedValue, ethPrice);
            }
          }
        }
      } else {
        var baseProp = this.data.basePropDefs[propIndex];
        var encodedValue = item[propIndex];
        if (!this.propValPriceFloors[baseProp.name]) {
          iso.set(this.propValPriceFloors, baseProp.name, {});
        }
        if (baseProp.type == 'tags') {
          for (var tag of encodedValue) {
            if (!this.propValPriceFloors[baseProp.name][tag]) {
              iso.set(this.propValPriceFloors[baseProp.name], tag, ethPrice);
            } else {
              if (ethPrice < this.propValPriceFloors[baseProp.name][tag])
                this.propValPriceFloors[baseProp.name][tag] = ethPrice;
            }
          }
        } else {
          if (!this.propValPriceFloors[baseProp.name][encodedValue]) {
            iso.set(this.propValPriceFloors[baseProp.name], encodedValue, ethPrice);
          } else {
            if (ethPrice < this.propValPriceFloors[baseProp.name][encodedValue])
              this.propValPriceFloors[baseProp.name][encodedValue] = ethPrice;
          }
        }
      }
    }
  }

  countDrillDowns(item: any[]) {
    for (var propIndex = 1; propIndex <= this.data.basePropDefs.length; propIndex++) {
      if (propIndex == this.data.basePropDefs.length) {
        if (this.data.derivedPropDefs) {
          // count derived props
          var derivedValues = item[propIndex];
          for (var derivedPropIndex = 0; derivedPropIndex < this.data.derivedPropDefs.length; derivedPropIndex++) {
            var derivedProp = this.data.derivedPropDefs[derivedPropIndex];
            var encodedValue = derivedValues[derivedPropIndex];
            if (!this.propValDrillDownCounts[derivedProp.name]) {
              iso.set(this.propValDrillDownCounts, derivedProp.name, {});
            }
            if (!this.propValDrillDownCounts[derivedProp.name][encodedValue]) {
              iso.set(this.propValDrillDownCounts[derivedProp.name], encodedValue, 1);
            } else {
              this.propValDrillDownCounts[derivedProp.name][encodedValue]++;
            }
          }
        }
      } else {
        var baseProp = this.data.basePropDefs[propIndex];
        var encodedValue = item[propIndex];
        if (!this.propValDrillDownCounts[baseProp.name]) {
          iso.set(this.propValDrillDownCounts, baseProp.name, {});
        }
        if (baseProp.type == 'tags') {
          var foundTag = false;
          for (var tag of encodedValue) {
            foundTag = true;
            if (!this.propValDrillDownCounts[baseProp.name][tag]) {
              iso.set(this.propValDrillDownCounts[baseProp.name], tag, 1);
            } else {
              this.propValDrillDownCounts[baseProp.name][tag]++;
            }
          }
          if (!foundTag && this.data.tagNones && baseProp.tagNonePv) {
            if (!this.propValDrillDownCounts[baseProp.name][baseProp.tagNoneIndex!]) {
              iso.set(this.propValDrillDownCounts[baseProp.name], baseProp.tagNoneIndex, 1);
            } else {
              this.propValDrillDownCounts[baseProp.name][baseProp.tagNoneIndex!]++;
            }
          }
        } else {
          if (!this.propValDrillDownCounts[baseProp.name][encodedValue]) {
            iso.set(this.propValDrillDownCounts[baseProp.name], encodedValue, 1);
          } else {
            this.propValDrillDownCounts[baseProp.name][encodedValue]++;
          }
        }
      }
    }
  }

  getDataPropertyValue(id: string, propName: string) {
    var item = this.itemLookup[id];
    var propDef = this.getPropDef(propName) as BasePropDef;
    if (!propDef) return undefined;
    if (propDef.type == 'data') return this.getPropertyEncodedValue(propDef, item);
    return undefined;
  }

  getPropertyEncodedValue(propDef: PropDef, item: any[]) {
    var baseIndex = this.data.basePropDefs.indexOf(propDef as BasePropDef);
    if (baseIndex != -1) {
      return item[baseIndex];
    }
    if (this.data.derivedPropDefs) {
      var derivedIndex = this.data.derivedPropDefs.indexOf(propDef as DerivedPropDef);
      if (derivedIndex != -1) {
        return item[this.data.basePropDefs.length][derivedIndex];
      }
    }
    return undefined;
  }

  ////////////////////////////////
  // OpenSea data loading

  async loadMoreRecentlyListed() {
    var recentlyListedIds = this.modeLists['recentlyListed'].ids;
    if (recentlyListedIds.length) {
      if (this.oldestRecentlyListedDate) {
        await this.loadRecentlyListed(this.oldestRecentlyListedDate);
      }
    } else {
      this.loadMoreRecentlyListed();
    }
  }

  /**
   * Loads all items from opensea in order of their recent listing.
   */
  async loadRecentlyListed(last?: Date, forceReload = false) {
    if (this.hasPrices) {
    } else {
      var recentlyListed = this.modeLists['recentlyListed'];
      if (!forceReload && !last) {
        if (recentlyListed.ids.length > 0) return;
      }
      recentlyListed.loading = true;
      if (!last) {
        iso.set(this, 'recentlyListedIds', []);
        iso.set(this, 'recentlyListedIdsInclude', {});
        iso.set(this, 'oldestRecentlyListedDate', null);
      }

      var idDatePairs: [string, Date][] = [];
      for (var contract of this.config.contracts) {
        var apiURL = this._getOpenSeaAPIUrlForRecentlyListed(contract.contract, last);
        if (apiURL) {
          var result = await opensea.makeOpenSeaAPIRequest(apiURL);
          if (result) {
            for (var event of result.data.asset_events) {
              var id = this.constructId(event.asset.token_id, contract.contract);
              if (event.asset && this.itemLookup[id]) {
                this._setOpenSeaDataForEvent(id, event);
                if (!recentlyListed.alreadyContains[id]) {
                  recentlyListed.alreadyContains[id] = true;
                  idDatePairs.push([id, new Date(event.created_date + 'Z')]);
                }
                this.oldestRecentlyListedDate = new Date(event.created_date + 'Z');
              }
            }
          }
        }
      }

      idDatePairs.sort((a, b) => {
        return b[1].valueOf() - a[1].valueOf();
      });
      var otherFiltersParsed = this.otherFiltersParsed;
      var now = new Date().valueOf();
      for (var pair of idDatePairs) {
        if (this.extraDataOf(pair[0]).priceData) {
          recentlyListed.ids.push(pair[0]);
          if (this.passesFilter(this.itemLookup[pair[0]], otherFiltersParsed, now)) {
            recentlyListed.filteredIds.push(pair[0]);
          }
        }
      }
      recentlyListed.updateStats(this);
      recentlyListed.loading = false;
    }
  }

  async updateMarketData(ids: string[], useDataRT = false) {
    if (this.id == 'axie') {
      var remaining = ids.slice();
      var assets: any[] = [];
      while (remaining.length) {
        var maxPer = 32;
        var nextSet = remaining.splice(0, maxPer);
        assets.concat(await this.updateAxieDataMax50(nextSet));
      }
      return assets;
    } else if (this.chain == 'ethereum') {
      var remaining = ids.slice();
      var assets: any[] = [];
      while (remaining.length) {
        var maxPer = 50;
        if (remaining[0].length > 10) maxPer = 25;
        var nextSet = remaining.splice(0, maxPer);
        assets.concat(await this.updateOpenSeaDataMax50(nextSet, useDataRT));
      }
      return assets;
    } else {
      return [];
    }
  }

  async updateAxieDataMax50(ids: string[]) {
    var results = await axie.getAxieIds(ids);
    for (var id in results) {
      var axieDetails = results[id];
      var extraData = this.extraDataOf(id);

      iso.set(extraData, 'ownerAddress', axieDetails.owner);
      iso.set(extraData, 'ownerUser', axieDetails.ownerProfile?.name ?? '');
      iso.set(extraData, 'name', axieDetails.name);

      if (!this.hasPrices)
        if (axieDetails.auction) {
          var price = parseFloat(axieDetails.auction.currentPrice) / Math.pow(10, 18);
          var priceData: priceData.InterpretedPriceData = {
            price_eth: price,
            price: price,
            price_begin: price,
            type: 0,
            begin: new Date().valueOf(),
          };
          iso.set(extraData, 'priceData', priceData);
        }
    }
  }

  async updateOpenSeaDataMax50(ids: string[], useDataRT = false) {
    var contractsToTry: { contract: string; tokenIds: string[] }[] = [];

    if (this.config.tryContracts) {
      // just for mooncats

      for (var i = 0; i < this.config.contracts.length; i++) {
        if (i == 0) {
          contractsToTry.push({
            contract: this.config.contracts[i].contract,
            tokenIds: ids,
          });
        } else {
          contractsToTry.push({
            contract: this.config.contracts[i].contract,
            tokenIds: [],
          });
        }
      }
    } else {
      var tokenIdsByContract: { [contractAddress: string]: string[] } = {};
      for (var id of ids) {
        var idInfo = this.getContractAndTokenId(id);
        if (!tokenIdsByContract[idInfo.contract]) {
          tokenIdsByContract[idInfo.contract] = [];
        }
        tokenIdsByContract[idInfo.contract].push(idInfo.tokenId);
      }

      for (var contract of this.config.contracts) {
        if (tokenIdsByContract[contract.contract]) {
          contractsToTry.push({ contract: contract.contract, tokenIds: tokenIdsByContract[contract.contract] });
        }
      }
    }

    var results: any[] = [];

    for (var contractItem of contractsToTry) {
      var contractAddress = contractItem.contract;
      var tokenIds = contractItem.tokenIds;
      var isExtraContract = false;

      if (!tokenIds.length && this.config.tryContracts) {
        isExtraContract = true;
        // in this mode we keep trying the remaining missing ids (only used for mooncats)
        var missingIds: string[] = [];
        for (var id of ids) {
          if (!this.extraDataOf(id).ownerAddress) {
            var tokenId = this.getDataPropertyValue(id, 'tokenId') || id;
            if (tokenId != id) {
              this.tokenIdsToIds[tokenId] = id;
              missingIds.push(tokenId);
            } else if (this.id == 'meowbits-collection') {
              missingIds.push(id);
            }
          }
        }
        tokenIds = missingIds;
      }

      if (tokenIds.length) {
        var remainingIds = tokenIds.slice();
        while (remainingIds.length) {
          var limit = 20;
          var nextIds = remainingIds.splice(0, limit);
          var apiURL = opensea.getOpenSeaAPIUrlFor(contractAddress, nextIds);

          let newAssets = [];
          if (apiURL) {
            try {
              if (useDataRT) {
                const dataUrl = opensea.getProxiedDataUrlFromOpenSeaAPIUrl(apiURL);
                newAssets = (await vhttp.get(dataUrl))?.data ?? [];
              } else {
                newAssets = (await opensea.makeOpenSeaAPIRequest(apiURL)).data?.assets || [];
              }
            } catch (error) {
              iso.error(error);
            }

            for (var asset of newAssets) {
              var id = isExtraContract ? (asset.token_id as string) : this.constructId(asset.token_id, contractAddress);
              this._setOpenSeaDataFor(id, asset, isExtraContract ? contractAddress : undefined);
            }
            results = results.concat(newAssets);
          }
        }

        for (var id of tokenIds) {
          if (!this.extraDataOf(id).ownerAddress) {
            iso.set(this.extraDataOf(id), 'noOpenSea', true);
          }
        }
      }
    }

    return results;
  }

  _getOpenSeaAPIUrlForRecentlyListed(contract: string, last?: Date) {
    if (!last) last = new Date();
    var params = `asset_contract_address=${contract}&limit=${
      this.config.images.pageSize
    }&event_type=created&occurred_before=${last.valueOf() / 1000}`;
    return `${DATA_ORIGIN}/api/proxy/api/v1/events?${params}`;
  }

  _getOpenSeaAPIUrlFor(contract: string, ids: string[]) {
    var params = `asset_contract_address=${contract}&limit=50`;
    var foundCount = 0;
    for (var nftId of ids) {
      if (!this.extraDataOf(nftId).ownerAddress) {
        params += '&token_ids=' + nftId;
        foundCount++;
      }
    }
    if (foundCount) return 'https://api.opensea.io/api/v1/assets?' + params;
    return undefined;
  }

  _setOpenSeaDataForEvent(id: string, event: any) {
    var asset = event.asset;
    var extraData = this.extraDataOf(id);
    if (extraData) {
      if (!this.hasPrices) {
        if (extraData.priceData?.begin) {
          return false;
        }
      }
      iso.set(extraData, 'ownerAddress', asset.owner?.address);
      iso.set(extraData, 'ownerUser', asset.owner?.user?.username);
      iso.set(extraData, 'name', asset.name);

      if (!this.hasPrices) iso.set(extraData, 'priceData', priceData.interpretPriceFromOpenSeaEvent(event));

      iso.set(extraData, 'openSeaPreview', asset.image_preview_url);
      iso.set(extraData, 'openSeaFull', asset.image_url);
    }
    return true;
  }

  _setOpenSeaDataFor(id: string, asset: any, useContract?: string) {
    var id = this.tokenIdsToIds[asset.token_id] || id;

    var extraData = this.extraDataOf(id);
    if (extraData) {
      if (!this.hasPrices) iso.set(extraData, 'priceData', priceData.interpretPriceFromOpenSeaAsset(asset));
      else iso.set(extraData, 'priceData2', priceData.interpretPriceFromOpenSeaAsset(asset));

      iso.set(extraData, 'ownerAddress', asset.owner?.address);
      iso.set(extraData, 'ownerUser', asset.owner?.user?.username);
      iso.set(extraData, 'name', asset.name);
      iso.set(extraData, 'openSeaPreview', asset.image_preview_url);
      iso.set(extraData, 'openSeaFull', asset.image_url);

      if (asset.animation_url) iso.set(extraData, 'animationUrl', asset.animation_url);

      if (this.id == 'bonsai-zenft') {
        if (asset.description) {
          var desc: string = asset.description;
          var beginPos = desc.indexOf('(.glb)](');
          var endPos = desc.indexOf(')', beginPos + 8);
          if (beginPos != -1 && endPos != -1) {
            iso.set(extraData, 'model', desc.substring(beginPos + 8, endPos));
          }
          var uBeginPos = desc.indexOf('(.usdz)](');
          var uEndPos = desc.indexOf(')', uBeginPos + 9);
          if (uBeginPos != -1 && uEndPos != -1) {
            iso.set(extraData, 'modelUsdz', desc.substring(uBeginPos + 9, uEndPos));
          }
        }
      }

      if (useContract) {
        iso.set(extraData, 'useContract', useContract);
        iso.set(extraData, 'useTokenId', asset.token_id);
      }
    }
  }

  ////////////////////////////////
  // Wallet

  async assignWalletIds(walletIds: number[], useDataRT = false) {
    var wallet = this.modeLists['wallet'];
    wallet.resetIds();
    var ids = walletIds.map((id) => id.toString());
    ids = ids.filter((id) => this.itemLookup[id]); // filter out ones we don't know about
    ids.sort((a, b) => {
      return this.rankOf(a) - this.rankOf(b);
    });
    iso.set(wallet, 'ids', ids);
    for (var id of ids) {
      if (!this.extraDataOf(id).filteredOut) {
        wallet.filteredIds.push(id);
      }
    }
    wallet.updateStats(this);

    await this.updateMarketData(wallet.ids, useDataRT);
    wallet.updateStats(this);
  }

  ////////////////////////////////
  // NFT data lookup

  extraDataOf(id: string) {
    var extraData = this.extraDataLookup[id];
    if (!extraData) {
      extraData = {};
      iso.set(this.extraDataLookup, id, extraData);
    }
    return extraData;
  }

  isInDungeon(id: string) {
    return this.extraDataLookup[id]?.ownerAddress == '0xb291984262259bcfe6aa02b66a06e9769c5c1ef3';
  }

  isBurned(id: string) {
    return this.extraDataLookup[id]?.ownerAddress == '0x0000000000000000000000000000000000080085';
  }

  get id() {
    return this.config.id;
  }

  get name() {
    return this.json.name;
  }

  get shortName() {
    if (this.json.shortName) return this.json.shortName;
    return this.name;
  }

  get itemName() {
    if (this.json.itemName) return this.json.itemName;
    return this.shortName;
  }

  get totalItemCount() {
    return this.data.items.length;
  }

  displayIdFor(id: string) {
    var displayIdProp = this.basePropDefLookup['displayId'];
    if (displayIdProp) {
      var displayId = this.getPropertyEncodedValue(displayIdProp, this.itemLookup[id]);
      if (displayId === '0' || displayId) {
        return displayId;
      }
    }
    return id;
  }

  displayNameFor(id: string) {
    // custom code
    if (this.id == 'mooncats') {
      var extraData = this.extraDataOf(id);
      if (extraData.useContract) return id + ': Wrapped ' + extraData.useTokenId;
      else if (extraData.ownerAddress) return id + ': Acclimated';
      else return id;
    }

    var extraData = this.extraDataOf(id);
    if (extraData.name && !this.config.options?.useOwnName) return extraData.name;

    var displayIdProp = this.basePropDefLookup['displayId'];
    if (displayIdProp) {
      var displayId = this.getPropertyEncodedValue(displayIdProp, this.itemLookup[id]);
      if (displayId === '0' || displayId) {
        id = displayId;
      }
    }

    if (id.length > 20) return this.itemName;

    if (typeof (id as any) != 'string') id = id.toString();

    return this.itemName + ' #' + id.substring(0, 20);
  }

  ownerUrlFor(id: string) {
    if (this.id == 'axie') {
      var extraData = this.extraDataLookup[id];
      if (extraData) {
        var ownerAddress = extraData.ownerAddress;
        if (ownerAddress) return `https://marketplace.axieinfinity.com/profile/ronin:${ownerAddress.substr(2)}/axie`;
      }
      return '#';
    } else {
      var extraData = this.extraDataLookup[id];
      if (extraData) {
        if (extraData.ownerUser) return `https://opensea.io/accounts/${extraData.ownerUser}${OS_REFERRER_QUERY}`;
        if (extraData.ownerAddress) return `https://opensea.io/accounts/${extraData.ownerAddress}${OS_REFERRER_QUERY}`;
      }
      return '#';
    }
  }

  /**
   * Return the marketplace url for the given token. Can return undefined.
   */
  urlFor(id: string, targetId?: string) {
    // Determine the contract + token to link to. The override from `extraData` was kept as-is from
    // previous code (TODO: What does this do?)
    const baseInfo = this.getContractAndTokenId(id);
    const extraDataOverride = this.extraDataOf(id);
    let tokenIdentifier: TokenIdentifier = {
      chainId: this.chain,
      contractId: extraDataOverride.useContract || baseInfo.contract,
      tokenId: extraDataOverride.useTokenId || baseInfo.tokenId,
    };

    return generateTokenLink(targetId || 'opensea', tokenIdentifier, null, null);
  }

  getImgIndex(id: any) {
    var imgIndex: any = id;
    if (this.config.images.indexOffset) {
      //imgIndex = (id + this.config.imgIndexOffset) % this.config.imgInmdex
      var result = id - this.config.images.indexOffset;
      if (result < 0) result = this.config.images.indexMax! + result;
      imgIndex = result;
    }
    if (this.config.images.indexPadZeroes) {
      imgIndex = imgIndex.padStart(4, '0');
    }
    return imgIndex;
  }

  private getCdnMediaUrl(itemId: string, dimensions: { width: number; height: number }) {
    const maybeMediaSrc = this.mediaFile?.urls?.[itemId];

    if (maybeMediaSrc && isVideoSrc(maybeMediaSrc)) {
      return `${process.env.MEDIA_CDN_ORIGIN}/${this.id}/${maybeMediaSrc.poster}`;
    }

    // If the media mapping has no entry, then its `token id + shared extension`. This shared extension
    // is in the media file these days, but used to be in the config previously.
    const filename = maybeMediaSrc ?? `${itemId}.${this.mediaFile?.extension ?? this.config.images.ext}`;

    // @ts-ignore URLSearchParams is missing
    const params = new URLSearchParams({
      width: dimensions.width.toString(),
      height: dimensions.height.toString(),
    });

    return `${process.env.MEDIA_CDN_ORIGIN}/${collectionIdForProjectId(this.id)}/${filename}?${params}`;
  }

  imgUrlFor(itemId: string) {
    if (this.config.images.useMediaCdn) {
      return this.getCdnMediaUrl(itemId, {
        width: this.config.images.sizeThumb[0],
        height: this.config.images.sizeThumb[1],
      });
    }

    if (this.config.images.useImgFile) {
      if (!this.imgFile) {
        // load the img file
        // set it to empty first to prevent multiple calls
        iso.set(this, 'imgFile', {});
        var server = STATIC_HOST_AND_BASE;
        (async () => {
          var imgFileUrl = `${server}/img/img_${this.id}.json`;
          if (this.json.dynamic) {
            imgFileUrl = `${server}/imgServer/img_${this.id}.json`;
          } else {
            // not dynamic
          }
          iso.set(this, 'imgFile', (await vhttp.get(imgFileUrl)).data);
        })();
      } else if (this.imgFile.contractImgs) {
        var tokenId = this.getContractAndTokenId(itemId);
        return getImgFromImgFile(this.imgFile, tokenId.contract, tokenId.tokenId) || '/white.png';
      }
      return '/white.png';
    }

    if (this.config.options?.canHaveImgOverride) {
      var def = this.getPropDef('imgOverride');
      if (def) {
        var val = this.getPropertyEncodedValue(def, this.itemLookup[itemId]);
        if (val) return val;
      }
    }

    // Deprecated, we won't be able to make unauth calls to the OpenSea API soon!
    if (this.config.images.useOpenSeaImg || this.config.images.useOpenSeaImgForPreview) {
      if (this.extraDataOf(itemId).openSeaPreview) {
        return this.extraDataOf(itemId).openSeaPreview;
      } else if (this.config.images.useBackup) {
        return `${this.config.images.baseUrl}${this.getImgIndex(itemId)}${this.config.images.ext}`;
      }
      return '/white.png';
    }

    return `${this.config.images.baseUrl ?? ''}${this.getImgIndex(itemId)}${this.config.images.ext ?? ''}`;
  }

  videoUrlFor(itemId: any) {
    const maybeMediaSrc = this.mediaFile?.urls?.[itemId];
    if (maybeMediaSrc && isVideoSrc(maybeMediaSrc)) {
      return `${process.env.MEDIA_CDN_ORIGIN}/${this.id}/${maybeMediaSrc.animation}`;
    }
    return '';
  }

  fullImgUrlFor(itemId: any) {
    if (this.config.images.useMediaCdn && process.env.MEDIA_CDN_ORIGIN) {
      return this.getCdnMediaUrl(itemId, {
        width: this.config.images.sizeFull[0],
        height: this.config.images.sizeFull[1],
      });
    }

    if (this.config.images.useImgFile) {
      return this.imgUrlFor(itemId);
    }
    if (this.config.options?.canHaveImgOverride) {
      var def = this.getPropDef('imgOverride');
      if (def) {
        var val = this.getPropertyEncodedValue(def, this.itemLookup[itemId]);
        if (val) return val;
      }
    }
    if (this.config.images.useOpenSeaImg) {
      if (this.extraDataOf(itemId).openSeaFull) {
        return this.extraDataOf(itemId).openSeaFull;
      } else if (this.config.images.useBackup) {
        return `${this.config.images.baseUrl}${this.getImgIndex(itemId)}${this.config.images.ext}`;
      }
      return '/white.png';
    }
    return `${this.config.images.previewBaseUrl || this.config.images.baseUrl}${this.getImgIndex(itemId)}${
      this.config.images.previewExt || this.config.images.ext
    }`;
  }

  rankOf(id: string) {
    var item = this.itemLookup[id];
    if (!item) return '';
    return item[item.length - 1];
  }

  rarityScoreFor(id: string) {
    var item = this.itemLookup[id];
    return item[item.length - 2];
  }

  ownerDisplayFor(id: string) {
    if (this.isInDungeon(id)) return 'Dungeon';
    if (this.isBurned(id)) return 'Burned';
    var extraData = this.extraDataLookup[id];
    if (extraData) {
      if (extraData.ownerUser) return extraData.ownerUser;
      if (extraData.ownerAddress) return extraData.ownerAddress.substr(0, 6);
    }
    return undefined;
  }

  roundToTwo(num: any) {
    return +(Math.round((num + 'e+2') as any) + 'e-2');
  }

  roundToThree(num: any) {
    return +(Math.round((num + 'e+3') as any) + 'e-3');
  }

  roundToFour(num: any) {
    return +(Math.round((num + 'e+4') as any) + 'e-4');
  }

  priceChangedFor(id: string) {
    var extraData = this.extraDataOf(id);
    if (extraData.priceData && extraData.priceData === null) {
      return true;
    }
    if (extraData.priceData && extraData.priceData2) {
      if (Math.abs(extraData.priceData.price_eth - extraData.priceData2.price_eth) > 0.25) {
        return true;
      }
    }
    return false;
  }

  displayPriceFor(id: string) {
    var extraData = this.extraDataOf(id);
    if (extraData.priceData?.price) {
      var symbol = extraData.priceData?.symbol || 'ETH';
      if (symbol == 'WETH') symbol = 'ETH';
      return (
        this.roundToFour(extraData.priceData?.price) + ' ' + symbol + (extraData.priceData.type == 2 ? ' Auction' : '')
      );
    }
    return '';
  }

  newPriceFor(id: string) {
    var extraData = this.extraDataOf(id);
    if (extraData.priceData2?.price) {
      var symbol = extraData.priceData2?.symbol || 'ETH';
      if (symbol == 'WETH') symbol = 'ETH';
      return (
        this.roundToFour(extraData.priceData2?.price) +
        ' ' +
        symbol +
        (extraData.priceData2.type == 2 ? ' Auction' : '')
      );
    }
    return '';
  }

  humanize(text: string) {
    if (this.config.options?.dontHumanizeTraitTypes) return text;

    if (!text) return '';
    return humanizeString(text).replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  }

  ////////////////////////////////
  // Property Lookup

  getPropNameFromAnyCase(propName: string) {
    for (var prop of this.data.basePropDefs) {
      if (prop.name.toLowerCase() == propName.toLowerCase()) {
        return prop.name;
      }
    }
    if (this.data.derivedPropDefs) {
      for (var prop2 of this.data.derivedPropDefs) {
        if (prop2.name.toLowerCase() == propName.toLowerCase()) {
          return prop2.name;
        }
      }
    }
    return propName;
  }

  getPropDef(propName: string) {
    var def: PropDef = this.basePropDefLookup[propName];
    if (def) return def;
    def = this.derivedPropDefLookup[propName];
    if (def) return def;
    return undefined;
  }

  getPropValueSetAndIndex(propName: string, encodedValue: any) {
    var baseDef = this.basePropDefLookup[propName];
    if (baseDef) {
      return {
        valueSet: baseDef.pvs![encodedValue],
        index: encodedValue,
      };
    }
    var derivedDef = this.derivedPropDefLookup[propName];
    if (derivedDef) {
      // search
      for (var index = 0; index < derivedDef.pvs!.length; index++) {
        var valueSet = derivedDef.pvs![index];
        if (encodedValue == valueSet[0])
          return {
            valueSet,
            index,
          };
      }
    }
    return undefined;
  }

  getPropActualValueFromValueIndex(propName: string, valueIndex: number): string | number | null | undefined {
    var derivedDef: DerivedPropDef = this.derivedPropDefLookup[propName];
    if (derivedDef) {
      // for derived props, encoded value is combined for eg. "1.2", we need to lookup
      return this.getPropActualValue(propName, derivedDef.pvs![valueIndex][0]);
    }
    // for basic props, encoded value is the valueIndex
    return this.getPropActualValue(propName, valueIndex);
  }

  getPropActualValue(propName: string, encodedValue: any): string | number | null | undefined {
    var derivedDef: DerivedPropDef = this.derivedPropDefLookup[propName];
    if (derivedDef) {
      if (derivedDef.type == 'combinedProperty') {
        if (encodedValue === null) return null;
        var vals = encodedValue.split('.');
        var actualVals: any[] = [];
        for (var i = 0; i < vals.length; i++) {
          var actualValue = this.getPropActualValue(derivedDef.properties![i], vals[i]);
          if (actualValue === null) actualVals.push('none');
          else actualVals.push(actualValue);
        }
        return actualVals.join('-'); //+ " = " + encodedValue
      } else if (derivedDef.type == 'propertyMatch') {
        if (encodedValue === null) return null;

        var actualValue = this.getPropActualValue(derivedDef.properties![0], encodedValue);
        if (actualValue) {
          if (derivedDef.matchValueMapping && derivedDef.matchValueMapping[actualValue]) {
            actualValue = derivedDef.matchValueMapping[actualValue];
          }
        }
        return actualValue;
      } else if (derivedDef.type == 'propertyMatchCount') {
        return encodedValue;
      } else if (derivedDef.type == 'propertySetMatch') {
        return encodedValue;
      }
    } else {
      var def = this.getPropDef(propName);
      if (def && def.pvs) return def.pvs[encodedValue][0];
    }
    return undefined;
  }

  getPropActualValueFromFilterPair(filterPair: any[]) {
    if (filterPair[0] == 'minPrice' || filterPair[0] == 'maxPrice') return filterPair[1] + ' ETH';
    if (filterPair[0] == 'minRank' || filterPair[0] == 'maxRank') return '#' + filterPair[1];
    if (filterPair[0] == 'ownerAddress') return filterPair[1];
    if (filterPair[0] == 'buyNow') return 'On';
    if (filterPair[0] == 'auction') return 'On';

    return this.getPropActualValueFromValueIndex(filterPair[0], filterPair[1]);
  }

  /////////////////////////////////////////////////////
  // ranking

  applyRankings() {
    let method: CalculationMethod;
    const options = this.calcOptions;
    if (options.method == 'score') {
      method = new RarityScoreCalculationMethod(this, options);
    } else if (options.method == 'statistical') {
      method = new StatisticalRarityCalculationMethod(this, options);
    } else if (options.method == 'average') {
      method = new AverageTraitRarityCalculationMethod(this, options);
    } else if (options.method == 'trait') {
      method = new TraitRarityCalculationMethod(this, options);
    } else if (options.method == 'preRanked') {
      method = new PreRankedCalculationMethod(this, options);
    } else {
      return;
    }

    method.rank();
    var newIds = [];
    for (var item of this.data.items) {
      newIds.push(item[0]);
    }
    iso.set(this.modeLists['all'], 'ids', newIds);
  }

  getMatchId(combination: number[]) {
    return combination.join('-');
  }

  getMatchValue(item: any[], combination: number[]) {
    return combination
      .map((propId) => {
        return item[propId];
      })
      .join('|');
  }

  getPropWeight(propDef: PropDef) {
    return CalculationMethod.getPropWeight(propDef, this.calcOptions, this.matches);
  }

  //////////////////////////////

  getPriceFloor(propName: string, encodedValue: any) {
    if (this.propValPriceFloors[propName]) return this.propValPriceFloors[propName][encodedValue];
    return undefined;
  }

  getPriceEth(id: string, now: number) {
    var extraData = this.extraDataOf(id);
    if (extraData.priceData) {
      if (extraData.priceData.price_eth) {
        if (now > extraData.priceData.begin) {
          if (extraData.priceData.end) {
            if (now > extraData.priceData.end) return undefined;
          }
          return extraData.priceData.price_eth;
        }
      }
    }
    return undefined;
  }

  getPriceEthIncAuctions(id: string, now: number) {
    var extraData = this.extraDataOf(id);
    if (extraData?.priceData) {
      var ethPrice = extraData.priceData.price_eth;
      if (!ethPrice) {
        if (extraData.priceData.symbol == 'WETH') {
          if (extraData.priceData.type == 2) {
            ethPrice = extraData.priceData.price;
          }
        }
      }
      if (ethPrice) {
        if (now > extraData.priceData.begin) {
          if (extraData.priceData.end) {
            if (now > extraData.priceData.end) return undefined;
          }
          return ethPrice;
        }
      }
    }
    return undefined;
  }

  ////////////////////////////////

  get defaultExploreTrait() {
    if (this.config.options?.defaultExploreTrait) return this.config.options.defaultExploreTrait;
    return this.config.propCategories[0][1][0];
  }

  _getExploredTraitValues1(propName: string): ExploredTraitValue[] {
    var results: ExploredTraitValue[] = [];
    var propDef = this.getPropDef(propName);
    var isDerived = this.derivedPropDefLookup[propName];
    if (propDef?.pvs?.length) {
      var type = (propDef as any).type;
      if (type != 'data' && type != 'primaryKey') {
        for (var valueIndex = 0; valueIndex < propDef.pvs.length; valueIndex++) {
          var pv = propDef.pvs[valueIndex];
          var encodedValue: any = isDerived ? pv[0] : valueIndex;
          var dataRow = {
            propName,
            encodedValue,
            valueIndex,
            actualValue: this.getPropActualValueFromValueIndex(propName, valueIndex),
            count: pv[1],
            score: pv[2],
            floorPrice: this.getPriceFloor(propName, encodedValue) || 0,
            exampleTokenIds: [],
          };
          if (dataRow.actualValue === null) dataRow.actualValue = '<none>';
          results.push(dataRow);
        }
      }
    }
    return results;
  }

  static MAX_EXPLORE_TRAIT_VALUES_SHOW = 50;

  getExploredTraitValues(propName: string, sort: string, useDataRT = false): ExploredTraitValue[] {
    const EXAMPLE_COUNT = 3;
    var results: ExploredTraitValue[] = [];
    if (propName == 'Top Traits') {
      for (var prop in this.basePropDefLookup) {
        results = results.concat(this._getExploredTraitValues1(prop));
      }
      for (var prop in this.derivedPropDefLookup) {
        if (!this.matches) {
          var propDef = this.getPropDef(prop) as DerivedPropDef;
          if (propDef.isMatch) continue;
        }
        results = results.concat(this._getExploredTraitValues1(prop));
      }
    } else {
      results = this._getExploredTraitValues1(propName);
    }

    // sort
    if (sort == 'count') {
      results.sort((a, b) => {
        return a.count - b.count;
      });
    }
    if (sort == 'score') {
      results.sort((a, b) => {
        return b.score - a.score;
      });
    } else if (sort == 'name') {
      results.sort((a, b) => {
        return a.propName.localeCompare(b.propName);
      });
    } else if (sort == 'floor') {
      results.sort((a, b) => {
        if (!a.floorPrice && b.floorPrice) return 1;
        if (!b.floorPrice && a.floorPrice) return -1;
        if (!b.floorPrice && !a.floorPrice) return 0;
        return b.floorPrice - a.floorPrice;
      });
    }

    // if Top Traits we take only Top 25
    if (propName == 'Top Traits') {
      results = results.slice(0, 25);
    }

    // fill out cheapestToken and examples for each
    var ids = this.modeLists['all'].ids.slice();
    if (this.hasPrices) this._sortIdsWithSort(ids, 'priceLowHigh');

    var toShow = results.length <= Project.MAX_EXPLORE_TRAIT_VALUES_SHOW;

    var remainingResults = results.slice();

    for (var id of ids) {
      var resultsFinished: ExploredTraitValue[] = [];
      for (var result of remainingResults) {
        var needsCheapestToken = !result.cheapestTokenId && this.hasPrices;
        var needsExamples =
          toShow && !(result.exampleTokenIds.length == EXAMPLE_COUNT || result.exampleTokenIds.length == result.count);

        var propDef2 = this.getPropDef(result.propName);
        var matching = false;
        var encodedValue = this.getPropertyEncodedValue(this.getPropDef(result.propName)!, this.itemLookup[id]);
        if ((propDef2 as BasePropDef).type == 'tags') {
          if (encodedValue.indexOf(result.encodedValue) != -1) matching = true;
        } else {
          if (encodedValue == result.encodedValue) matching = true;
        }
        if (matching) {
          if (needsCheapestToken) {
            result.cheapestTokenId = id;
          } else if (needsExamples) {
            result.exampleTokenIds.push(id);
          }
        }

        needsCheapestToken = !result.cheapestTokenId && this.hasPrices;
        needsExamples =
          toShow && !(result.exampleTokenIds.length == EXAMPLE_COUNT || result.exampleTokenIds.length == result.count);

        if (!needsCheapestToken && !needsExamples) {
          resultsFinished.push(result);
        }
      }
      for (var result of resultsFinished) {
        remainingResults.splice(remainingResults.indexOf(result), 1);
      }
      if (remainingResults.length == 0) break;
    }

    // gather all ids we need
    if (toShow) {
      var displayIds: string[] = [];
      for (var result of results) {
        if (result.cheapestTokenId) {
          if (displayIds.indexOf(result.cheapestTokenId) == -1) displayIds.push(result.cheapestTokenId);
        }
        for (var exampleId of result.exampleTokenIds)
          if (displayIds.indexOf(exampleId) == -1) displayIds.push(exampleId);
      }
      this.updateMarketData(displayIds, useDataRT);
    }

    return results;
  }
}

export default Project;
