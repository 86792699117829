










import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';
import moment from 'moment';

// import Web3 from 'web3'

@Component({})
export default class AdBlockVert extends AppVue {
  get adToShow(): { desktop: string; mobile: string; mobileWidth?: number; target: string } | undefined {
    var prefix = 'https://current.images.rarity.tools/';
    var possibleItems: any = [];
    if (this.$app.current) {
      var current = this.$app.current;
      if (current.vert1) {
        possibleItems.push({
          desktop: `${prefix}${current.vert1}_vert_d`,
          mobile: `${prefix}${current.vert1}_vert_m`,
          target: current.vert1_target,
        });
      }
      if (current.vert2) {
        possibleItems.push({
          desktop: `${prefix}${current.vert2}_vert_d`,
          mobile: `${prefix}${current.vert2}_vert_m`,
          target: current.vert2_target,
        });
      }
    }
    if (possibleItems.length) return possibleItems[Math.floor(Math.random() * possibleItems.length)];
    return undefined;
  }
}
