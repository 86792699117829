



















import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';

// import Web3 from 'web3'

@Component({})
export default class PropFilterProp extends AppVue {
  @Prop()
  propName!: string;

  get isSelected() {
    return this.exploringTrait == this.propName;
  }

  get toTarget() {
    if (this.project) {
      if (this.propName == this.project.defaultExploreTrait) return `/${this.projectId}/traits`;
    }
    return {
      path: `/${this.projectId}/traits`,
      query: { trait: this.propName },
    };
    //return
  }
}
