import { render, staticRenderFns } from "./ExploreTraits.vue?vue&type=template&id=ceb7c6a0&"
import script from "./ExploreTraits.vue?vue&type=script&lang=ts&"
export * from "./ExploreTraits.vue?vue&type=script&lang=ts&"
import style0 from "./ExploreTraits.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NFTImage: require('/opt/build/repo/components/NFTImage.vue').default,ExploreTraitsSidebar: require('/opt/build/repo/components/ExploreTraitsSidebar.vue').default})
