






import AppVue from '@/components/AppVue';
import { Component, Watch } from 'nuxt-property-decorator';

// import Web3 from 'web3'

@Component({})
export default class DefaultLayout extends AppVue {}
