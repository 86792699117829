import { CalculationMethod } from './CalculationMethod';
import * as iso from '../iso';

export class PreRankedCalculationMethod extends CalculationMethod {
  public rank() {
    this.setScoresFromDataItem();
    this.sortByScoresAscending();
    this.assignRanks();
  }

  setScoresFromDataItem() {
    // find index of data prop
    var propIndex = -1;
    for (var ind = 1; ind < this.data.basePropDefs.length; ind++) {
      if (this.data.basePropDefs[ind].name == this.options.dataItem) {
        propIndex = ind;
        break;
      }
    }
    if (propIndex == -1) {
      console.error('could not find prop: ' + this.options.dataItem);
      return;
    }

    // caculate scores for each item
    for (var arrItem of this.data.items) {
      iso.set(arrItem, this.data.basePropDefs.length + 1, arrItem[propIndex]);
    }
  }
}
