








































import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';

// import Web3 from 'web3'

@Component({
  layout: 'home',
})
export default class ProjectList extends AppVue {
  @Prop()
  stat!: string;

  @Prop()
  title!: string;

  @Prop({ default: -1 })
  max!: number;

  @Prop({ default: () => () => {} })
  onProjectClick!: () => void;

  async mounted() {
    //await this.$app.checkLoadCollections()
  }

  formatValue(value: number) {
    if (this.stat.indexOf('volume') != -1 || this.stat.indexOf('price') != -1) {
      return this.formatETH2(value);
    }
    if (this.stat.indexOf('owner') != -1) return value + ' Owners';
    return value;
  }

  collectionsFor(stat: string) {
    if (this.max > 0) return this.$app.getCollectionsBy(stat).slice(0, this.max);
    return this.$app.getCollectionsBy(stat);
  }
}
