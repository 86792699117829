



































































import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';

// import Web3 from 'web3'

@Component({})
export default class PropFilterValue extends AppVue {
  @Prop()
  valueSet!: [string | any | null, number, number];

  @Prop()
  showDrillDownCounts?: boolean;

  @Prop()
  valueIndex!: number;

  @Prop()
  propName!: string;

  @Prop()
  level!: string;

  get count() {
    if (this.showDrillDownCounts && this.project?.hasFiltersActive) {
      if (this.project!.propValDrillDownCounts[this.propName]) {
        var encodedValue: any = this.valueIndex;
        var derivedProp = this.project.derivedPropDefLookup[this.propName];
        if (derivedProp) {
          encodedValue = derivedProp.pvs![this.valueIndex][0];
        }
        return this.project!.propValDrillDownCounts[this.propName][encodedValue] || 0;
      }
      return 0;
    } else {
      return this.valueSet[1];
    }
  }

  get actualValue() {
    if (this.valueSet[0] === null) return '<none>';
    if (this.valueSet[0] == '@@_rt_no_tags') return '<none>';
    return this.project!.getPropActualValueFromValueIndex(this.propName, this.valueIndex);
  }

  toggleFilter() {
    if (this.project) {
      if (!this.project.filtersActive[this.propName]) {
        this.$set(this.project.filtersActive, this.propName, {});
      }

      if (!this.project.filtersActive[this.propName][this.valueIndex]) {
        this.$set(this.project.filtersActive[this.propName], this.valueIndex, true);
      } else {
        this.$delete(this.project.filtersActive[this.propName], this.valueIndex);
      }
      this.checkApplyUrlFromFilters();
      this.$bus.$emit('eventNFTsFiltered');
    }
  }

  get filtered() {
    if (this.project) {
      if (this.project.filtersActive[this.propName]) return this.project.filtersActive[this.propName][this.valueIndex];
    }
    return false;
  }
}
