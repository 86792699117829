import { CalculationMethod } from './CalculationMethod';

export class StatisticalRarityCalculationMethod extends CalculationMethod {
  public rank() {
    //console.log('StatisticalRarityCalcMethod rank')
    this.prepareWeightsAndPropValueScores();
    this.calculateItemScores();
    this.sortByScoresAscending();
    this.assignRanks();
  }

  protected get uniquenessWeightMultiplier(): number {
    return 1;
  }

  protected calculatePropValueScore(valueCount: number, pvCount: number, weight: number): number {
    var score = valueCount / this.itemCount;
    // we don't need normalization (?)
    if (this.options.normalize) {
      score = Math.pow(score, 1 / pvCount);
    }
    score = Math.pow(score, weight);
    return score;
  }

  protected initialScore(): number {
    return 1;
  }
  protected accumulateScore(accumulatedScore: number, newScore: number): number {
    return accumulatedScore * newScore;
  }
  protected finalizeScore(accumulatedScore: number): number {
    return accumulatedScore;
  }

  protected mergeUniquenessScore(normalScore: number, uniquenesScore: number): number {
    return normalScore * this.calculatePropValueScore(uniquenesScore, 1, this.uniquenessWeight);
  }
}
