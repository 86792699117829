import { CollectionIdentifier, LinkTarget } from './types';

export const OS_REFERRER_QUERY = '?ref=0x5c5321ae45550685308a405827575e3d6b4a84aa';

export const OpenSea: LinkTarget<{}, { slug: string }> = {
  id: 'opensea',
  name: 'OpenSea',

  makeTokenLink: (identifier) => {
    if (identifier.chainId === 'matic') {
      return `https://opensea.io/assets/matic/${identifier.contractId}/${identifier.tokenId}${OS_REFERRER_QUERY}`;
    } else if (identifier.chainId === 'solana') {
      return `https://opensea.io/assets/solana/${identifier.tokenId}${OS_REFERRER_QUERY}`;
    } else if (identifier.chainId === 'ethereum') {
      return `https://opensea.io/assets/ethereum/${identifier.contractId}/${identifier.tokenId}${OS_REFERRER_QUERY}`;
    } else {
      return `https://opensea.io/assets/${identifier.contractId}/${identifier.tokenId}${OS_REFERRER_QUERY}`;
    }
  },

  makeCollectionLink: (_, customData) => {
    return `https://opensea.io/collection/${customData.slug}${OS_REFERRER_QUERY}`;
  },
};

function supportedByLooksRare(identifier: CollectionIdentifier) {
  if (identifier.chainId != 'ethereum') {
    return false;
  }
  // LooksRare does not have Axie listed. This is surprising, as they otherwise index the whole chain.
  if (identifier.contractId == '0xf5b0a3efb8e8e4c201e2a935f110eaaf3ffecb8d') {
    return false;
  }
  return true;
}

export const LooksRare: LinkTarget<{}, { slug: string }> = {
  id: 'looksrare',
  name: 'LooksRare',

  makeTokenLink: (identifier) => {
    if (!supportedByLooksRare(identifier)) {
      return '';
    }
    return `https://looksrare.org/collections/${identifier.contractId}/${identifier.tokenId}`;
  },

  makeCollectionLink: (identifier, _) => {
    if (!supportedByLooksRare(identifier)) {
      return '';
    }
    return `https://looksrare.org/collections/${identifier.contractId}`;
  },
};

export const MagicEden: LinkTarget<{}, { slug: string }> = {
  id: 'magiceden',
  name: 'MagicEden',

  makeTokenLink: (identifier) => {
    if (identifier.chainId !== 'solana') {
      return '';
    }
    return `https://magiceden.io/item-details/${identifier.tokenId}`;
  },
};

export const AxieInfinityMarketplace: LinkTarget<{}, { slug: string }> = {
  id: 'axie',
  name: 'Axie Infinity',

  makeTokenLink: (identifier) => {
    if (identifier.chainId != 'ethereum' || identifier.contractId != '0xf5b0a3efb8e8e4c201e2a935f110eaaf3ffecb8d') {
      return '';
    }
    return `https://marketplace.axieinfinity.com/axie/${identifier.tokenId}`;
  },
};
