import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=8b70a548&"
import script from "./TopBar.vue?vue&type=script&lang=ts&"
export * from "./TopBar.vue?vue&type=script&lang=ts&"
import style0 from "./TopBar.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AllCollectionsMenuList: require('/opt/build/repo/components/AllCollectionsMenu/List.vue').default,ProjectTopLink: require('/opt/build/repo/components/ProjectTopLink.vue').default})
