






import Vue from 'vue';
import { Component, Prop } from 'nuxt-property-decorator';

@Component({})
export default class SelectList extends Vue {
  // selected item
  @Prop()
  value?: string | null;

  onClick(evt: MouseEvent) {
    if (!evt.target) return;
    // we are doing a bit of manual html javascript
    // because we don't want to have a "SelectListItem" tag
    // so we can just use li tags

    // first see if we are clicking on which child
    var targetChildEl: Element | undefined;
    for (var i = 0; i < this.$el.children.length; i++) {
      var childEl = this.$el.children[i];
      if (childEl.contains(evt.target as Node)) {
        targetChildEl = childEl;
      }
    }
    if (targetChildEl) {
      this.$emit('input', targetChildEl.getAttribute('data-value'));
    }
  }

  mounted() {
    this._updateClasses();
  }

  updated() {
    this.$nextTick(() => {
      this._updateClasses();
    });
  }

  _updateClasses() {
    // find child with new value
    var targetChildEl: Element | undefined;
    for (var i = 0; i < this.$el.children.length; i++) {
      var childEl = this.$el.children[i];
      if (childEl.getAttribute('data-value') == this.value) {
        targetChildEl = childEl;
      }
    }

    // remove selected from all children
    for (var i = 0; i < this.$el.children.length; i++) {
      var childEl = this.$el.children[i];
      if (childEl != targetChildEl) {
        childEl.classList.remove('selected');
      }
    }

    if (targetChildEl)
      if (!targetChildEl.classList.contains('selected'))
        // add selected to targetChildEl
        targetChildEl.classList.add('selected');
  }
}
