import { render, staticRenderFns } from "./ExploreTraitsCategory.vue?vue&type=template&id=523be88e&"
import script from "./ExploreTraitsCategory.vue?vue&type=script&lang=ts&"
export * from "./ExploreTraitsCategory.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ExploreTraitsProp: require('/opt/build/repo/components/ExploreTraitsProp.vue').default})
