











import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';
import moment from 'moment';
import AdBlockVert from './AdBlockVert.vue';

// import Web3 from 'web3'

@Component({})
export default class AdBlockVertMobile extends AdBlockVert {
  @Prop({ default: 'mobile' })
  showAt!: 'md' | 'mobile';

  get hide() {
    //if (this.adToShow?.mobile.indexOf('basedfishmafia') != -1)
    //return true
    return false;
  }
}
