import Vue from 'vue';
import axios from 'axios';
import RTWeb3 from '@/lib/RTWeb3';
import moment from 'moment';
import { Project, ProjectsJson, CollectionData, collectionIdForProjectId } from '@/lib/rarity';
import { getAPIUrl } from '~/lib/api';

export interface Current {
  date: string;
  horiz1: string;
  horiz2: string;
  horiz1_target: string;
  horiz2_target: string;
  vert1: string;
  vert2: string;
  vert1_target: string;
  vert2_target: string;
  feature1: string;
  feature2: string;
}

export default class AppData {
  projects: { [projectName: string]: Project } = {};
  web3: RTWeb3 = new RTWeb3(this);

  dataBus = new Vue();

  openSeaAsset: any = null;

  lastCardShowMode: string = 'bySorted';
  lastCardSortMode: string = 'rarity';

  current: Current | null = null;

  /////////////////////////////////

  _currentProjectId: string | null = null;

  collectionData: CollectionData[] = [];
  collectionDataLookup: { [index: string]: CollectionData } = {};
  projectsJson: ProjectsJson = { list: [], lookup: {} };

  closingPopup = false;
  loadedStats = false;

  // used for secret unlisted projects in AppVue
  validUnlisted: string = '';

  get currentProjectId() {
    return this._currentProjectId;
  }
  set currentProjectId(newValue: string | null) {
    if (this._currentProjectId != newValue) {
      if (this._currentProjectId != null) {
        delete this.projects[this._currentProjectId!];
      }
      this._currentProjectId = newValue;
      //console.log('project change from ' + this._currentProjectId + ' to ' + newValue + ' addressChange call')
      this.onAddressChange();
    }
  }

  get currentProject() {
    if (this.currentProjectId) return this.projects[this.currentProjectId];
  }

  collectionForProjectId(projectId: string) {
    var collectionId = collectionIdForProjectId(projectId);
    if (collectionId) {
      return this.collectionDataLookup[collectionId];
    }
    return undefined;
  }

  ///////////////////////////////////////////

  async onAddressChange() {
    if (this.currentProject) {
      for (var projectId in this.projects) {
        var project = this.projects[projectId];
        project.modeLists['wallet'].resetIds();
      }

      this.dataBus.$emit('addressChange');
    }
  }

  //////////////////////////////////////

  get isDevelopment() {
    return window.location.href.indexOf('localhost') >= 0;
  }

  calculateExtraStats() {
    for (var coll of this.collectionData) {
      if (coll.stats) {
        if (coll.slug == 'cyberkongz') coll.stats.total_volume += 2052.1595;

        coll.stats.owner_percent =
          coll.stats.total_supply > 0 ? (coll.stats.num_owners * 100) / coll.stats.total_supply : 0;
        coll.stats.est_market_cap = coll.stats.seven_day_average_price * coll.stats.total_supply;
        coll.stats.added = this.projectsJson.lookup[coll.slug]
          ? moment(this.projectsJson.lookup[coll.slug].added, 'YYYY/MM/DD')
          : null;
      }
    }
  }

  async checkLoadCollections() {
    if (this.collectionData && this.collectionData.length) {
      return;
    }

    try {
      const rawResponse: { collections: CollectionData[]; projects: ProjectsJson } = (
        await axios.get(getAPIUrl('collections'))
      ).data;

      Vue.set(this, 'projectsJson', rawResponse.projects);
      Vue.set(this, 'collectionData', rawResponse.collections);
      this.calculateExtraStats();

      // Index all collections by slug.
      for (let collection of this.collectionData) {
        Vue.set(this.collectionDataLookup, collection.slug, collection);
      }
    } catch (e) {
      console.log('error loading collections');
      console.error(e);
    }
  }

  async checkLoadStats() {
    if (!this.loadedStats) {
      var url = 'https://collections.rarity.tools/collectionsStats';
      if (this.isDevelopment) {
        //url = 'http://localhost:9006/collectionsStats'
      }

      try {
        var rawCollections: CollectionData[] = (await axios.get(url)).data;
        for (var rawColl of rawCollections) {
          var coll = this.collectionDataLookup[rawColl.slug];
          if (coll) Vue.set(coll, 'stats', rawColl.stats);
        }
        this.calculateExtraStats();
        this.loadedStats = true;
      } catch (e) {
        console.log('error loading stats');
        console.error(e);
      }
    }
  }

  async checkLoadCollectionDetails(slug: string) {
    var coll = this.collectionDataLookup[slug];
    if (coll && !coll.details) {
      var url = 'https://collections.rarity.tools/collectionDetails/' + slug;
      if (this.isDevelopment) {
        //url = 'http://localhost:9006/collectionDetails/' + slug
      }
      try {
        var rawData: CollectionData = (await axios.get(url)).data;
        if (rawData) {
          Vue.set(coll, 'details', rawData.details);
          Vue.set(coll, 'stats', rawData.stats);
        }
        this.calculateExtraStats();
      } catch (e) {
        console.log('error loading details');
        console.error(e);
      }
    }
  }

  getCollectionsBy(prop: string) {
    if (prop === 'seven_day_volume') {
      // Already sorted on the backend by this metric
      return this.collectionData;
    }
    const copy = this.collectionData.slice();
    copy.sort((a: any, b: any) => {
      if (a.stats && !b.stats) return -1;
      if (!a.stats && b.stats) return 1;
      if (!a.stats && !b.stats) return 0;
      return b.stats[prop] - a.stats[prop];
    });
    return copy;
  }

  get newestCollections(): string[] {
    return this.projectsJson.list.slice().reverse();
  }

  get frontPageCollections() {
    const sortedIds = this.projectsJson.list.slice().reverse();

    sortedIds.sort((a, b) => {
      var aProject = this.projectsJson.lookup[a];
      var bProject = this.projectsJson.lookup[b];
      if (aProject.featured && !bProject.featured) {
        return -1;
      }
      if (!aProject.featured && bProject.featured) {
        return 1;
      }
      if (aProject.featured && bProject.featured) return (aProject.featuredOrder || 0) - (bProject.featuredOrder || 0);
      return sortedIds.indexOf(a) - sortedIds.indexOf(b);
    });

    return sortedIds;
  }

  //////////////////////////////

  async checkLoadCurrent() {
    if (!this.current) {
      var url = 'https://collections.rarity.tools/current';
      if (this.isDevelopment) {
        // url = 'http://localhost:9006/current'
      }
      try {
        Vue.set(this, 'current', (await axios.get(url)).data);
      } catch (e) {
        console.error(e);
      }
    }
  }
}
