import Vue from 'vue';
import AppData from '@/lib/AppData';

Vue.prototype.$app = Vue.observable(new AppData());
Vue.prototype.$bus = new Vue();

export const appData: AppData = Vue.prototype.$app;
export const vueEventBus = Vue.prototype.$bus;

export default (context: any) => {
  Vue.prototype.$app.$context = context;
};
