






















































import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';

@Component({})
export default class CardPropLine extends AppVue {
  @Prop()
  prop!: any;

  @Prop()
  sortMode!: string;
}
