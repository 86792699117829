import { AxiosRequestConfig } from 'axios';
import { RateLimiter } from 'limiter';
import AsyncRetry from 'async-retry';
import moment from 'moment';

import * as iso from '../iso';
import { OpenSeaGQLCollection } from './types';
import { Blockchain, CollectionData } from '../types';
import vhttp from '../vhttp';

const graphLimiter = new RateLimiter({ tokensPerInterval: 1, interval: 2000 });

export async function makeOpenSeaGraphRequest(query: string, variables: any = {}) {
  await graphLimiter.removeTokens(1);
  iso.debug('graphql: ' + query.split('\n')[1]);
  //iso.debug("graphql: " + query)
  //iso.debug("variables:")
  //iso.debug(variables)

  var request = {
    query,
    variables,
  };
  var graphPath = 'https://api.opensea.io/graphql/';
  var config: AxiosRequestConfig | undefined = undefined;
  if (iso.isServer && (process?.env?.NODE_ENV || 'development') == 'development') {
    iso.debug('using gqlProxy');
    graphPath = 'http://collections.rarity.tools:9111/gqlQuery';
    config = {
      headers: {
        'x-requested-by': 'dev',
      },
    } as AxiosRequestConfig;
  }
  var result = await vhttp.post(graphPath, request, config);
  var responseData = result.data;
  if (!responseData?.data) {
    iso.error('graphql response status ' + result.status + ' ' + result.statusText);
    iso.error('graphql response data:');
    iso.error(result.data);
    throw new Error('No data result!');
  }
  return responseData.data;
}

var assetFragment = `
	asset {
		tokenId
		relayId
		name
		imagePreviewUrl
		imageThumbnailUrl
		animationUrl
		assetContract {
			address
		}
		assetEventData {
			lastSale {
				timestamp
			}
		}
		orderData {
			bestBid {
				orderType
				paymentAssetQuantity {
					...AssetQuantity_data
				}
			}
			bestAsk {
				closedAt
				orderType
				dutchAuctionFinalPrice
				openedAt
				paymentAssetQuantity {
					...AssetQuantity_data
				}
			}
		}
		assetOwners(first: 1) {
			edges {
				node {
					owner {
						address
						user {
							username
						}
					}
				}
			}
		}
	}
`;

var assetQuantityFragment = `
	fragment AssetQuantity_data on AssetQuantityType {
		asset {
			decimals
			symbol
		}
		quantity
		quantityInEth
	}
`;

export interface OpenSeaGQLListings {
  search: {
    edges: {
      node: {
        asset: OpenSeaGQLAsset;
      };
    }[];
    totalCount?: number;
    pageInfo: {
      endCursor: string;
      hasNextPage: boolean;
    };
  };
}

// type is either ON_AUCTION or BUY_NOW
export async function getListings(
  collection: string,
  cursor: string | null,
  withTotalCount: boolean,
  type: string
): Promise<OpenSeaGQLListings> {
  var query = `
		query AssetSearchQuery(
			$collections: [CollectionSlug!]
			$cursor: String
		) {
			search(after: $cursor, chains: ["ETHEREUM"], collections: $collections, first: 100, resultType: ASSETS, toggles: [${type}], sortBy: LISTING_DATE, sortAscending: false) {
				edges {
					node {
						${assetFragment}
					}
				}
				${withTotalCount ? 'totalCount' : ''}
				pageInfo {
					endCursor
					hasNextPage
				}
			}
		}

		${assetQuantityFragment}
	`;
  var result = await makeOpenSeaGraphRequest(query, { collections: [collection], cursor });
  //iso.debug(JSON.stringify(result, undefined, "\t"))
  return result;
}

export interface OpenSeaGQLEventEdge {
  node: {
    assetQuantity: {
      asset: {
        tokenId: string;
        assetContract: {
          account: {
            address: string;
          };
        };
      };
    };
    fromAccount: {
      address: string;
    };
    eventTimestamp: string;
  };
}

export interface OpenSeaGQLEvents {
  assetEvents: {
    edges: OpenSeaGQLEventEdge[];
    pageInfo: {
      endCursor: string;
      hasNextPage: boolean;
    };
  };
}

export async function getEventsGQL(
  collection: string,
  cursor: string | null,
  newerThan: Date
): Promise<OpenSeaGQLEvents> {
  var query = `
		query AssetEventsQuery(
			$collections: [CollectionSlug!]
			$cursor: String
			$newerThan: DateTime
		) {
			assetEvents(after: $cursor, chains: ["ETHEREUM"], collections: $collections, first: 100, eventTimestamp_Gte: $newerThan, eventTypes: [AUCTION_CREATED, AUCTION_SUCCESSFUL, AUCTION_CANCELLED, ASSET_TRANSFER]) {
				edges {
					node {
						assetQuantity {
							asset {
								tokenId
								assetContract {
									account {
										address
									}
								}
							}
						}
						fromAccount {
							address
						}
						eventTimestamp
					}
				}
				pageInfo {
					endCursor
					hasNextPage
				}
			}
		}
	`;
  var result = await makeOpenSeaGraphRequest(query, {
    collections: [collection],
    cursor,
    newerThan: moment(newerThan).toISOString(),
  });
  //iso.debug(JSON.stringify(result, undefined, "\t"))
  return result;
}

export interface OpenSeaGQLAssetQuantity {
  asset: {
    decimals: number;
    symbol: string;
  };
  quantity: string;
  quantityInEth: string;
}

export interface OpenSeaGQLAsset {
  tokenId: string;
  relayId: string;
  name: string;
  animationUrl: string;
  imagePreviewUrl: string;
  imageThumbnailUrl: string;
  assetContract: {
    address: string;
  };
  assetEventData: {
    lastSale?: {
      timestamp?: string;
    };
  };
  orderData: {
    bestBid?: {
      orderType: 'BASIC' | 'DUTCH' | 'ENGLISH';
      paymentAssetQuantity: OpenSeaGQLAssetQuantity;
    };
    bestAsk?: {
      closedAt?: string;
      orderType: 'BASIC' | 'DUTCH' | 'ENGLISH';
      dutchAuctionFinalPrice?: string;
      openedAt: string;
      paymentAssetQuantity: OpenSeaGQLAssetQuantity;
    };
  };
  assetOwners: {
    edges: [
      {
        node: {
          owner: {
            address: string;
            user: {
              username: string;
            };
          };
        };
      }
    ];
  };
}

export interface OpenSeaGQLCollections {
  collections: {
    edges: {
      node: OpenSeaGQLCollection;
    }[];
    pageInfo: {
      endCursor: string;
      hasNextPage: boolean;
    };
  };
}

export async function getCollectionsGQL(collections: string[], cursor: string | null): Promise<OpenSeaGQLCollections> {
  var query = `
		query CollectionsQuery(
			$collections: [CollectionSlug!]
			$cursor: String
		) {
			collections(after: $cursor, collections: $collections, first: 100) {
				edges {
					node {
						name
						slug
						createdDate
						imageUrl
						largeImageUrl
						bannerImageUrl
						externalUrl
						chatUrl
						wikiUrl
						discordUrl
						telegramUrl
						twitterUsername
						instagramUsername
						mediumUsername
						description
						shortDescription
						featured
						isMintable
						assetContracts(first: 5) {
							edges {
								node {
									tokenStandard
									address
								}
							}
						}
						representativeAsset {
							imageWidth
							imageHeight
							imagePreviewUrl
							imageThumbnailUrl
							assetContract {
								tokenStandard
								account {
									address
								}
							}
						}
						stats {
							oneDayVolume
							oneDaySales
							oneDayChange
							oneDayAveragePrice
							sevenDayVolume
							sevenDaySales
							sevenDayChange
							sevenDayAveragePrice
							totalVolume
							totalSales
							totalSupply
							numOwners
							numReports
							averagePrice
							floorPrice
							marketCap
						}
					}
				}
				pageInfo {
					endCursor
					hasNextPage
				}
			}
		}
	`;
  var result = await makeOpenSeaGraphRequest(query, { collections, cursor });
  //iso.debug(JSON.stringify(result, undefined, "\t"))
  return result;
}

////////////////////////////////////////////////////////

export interface OpenSeaAddressAssetsResult {
  search: {
    edges: {
      node: {
        asset: {
          tokenId: string;
        };
      };
    }[];
    totalCount: number;
    pageInfo: {
      endCursor: string;
      hasNextPage: boolean;
    };
  };
}

export async function getAddressAssetsGQL(
  collections: string[],
  cursor: string | null,
  address: string
): Promise<OpenSeaAddressAssetsResult> {
  var query = `
		query AssetsQuery(
			$address: String
			$collections: [CollectionSlug!]
			$cursor: String
		) {
			search(after: $cursor, collections: $collections, first: 100, resultType: ASSETS, identity: {address: $address}) {
				edges {
					node {
						asset {
							tokenId
						}
					}
				}
				totalCount
				pageInfo {
					endCursor
					hasNextPage
				}
			}
		}
	`;
  var result = await makeOpenSeaGraphRequest(query, { collections, cursor, address });
  //iso.debug(JSON.stringify(result, undefined, "\t"))
  return result;
}

////////////////////////////////////////////////////////

export async function getCollectionDatasGQL(slugs: string[], withContracts: boolean): Promise<CollectionData[]> {
  // request collections
  var remaining = slugs.slice();
  var gqlCollections: OpenSeaGQLCollection[] = [];

  while (remaining.length) {
    var thisSet = remaining.splice(0, 50);

    var hasMore = true;
    var cursor: string | null = null;
    while (hasMore) {
      var gqlResult: OpenSeaGQLCollections;
      await AsyncRetry(
        async (_, attempt) => {
          if (attempt > 1) iso.warn('getCollections attempt ' + attempt);
          gqlResult = await getCollectionsGQL(thisSet, cursor);
        },
        { retries: 3, minTimeout: 1000, maxTimeout: 5000 }
      );

      if (gqlResult!) {
        for (var item of gqlResult.collections.edges) {
          gqlCollections.push(item.node);
        }
        hasMore = gqlResult.collections.pageInfo.hasNextPage;
        cursor = gqlResult.collections.pageInfo.endCursor;
      } else {
        hasMore = false;
      }
    }
  }

  var result: CollectionData[] = [];

  // process collection data received
  for (var gqlCollection of gqlCollections) {
    var collectionData: CollectionData = {
      slug: gqlCollection.slug,
      image_url: gqlCollection.imageUrl,
      stats: {
        one_day_volume: gqlCollection.stats.oneDayVolume,
        one_day_change: gqlCollection.stats.oneDayChange,
        one_day_sales: gqlCollection.stats.oneDaySales,
        one_day_average_price: gqlCollection.stats.oneDayAveragePrice,
        seven_day_volume: gqlCollection.stats.sevenDayVolume,
        seven_day_change: gqlCollection.stats.sevenDayChange,
        seven_day_sales: gqlCollection.stats.sevenDaySales,
        seven_day_average_price: gqlCollection.stats.sevenDayAveragePrice,
        total_volume: gqlCollection.stats.totalVolume,
        total_sales: gqlCollection.stats.totalSales,
        total_supply: gqlCollection.stats.totalSupply,
        //count: gqlCollection.stats.
        num_owners: gqlCollection.stats.numOwners,
        average_price: gqlCollection.stats.averagePrice,
        //num_reports: gqlCollection.stats.,
        floor_price: gqlCollection.stats.floorPrice,
        market_cap: gqlCollection.stats.marketCap,
      },
      details: {
        name: gqlCollection.name,
        blockchain: Blockchain.ETHEREUM, // opensea API only returns Ethereum (for now)
        created_date: gqlCollection.createdDate,
        description: gqlCollection.description,
        discord_url: gqlCollection.discordUrl,
        external_url: gqlCollection.externalUrl,
        large_image_url: gqlCollection.largeImageUrl,
        banner_image_url: gqlCollection.bannerImageUrl,
        medium_username: gqlCollection.mediumUsername,
        twitter_username: gqlCollection.twitterUsername,
        instagram_username: gqlCollection.instagramUsername,
      },
    };

    if (withContracts) {
      collectionData.contracts = [];
      for (var edge of gqlCollection.assetContracts.edges) {
        collectionData.contracts.push({
          contract: edge.node.address,
          tokenStandard: edge.node.tokenStandard,
        });
      }
    }

    if (gqlCollection.slug == 'cryptopunks' || gqlCollection.slug == 'meebits') {
      if (collectionData.details) {
        collectionData.details.discord_url = 'https://discord.gg/tQp4pSE';
        collectionData.details.twitter_username = 'larvalabs';
      }
    }

    if (gqlCollection.slug == 'axie') {
      collectionData.stats = {} as any;

      try {
        // we need to override the stats!
        var axieStats = await AsyncRetry(
          async (_, attempt) => {
            if (attempt > 1) iso.warn('getAxies attempt ' + attempt);
            return (
              await vhttp.get('https://6hejh2an7h.execute-api.us-east-1.amazonaws.com/default/axie-data/origins.json')
            ).data;
          },
          { retries: 0, minTimeout: 1000, maxTimeout: 5000 }
        );

        if (axieStats) {
          collectionData.stats = {
            seven_day_volume: axieStats['Volume_7d'],
            //seven_day_change: gqlCollection.stats.sevenDayChange,
            seven_day_sales: axieStats['Sales_7d'],
            seven_day_average_price: axieStats['Avg_Price_7d'],
            total_volume: axieStats['Volume_all'],
            total_sales: axieStats['Sales_all'],
            total_supply: axieStats['Total_Supply'],
            num_owners: axieStats['Owners'],
            //average_price: gqlCollection.stats.averagePrice,
            market_cap: axieStats['Market_Cap'],
          } as any;
        }
      } catch (e) {
        iso.error('Error reading Axie data');
        iso.logError(e);
      }
    }

    result.push(collectionData);
  }

  return result;
}
