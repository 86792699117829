


















































































































































































































































































































































































































































































































import AppVue from '@/components/AppVue';
import { Component, Watch } from 'nuxt-property-decorator';
import { CollectionCardLocation, collectionClicked, twitterContactClicked } from '~/plugins/mixpanel';
import BurgerIcon from '~/assets/burger.svg?inline';
import CloseIcon from '~/static/close.svg?inline';
import ArrowRightIcon from '~/static/arrow-right.svg?inline';
import DarkModeIcon from '~/static/dark-theme.svg?inline';
import LightModeIcon from '~/static/light-theme.svg?inline';

function isDescendant(parent: any, child: any) {
  var node = child.parentNode;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

@Component({
  components: {
    BurgerIcon,
    CloseIcon,
    ArrowRightIcon,
    DarkModeIcon,
    LightModeIcon,
  },
})
export default class TopBar extends AppVue {
  forceClose = false;
  forceOpen = false;
  menuOpen = false;
  isMobileSearchFocused = false;

  projectFilter = '';

  @Watch('forceOpen')
  onAllCollectionOpen(forceOpen: boolean) {
    if (forceOpen) {
      window.addEventListener('mousedown', this.windowListener);
    } else {
      window.removeEventListener('mousedown', this.windowListener);
    }
  }

  windowListener(ev: Event) {
    if (!isDescendant(this.$el, ev.target)) {
      this.closeAllCollectionsMenu();
    }
  }

  closeAllCollectionsMenu() {
    this.forceClose = true;
    this.forceOpen = false;
  }

  onAllCollectionItemClick() {
    this.trackTopCollectionClick();
    this.closeAllCollectionsMenu();
    this.projectFilter = '';
  }

  debouncedFilterChange(value: string) {
    this.projectFilter = value;
  }

  toggleColorMode(event: Event) {
    event.stopPropagation();
    let current = (this as any).$colorMode.preference;
    if (current !== 'dark') current = 'dark';
    else current = 'light';
    (this as any).$colorMode.preference = current;
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  stopEventPropagation(event: Event) {
    event.stopPropagation();
  }

  setMobileSearchFocusState(isFocused: boolean) {
    this.isMobileSearchFocused = isFocused;
  }

  trackTwitterClick() {
    twitterContactClicked('TopNavBar', 'rarity.tools');
  }

  trackNewCollectionClick() {
    collectionClicked(CollectionCardLocation.TOP_NAV_NEW, this.$route);
  }

  trackTopCollectionClick() {
    collectionClicked(CollectionCardLocation.TOP_NAV_ALL, this.$route);
  }
}
