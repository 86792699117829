

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Watch } from 'nuxt-property-decorator';
import { ethers } from 'ethers';
import AppVue from '@/components/AppVue';
import { Project } from '@/lib/rarity';
import { CollectionCardLocation, collectionClicked } from '@/plugins/mixpanel';
import { OS_REFERRER_QUERY } from '~/lib/rarity';

var urlon = require('urlon');

// import Web3 from 'web3'

const DARK_BANNER_PLACEHOLDER = '/images/dark-banner-placeholder.png';
const LIGHT_BANNER_PLACEHOLDER = '/images/light-banner-placeholder.png';

const STATIC_HOST_AND_BASE = process.env.STATIC_HOST_AND_BASE ?? 'https://projects.rarity.tools/static';
const USE_DATA_PROXY = process.env.USE_DATA_PROXY === 'true';

@Component({
  head(this: DefaultLayout) {
    var title = 'rarity.tools';
    if (this.projectId) {
      title = this.getProjectName(this.projectId) + ' | ' + title;
    }
    if (this.$route.params.id) {
      if (this.project) {
        title = this.project.displayNameFor(this.$route.params.id) + ' - ' + title;
      }
    }
    return { title };
  },
  watch: {
    async $route(this: DefaultLayout, newValue, oldValue) {
      if (this.checkRedirects()) return;
      this.onRouteChange(oldValue);
    },
  },
})
export default class DefaultLayout extends AppVue {
  page = 1;

  loadOpenSeaTimeout: any;
  lookupId = '';

  selectedProject = '';

  enableDrillDown = true;

  mobileShowingFilters = false;

  showCalcSettings = false;
  editingWeights = false;
  customWeightings = '';

  showDisclaimer = false;
  showSiteNews = false;
  showProjectNotes = false;

  sortMode = 'rarity';

  // filters
  minPrice = '';
  maxPrice = '';
  minRank = '';
  maxRank = '';
  ownerAddress = '';
  buyNow = '';
  auction = '';

  imagesMode = false;
  idsMode = true;

  async mounted() {
    if (this.checkRedirects()) return;

    var hasValidProjectId = await this.hasValidProjectId();
    if (!hasValidProjectId) {
      this.$router.push('/');
    }

    this.idsMode = (await this.$localForage.getItem('idsMode')) || false;

    await this.onRouteChange(null);

    this.$bus.$on('eventNFTsFiltered', () => {
      this.page = 1;
      this.scrollToContent();
    });
    this.$bus.$on('nextPage', () => {
      this.scrollToContent();
    });
    this.$app.dataBus.$on('addressChange', () => {
      if (this.project) this.$app.web3.loadTokenIds(this.project);
    });

    if (this.isRarityScoreDisabled && this.sortMode === 'rarity') {
      this.$router.replace({
        path: this.$route.path,
        query: {
          sort: 'byId',
        },
      });
    }
  }

  scrollToContent() {
    if (this.$refs.scrollArea && this.$refs.contentBegin) {
      if (this.$refs.scrollArea.scrollTop > this.$refs.contentBegin.offsetTop - 60) {
        this.$refs.contentBegin.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  checkRedirects() {
    if (this.projectId == 'boredapeyachtclubalt') {
      this.$router.replace({
        path: this.$route.path.replace('boredapeyachtclubalt', 'boredapeyachtclub'),
        query: {
          preset: 'rarity-tools-1',
          matches: 'on',
        },
      });
      return true;
    }
    return false;
  }

  connectWallet() {
    try {
      this.$app.web3.connect();
    } catch (err) {
      this.$sentry.addBreadcrumb({
        category: 'data',
        message: 'window.ethereum: ' + JSON.stringify((window as any).ethereum),
      });
      this.$sentry.captureException(err);
    }
  }

  @Watch('enableDrillDown')
  updateDrillDowns() {
    if (this.project) {
      if (this.project.enableDrillDown != this.enableDrillDown) {
        this.project.enableDrillDown = this.enableDrillDown;
        if (this.project.enableDrillDown) {
          this.checkApplyUrlFromFilters();
        }
      }
    }
  }

  async onRouteChange(oldRoute: any) {
    await Promise.all([this.$app.checkLoadCollections(), this.$app.checkLoadCurrent()]);

    this.$app.checkLoadStats();

    if (await this.hasValidProjectId()) {
      this.$app.checkLoadCollectionDetails(this.projectId);
      this.$localForage.setItem('lastProjectId', this.projectId);
    }

    // sort mode query
    if (this.$route.query.sort) {
      this.sortMode = this.$route.query.sort as string;
    } else {
      // Different default sort for collections without rarity score
      this.sortMode = this.isRarityScoreDisabled ? 'byId' : 'rarity';
    }

    // check disclaimer
    var dismissedDisclaimer = (await this.$localForage.getItem('dismissedDisclaimer2')) || false;
    if (!dismissedDisclaimer) this.showDisclaimer = true;

    this.selectedProject = this.projectId;
    await this.checkLoadProject();
    this.$app.currentProjectId = this.projectId;

    this.$set(this.$app, 'openSeaAsset', null);
    if (this.$route.params.id) {
      // always update opensea data for item and save the asset
      var assets = await this.project?.updateMarketData([this.$route.params.id], USE_DATA_PROXY);
      if (assets?.length) {
        this.$set(this.$app, 'openSeaAsset', assets[0]);
      }
    }

    if (this.mode == 'wallet' && this.project) {
      this.$app.web3.loadTokenIds(this.project);
    }

    this.checkApplyFiltersFromUrl();

    if (!this.$route.params.id && this.$refs.scrollArea && this.$refs.contentBegin && !oldRoute?.params?.id) {
      //this.scrollToContent()
    }

    if (this.project) {
      this.pageSize = this.project.config.images.pageSize || 48;
      // filters queries

      var otherFilters = this.project.otherFilters;
      for (var prop of Project.OTHER_FILTERS) {
        var value = (otherFilters as any)[prop];
        if (value) {
          this.$set(this, prop, value);
        } else {
          this.$set(this, prop, '');
        }
      }
    }
    if (this.openseaRecentlyListed) this.loadRecent();
  }

  async onProjectChange() {
    this.page = 1;
    this.$router.push(`/${this.selectedProject}`);
  }

  get nftIdsToDisplay() {
    if (this.openseaRecentlyListed) {
      return this.project?.getFilteredIdsForMode('recentlyListed');
    }
    if (this.mode == 'all') {
      return this.nftIdsInPage;
    }
    if (this.mode == 'wallet') {
      return this.project?.getFilteredIdsForMode('wallet');
    }
    return this.project?.getFilteredIdsForMode(this.mode) || [];
  }

  get totalMatchingCount() {
    return this.activeModeList?.filteredIds?.length || 0;
  }

  get nftIdsInPage() {
    if (this.page > 0) {
      var ids = this.project?.getFilteredIdsForMode(this.mode);
      var selectedIds: string[] = [];
      if (ids) {
        var pageSize = this.pageSize;
        var start = (this.page - 1) * pageSize;
        var end = start + pageSize;
        selectedIds = ids.slice(start, end);
      }

      clearTimeout(this.loadOpenSeaTimeout);
      this.loadOpenSeaTimeout = 0;
      setTimeout(() => {
        this.loadMarketData();
      }, 100);
      return selectedIds;
    }
    return [];
  }

  async loadMarketData() {
    this.project?.updateMarketData(this.nftIdsInPage, USE_DATA_PROXY);
  }

  async loadRecent() {
    await this.project?.loadRecentlyListed();
  }

  removeFilter(pair: any[]) {
    if (this.project) {
      this.$delete(this.project.filtersActive[pair[0]], pair[1]);
      this.checkApplyUrlFromFilters();
      this.$bus.$emit('eventNFTsFiltered');
    }
  }

  get activeFilters() {
    var result: any[] = [];
    if (this.project) {
      for (var prop in this.project.filtersActive) {
        var propValues = this.project.filtersActive[prop];
        for (var value in propValues) {
          result.push([prop, value]);
        }
      }
    }
    return result;
  }

  lookup() {
    var lookupId = this.lookupId?.trim();
    if (lookupId && this.project?.itemLookup[lookupId]) {
      var target = this.linkToNFT(lookupId);
      if (target) this.$router.push(target);
    }
    if (this.project) {
      var propDef = this.project.basePropDefLookup['displayId'];
      if (propDef) {
        for (var item of this.project.data.items) {
          if (this.project.getPropertyEncodedValue(propDef, item) == lookupId) {
            var target = this.linkToNFT(item[0]);
            if (target) this.$router.push(target);
            break;
          }
        }
      }
    }
  }

  setCollapsedAll(value: boolean) {
    this.$bus.$emit('collapseAll', value);
  }

  ///////

  get totalPages() {
    if (!this.project) return 0;

    return Math.ceil(this.project.getFilteredIdsForMode(this.mode).length / this.pageSize);
  }

  get headerText() {
    var projectName = this.getProjectName(this.projectId);

    if (this.project?.config.rankingSets && this.project.config.rankingSets.length > 1) {
      for (var rset of this.project.config.rankingSets) {
        if (rset.projectId == this.projectId) {
          projectName += ': ' + rset.name;
        }
      }
    }

    if (this.mode == 'all' && !this.isRarityScoreDisabled) return projectName + ' Ranked by Rarity';
    if (this.mode == 'traits') return 'Explore ' + projectName + ' Traits';
    return this.humanize(this.mode) + ' ' + projectName;
  }

  get openseaRecentlyListed() {
    return this.project && this.mode == 'all' && !this.project.hasPrices && this.sortMode == 'recentlyListed';
  }

  get activeModeList() {
    if (!this.project) return undefined;
    if (this.openseaRecentlyListed) {
      return this.project.modeLists['recentlyListed'];
    }
    return this.project.modeLists[this.mode];
  }

  get showLoading() {
    if (!this.project) return true;
    return this.activeModeList?.loading;
  }

  get showLoadMore() {
    if (this.openseaRecentlyListed) {
      return this.project && !this.project.modeLists['recentlyListed'].loading;
    }
    return false;
  }

  async loadMore() {
    if (this.openseaRecentlyListed) {
      await this.project!.loadMoreRecentlyListed();
    }
  }

  get amountText() {
    if (this.mode != 'all' || this.openseaRecentlyListed) {
      return undefined;
    }
    if (this.project?.hasFiltersActive) {
      return 'Found ' + this.totalMatchingCount?.toLocaleString() + ' Matching';
    } else {
      return this.totalMatchingCount?.toLocaleString() + ' Total ' + this.getProjectName(this.projectId);
    }
    return undefined;
  }

  applyCustomWeightings() {
    try {
      var newWeights = JSON.parse(this.customWeightings);
      var propWeights = this.project?.sanitizePropWeights(newWeights);
      this.$router.push(this.linkToQueryParams({ propWeights: urlon.stringify(propWeights) }) as any);
      this.editingWeights = false;
    } catch (e) {}
  }

  beginEditingWeights() {
    this.customWeightings = JSON.stringify(this.project!.calcOptions.propWeights, undefined, '  ');
    this.editingWeights = true;
  }

  get activePropWeights() {
    var result: { [propName: string]: number } = {};
    if (this.project?.calcOptions?.propWeights && this.project?.calcOptions?.weights) {
      var propWeights = this.project.calcOptions.propWeights;
      for (var propName in propWeights) {
        var propDef = this.project.getPropDef(propName);
        if (propDef) {
          var weight = this.project.getPropWeight(propDef);
          result[propName] = weight;
        }
      }
    }
    return result;
  }

  get openSeaLink() {
    return 'https://opensea.io' + OS_REFERRER_QUERY;
  }

  dismissDisclaimer() {
    this.showDisclaimer = false;
    this.$localForage.setItem('dismissedDisclaimer2', true);
  }

  toggleShowSiteNews() {
    this.showSiteNews = !this.showSiteNews;
    this.$localForage.setItem('showSiteNews', this.showSiteNews);
  }

  toggleShowProjectNotes() {
    this.showProjectNotes = !this.showProjectNotes;
    this.$localForage.setItem('showProjectNotes', this.showProjectNotes);
  }

  onSortChange() {
    if (this.project) {
      this.project.sort = this.sortMode;
      this.$router.push(this.linkToQueryParams({ sort: this.sortMode }) as any);
    }
  }

  // must also applyFilters
  setFilter(propName: string, value: string) {
    if (this.project) {
      // first clear
      this.clearFilter(propName);

      // next set
      var valObj: any = {};
      valObj[value] = true;
      this.$set(this.project.filtersActive, propName, valObj);
    }
  }

  // must also applyFilters
  clearFilter(propName: string) {
    if (this.project) {
      // next set
      this.$delete(this.project.filtersActive, propName);
    }
  }

  applyFilters() {
    if (this.project) {
      this.checkApplyUrlFromFilters();
      this.$bus.$emit('eventNFTsFiltered');
    }
  }

  toggleFilterBuyNow() {
    if (this.project) {
      if (this.project.otherFilters.buyNow) {
        this.clearFilter('buyNow');
      } else {
        this.setFilter('buyNow', 'On');
      }
      this.applyFilters();
    }
  }

  toggleFilterAuction() {
    if (this.project) {
      if (this.project.otherFilters.auction) {
        this.clearFilter('auction');
      } else {
        this.setFilter('auction', 'On');
      }
      this.applyFilters();
    }
  }

  applyFiltersPrice() {
    if (this.project) {
      var parsedMin = parseFloat(this.minPrice);
      var parsedMax = parseFloat(this.maxPrice);
      if (isNaN(parsedMin)) {
        this.clearFilter('minPrice');
        this.minPrice = '';
      } else {
        this.setFilter('minPrice', parsedMin.toString());
      }
      if (isNaN(parsedMax)) {
        this.clearFilter('maxPrice');
        this.maxPrice = '';
      } else {
        this.setFilter('maxPrice', parsedMax.toString());
      }
      this.applyFilters();
    }
  }

  applyFiltersRank() {
    if (this.project) {
      var parsedMin = parseInt(this.minRank);
      var parsedMax = parseInt(this.maxRank);
      if (isNaN(parsedMin)) {
        this.clearFilter('minRank');
        this.minRank = '';
      } else {
        this.setFilter('minRank', parsedMin.toString());
      }
      if (isNaN(parsedMax)) {
        this.clearFilter('maxRank');
        this.maxRank = '';
      } else {
        this.setFilter('maxRank', parsedMax.toString());
      }
      this.applyFilters();
    }
  }

  async applyFiltersOwner() {
    if (this.project) {
      var provider = await this.$app.web3.getProvider();
      if (provider) {
        if (ethers.utils.isAddress(this.ownerAddress)) {
          var lowered = this.ownerAddress.toLowerCase();
          this.setFilter('ownerAddress', lowered);
          this.applyFilters();
        }
      } else {
        console.log('no web3');
      }
    }
  }

  get bannerImageUrl() {
    // The banner is the second item in the tuple.
    const customBannerExtension = this.getMetaImageExtensions(this.collectionId!)?.[1];
    if (customBannerExtension) {
      return `${process.env.COLLECTIONS_CDN}/${this.collectionId}/banner.${customBannerExtension}`;
    }

    if (this.projectLookup(this.projectId)?.customBanner) {
      var ext = this.projectLookup(this.projectId)?.customBanner;
      return `${STATIC_HOST_AND_BASE}/images/${this.collectionId}/banner.${ext}`;
    }

    // The collection metadata (as loaded from the marketplace backend) may also provide a banner.
    if (this.projectCollection && this.projectCollection.details?.banner_image_url) {
      return this.projectCollection.details.banner_image_url;
    }

    // Display the default fallback banner.
    return (this as any).$colorMode.preference === 'dark' ? DARK_BANNER_PLACEHOLDER : LIGHT_BANNER_PLACEHOLDER;
  }

  toggleIdsMode() {
    this.idsMode = !this.idsMode;
    this.$localForage.setItem('idsMode', this.idsMode);
  }

  get isOpenSeaMaintenance() {
    return process.env.OPENSEA_MAINTENANCE === 'true';
  }

  get areExternalServicesHavingIssues() {
    return process.env.EXTERNAL_SERVICES_ISSUES === 'true';
  }

  get collectionDescription() {
    if (!this.projectCollection?.details) {
      return '';
    }
    return (
      this.projectCollection.details.description || this.getFallbackDescription(this.projectCollection.details.name)
    );
  }

  get mintProgressPercentage(): number | false {
    const projectJson = this.collectionId ? this.projectLookup(this.collectionId) : undefined;
    if (
      !(projectJson?.isMinting && projectJson?.maxItems && projectJson?.maxItems > (this.project?.totalItemCount ?? 0))
    ) {
      return false;
    }

    return (this.project?.totalItemCount ?? 0) / projectJson?.maxItems;
  }

  get isMinting() {
    return this.mintProgressPercentage !== false;
  }

  get blockchain() {
    return this.projectCollection?.details?.blockchain || this.project?.config.chain || '';
  }

  trackLatestCollectionClick() {
    this.trackCollectionClick(CollectionCardLocation.COLLECTION_LATEST);
  }

  trackTopCollectionClick() {
    this.trackCollectionClick(CollectionCardLocation.COLLECTION_TOP);
  }

  trackCollectionClick(location: CollectionCardLocation) {
    collectionClicked(location, this.$route);
  }
}
