





































































import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';
import { BasePropDef } from '@/lib/rarity';

// import Web3 from 'web3'

@Component({})
export default class NFTCard extends AppVue {
  @Prop()
  nftId!: string;

  @Prop()
  compact!: boolean;

  @Prop({ default: null })
  aspectRatioPropDef!: BasePropDef;

  @Prop({ default: false })
  idsMode!: boolean;

  get link() {
    return this.linkToNFT(this.nftId);
  }
}
