




import AppVue from '@/components/AppVue';
import { Component, Prop } from 'nuxt-property-decorator';

// import Web3 from 'web3'

const DARK_BADGE_PLACEHOLDER = '/images/dark-badge-placeholder.png';
const LIGHT_BADGE_PLACEHOLDER = '/images/light-badge-placeholder.png';

const STATIC_HOST_AND_BASE = process.env.STATIC_HOST_AND_BASE ?? 'https://projects.rarity.tools/static';

@Component({
  layout: 'home',
})
export default class ProjectBadge extends AppVue {
  @Prop()
  id!: string;

  async mounted() {
    //await this.$app.checkLoadCollections()
  }

  get imgUrl() {
    const extension = this.getMetaImageExtensions(this.id)?.[0];
    if (extension) {
      return `${process.env.COLLECTIONS_CDN}/${this.id}/badge.${extension}?height=48`;
    }

    const collection = this.$app.collectionForProjectId(this.id);
    const lookup = this.projectLookup(this.id);
    if (lookup && lookup.customBadge) {
      return `${STATIC_HOST_AND_BASE}/images/${this.id}/badge.${lookup.customBadge}`;
    }

    if (collection && collection.image_url) {
      if (collection.image_url.endsWith('=s120')) {
        return collection.image_url.replace('=s120', '=s48');
      }
      return collection.image_url;
    }

    return (this as any).$colorMode.preference === 'dark' ? DARK_BADGE_PLACEHOLDER : LIGHT_BADGE_PLACEHOLDER;
  }
}
