











































import moment from 'moment';
import { Component, Prop } from 'nuxt-property-decorator';
import AppVue from '@/components/AppVue';
import { CollectionData } from '@/lib/rarity';

// import Web3 from 'web3'

const STATIC_HOST_AND_BASE = process.env.STATIC_HOST_AND_BASE ?? 'https://projects.rarity.tools/static';

const DARK_HEADER_PLACEHOLDER = '/images/dark-header-placeholder.png';
const LIGHT_HEADER_PLACEHOLDER = '/images/light-header-placeholder.png';

@Component({})
export default class ProjectBox extends AppVue {
  @Prop()
  slug!: string;

  @Prop({ default: false })
  showFeaturedIfFeatured!: boolean;

  @Prop({ default: () => () => {} })
  onClick!: () => void;

  mounted() {
    this.$app.checkLoadCollectionDetails(this.slug);
  }

  // Collection stats data may not be available for some projects
  get collection(): CollectionData | undefined {
    return this.$app.collectionForProjectId(this.slug);
  }

  get projectInfo() {
    return this.projectLookup(this.slug);
  }

  get blockchain() {
    const chain =
      this.projectInfo.chain ||
      this.$app.collectionForProjectId(this.slug)?.details?.blockchain ||
      this.getProject(this.slug)?.config.chain ||
      '';

    if (!chain) {
      this.$app.checkLoadCollectionDetails(this.slug);
    }

    return chain;
  }

  formatAddedDate(added: string) {
    return moment(added).format('D MMM YYYY');
  }

  get showFeatured() {
    if (this.showFeaturedIfFeatured) {
      var project = this.$app.projectsJson.lookup[this.slug];
      if (project && project.featured) return true;
    }
    return false;
  }

  get headerImgUrl() {
    const extension = this.getMetaImageExtensions(this.slug)?.[2];
    if (extension) {
      return `${process.env.COLLECTIONS_CDN}/${this.slug}/header.${extension}?height=220`;
    }

    const project = this.$app.projectsJson.lookup[this.slug];
    if (project && project.customHeader) {
      return `${STATIC_HOST_AND_BASE}/images/${this.slug}/header.${project.customHeader}`;
    }

    if (!this.collection?.details?.banner_image_url) {
      return (this as any).$colorMode.preference === 'dark' ? DARK_HEADER_PLACEHOLDER : LIGHT_HEADER_PLACEHOLDER;
    }

    if (this.collection?.details?.banner_image_url?.endsWith('=s2500')) {
      return this.collection?.details?.banner_image_url.replace('=s2500', '=h220');
    }

    return this.collection?.details?.banner_image_url;
  }

  get collectionDescription() {
    if (!this.collection?.details) {
      return '';
    }
    return this.collection.details.description || this.getFallbackDescription(this.collection.details.name);
  }
}
