//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'item-component',

  props: {
    source: {
      type: Object,
      default() {
        return {};
      },
    },
    projectLookup: {
      type: Object,
      default() {
        return {};
      },
    },
    formatETH: {
      type: Function,
      default() {
        return () => {};
      },
    },
    onItemClick: {
      type: Function,
      default() {
        return () => {};
      },
    },
  },

  data() {
    return {
      projectName: this.projectLookup[this.source.slug].name,
    };
  },

  computed: {
    formattedVolume() {
      return this.source.seven_day_volume ? this.formatETH(this.source.seven_day_volume) : '-';
    },
  },
};
