import * as iso from '../iso';
import { RateLimiter } from 'limiter';
import { OpenSeaAsset, OpenSeaEvent, OpenSeaCollection, StatsEndpointReturn, CollectionWithStats } from './types';
import { Blockchain, CollectionData } from '../types';
import { ProjectsJson, ProjectsJsonItem } from '../Project';
import AsyncRetry from 'async-retry';
import vhttp from '../vhttp';

const DATA_ORIGIN = process.env.DATA_ORIGIN ?? 'https://data.rarity.tools';

const serverLimiter = new RateLimiter({ tokensPerInterval: 1, interval: 1500 });
const clientLimiter = new RateLimiter({ tokensPerInterval: 1, interval: 1500 });

declare var window: any;

export async function makeOpenSeaAPIRequest<T = any>(apiURL: string, silent = false) {
  if (typeof window != 'undefined') {
    const apiProxyUrl = apiURL.replace(
      'https://api.opensea.io/api/v1/',
      `${process.env.API_BASE_URL}/api/v0/proxy/os/`
    );
    await clientLimiter.removeTokens(1);

    if (!silent) iso.debug('client ' + apiProxyUrl);
    return await vhttp.get<T>(apiProxyUrl);
  } else {
    const serverHeaders = process.env.OPENSEA_API_KEY
      ? {
          'X-API-KEY': process.env.OPENSEA_API_KEY,
        }
      : undefined;

    await serverLimiter.removeTokens(1);

    if (!silent) iso.debug('server ' + apiURL);
    return await vhttp.get<T>(apiURL, {
      headers: serverHeaders,
    });
  }
}

export async function makeOpenSeaAPIRequestWithRetries<T = any>(apiURL: string, silent = false) {
  return await AsyncRetry(
    async (_, attempt) => {
      if (attempt > 1) {
        iso.warn('attempt ' + attempt + ' for ' + apiURL);
      }

      return await makeOpenSeaAPIRequest<T>(apiURL, silent);
    },
    {
      retries: 5,
      minTimeout: 500,
      maxTimeout: 20000,
    }
  );
}

//////////////////////////////////////////////////

export function getOpenSeaAPIUrlFor(contract: string, ids: string[], limit: number = 20) {
  var params = `asset_contract_address=${contract}&limit=${limit}`;
  var foundCount = 0;
  for (var nftId of ids) {
    params += '&token_ids=' + nftId;
    foundCount++;
  }
  if (foundCount) return 'https://api.opensea.io/api/v1/assets?' + params;
  return undefined;
}

export const getProxiedDataUrlFromOpenSeaAPIUrl = (openSeaApiURL: string) => {
  return openSeaApiURL.replace('https://api.opensea.io/api/v1/assets', `${DATA_ORIGIN}/api/proxy/api/v1/assets`);
};

export async function getEvents(
  collection: string,
  eventType: string,
  newerThan: Date,
  cursor: string | null,
  limit = 50
): Promise<{ asset_events: OpenSeaEvent[]; next: string | null }> {
  const occurred_after = Math.floor(newerThan.valueOf() / 1000);

  const params = new URLSearchParams({
    collection_slug: collection,
    event_type: eventType,
    only_opensea: false + '',
    limit: limit + '',
    occurred_after: occurred_after + '',
  });

  if (cursor) {
    params.set('cursor', cursor);
  }

  const url = `https://api.opensea.io/api/v1/events?${params}`;

  return (await makeOpenSeaAPIRequest(url)).data;
}

// if more than X tokenIds will automatically do multiple queries and merge
export async function getAssets(contract: string, tokenIds: string[], silent = false) {
  var remainingIds = tokenIds.slice();
  var results: OpenSeaAsset[] = [];
  while (remainingIds.length) {
    var nextIds = remainingIds.splice(0, 20);
    var apiURL = getOpenSeaAPIUrlFor(contract, nextIds);
    if (apiURL) {
      var newAssets = (await makeOpenSeaAPIRequest(apiURL, silent)).data?.assets || [];
      results = results.concat(newAssets);
    }
  }
  return results;
}

////////////////////////////////////////////////////////

export async function getAddressAssets(
  collection: string,
  address: string,
  useDataRT: boolean = false
): Promise<string[]> {
  let results: string[] = [];
  let assets: OpenSeaAsset[] = [];
  let offset = 0;
  let limit = 20;

  while (true) {
    const url = `https://api.opensea.io/api/v1/assets?collection=${collection}&owner=${address}&offset=${offset}&limit=${limit}`;

    let result;
    if (useDataRT) {
      const dataUrl = getProxiedDataUrlFromOpenSeaAPIUrl(url);
      result = await vhttp.get(dataUrl);
    } else {
      result = await makeOpenSeaAPIRequest(url);
    }

    if (!result?.data?.assets?.length) {
      break;
    }

    const newItems: OpenSeaAsset[] = result.data?.assets;
    assets = [...assets, ...newItems];

    if (newItems.length < limit) {
      break;
    }

    offset += limit;
  }

  for (const asset of assets) {
    results.push(asset.token_id);
  }

  return results;
}

////////////////////////////////////////////////////////

export async function getCollectionDataSingle(
  slug: string,
  withContracts: boolean,
  projectsJson?: ProjectsJson
): Promise<CollectionData | undefined> {
  // first, we get some example assets from the collection
  iso.debug('loading collection: ' + slug);
  var limit = 1;

  if (projectsJson) limit = 5;

  var assetsResult = await makeOpenSeaAPIRequestWithRetries<{ assets: OpenSeaAsset[] }>(
    `https://api.opensea.io/api/v1/assets?offset=0&limit=${limit}&collection=${slug}`
  );

  let collectionData: CollectionData | undefined = undefined;

  const collectionResult = await makeOpenSeaAPIRequestWithRetries<OpenSeaCollection>(
    `https://api.opensea.io/api/v1/collection/${slug}`
  );
  if (collectionResult?.data?.collection) {
    const collection = collectionResult.data.collection;

    collectionData = {
      slug,
      image_url: collection.image_url,
      details: {
        blockchain: Blockchain.ETHEREUM,
        name: collection.name,
        created_date: collection.created_date,
        description: collection.description,
        discord_url: collection.discord_url,
        external_url: collection.external_url,
        large_image_url: collection.large_image_url,
        banner_image_url: collection.banner_image_url,
        medium_username: collection.medium_username,
        twitter_username: collection.twitter_username,
        instagram_username: collection.instagram_username,
      },
    };

    if (withContracts) {
      collectionData.contracts = collection.primary_asset_contracts.map((item) => ({
        contract: item.address,
        tokenStandard: item.schema_name as 'ERC721' | 'ERC1155',
      }));
    }
  } else if (assetsResult.data?.assets?.length) {
    const firstAsset = assetsResult.data.assets[0];

    collectionData = {
      slug,
      image_url: firstAsset.collection.image_url,
      details: {
        blockchain: Blockchain.ETHEREUM,
        name: firstAsset.collection.name,
        created_date: firstAsset.collection.created_date,
        description: firstAsset.collection.description,
        discord_url: firstAsset.collection.discord_url,
        external_url: firstAsset.collection.external_url,
        large_image_url: firstAsset.collection.large_image_url,
        banner_image_url: firstAsset.collection.banner_image_url,
        medium_username: firstAsset.collection.medium_username,
        twitter_username: firstAsset.collection.twitter_username,
        instagram_username: firstAsset.collection.instagram_username,
      },
    };

    if (withContracts) {
      collectionData.contracts = [
        {
          contract: firstAsset.asset_contract?.address,
          tokenStandard: firstAsset.asset_contract?.schema_name as any,
        },
      ];
    }
  }

  if (!collectionData) {
    iso.error('error: could not find any assets with slug: ' + slug);
    iso.error(
      'This can happen if the project is not on Ethereum. Please mark the "Creation status" as "Error" in Airtable, a Technical Lister will troubleshoot it'
    );
    return undefined;
  }

  if (projectsJson) {
    // if this is specified means we want stats
    var projectLookup: ProjectsJsonItem | undefined;
    if (projectsJson) {
      projectLookup = projectsJson.lookup[slug];
    }

    var asset_owner: string | undefined = projectLookup?.useOwner;
    if (!asset_owner) {
      for (var asset of assetsResult.data.assets) {
        var address = asset.owner?.address;
        if (address != '0x0000000000000000000000000000000000000000') {
          asset_owner = address;
          break;
        }
      }
    }

    if (!asset_owner) {
      // we lookup the single asset by itself to try to get the owner from top_ownerships
      iso.info('loading single asset for ' + slug);
      var firstAsset = assetsResult.data.assets[0] as OpenSeaAsset;
      var singleResult = await makeOpenSeaAPIRequestWithRetries(
        `https://api.opensea.io/api/v1/asset/${firstAsset.asset_contract.address}/${firstAsset.token_id}`
      );
      if (singleResult.data) {
        var singleAsset = singleResult.data as OpenSeaAsset;
        if (singleAsset.top_ownerships && singleAsset.top_ownerships.length) {
          var topOwner = singleAsset.top_ownerships[0];
          asset_owner = topOwner?.owner?.address;
        }
      }
    }

    if (!asset_owner) {
      iso.error('error: could not get stats because could not find any asset_owners for slug: ' + slug);
    } else {
      //console.log('finding from asset_owner ' + asset_owner)
      var offset = 0;
      var limit = 300;
      var collection: any;
      while (!collection) {
        //console.log('collections api call, offset ' + offset)
        var collectionByAssetOwner = await makeOpenSeaAPIRequestWithRetries(
          `https://api.opensea.io/api/v1/collections?offset=${offset}&limit=${limit}&asset_owner=${asset_owner}`
        );
        // find the specified collection
        if (!collectionByAssetOwner.data?.length) {
          iso.error('error: could not find collections for slug ' + slug + ' with owner ' + asset_owner);
          return undefined;
        }
        for (var coll of collectionByAssetOwner.data) {
          if (coll.slug == slug) {
            collection = coll;
            break;
          }
        }
        if (collection) break;

        if (collectionByAssetOwner.data.length < limit) break;

        offset += limit;
      }

      if (!collection) {
        iso.error('error: could not find collection for stats: ' + slug);
      } else {
        var srcStats = collection.stats;
        collectionData.seven_day_volume = srcStats.seven_day_volume;
        collectionData.stats = {
          one_day_volume: srcStats.one_day_volume,
          one_day_change: srcStats.one_day_change,
          one_day_sales: srcStats.one_day_sales,
          one_day_average_price: srcStats.one_day_average_price,
          seven_day_volume: srcStats.seven_day_volume,
          seven_day_change: srcStats.seven_day_change,
          seven_day_sales: srcStats.seven_day_sales,
          seven_day_average_price: srcStats.seven_day_average_price,
          total_volume: srcStats.total_volume,
          total_sales: srcStats.total_sales,
          total_supply: srcStats.total_supply,
          num_owners: srcStats.num_owners,
          average_price: srcStats.average_price,
          market_cap: srcStats.market_cap,
          floor_price: srcStats.floor_price,
        };
      }
    }
  }
  return collectionData;
}

// always tries to include stats
export async function getCollectionDatas(
  slugs: string[],
  projectsJson?: ProjectsJson,
  options?: { onCollectionError: (err: unknown) => void }
): Promise<CollectionData[]> {
  var collectionDatas: CollectionData[] = [];

  var slugsFound: { [slug: string]: CollectionData } = {};
  var slugsFinding: { [slug: string]: boolean } = {};

  for (var slug of slugs) {
    slugsFinding[slug] = true;
  }

  for (var slug of slugs) {
    if (slugsFound[slug]) continue;

    // first, we get some example assets from the collection
    iso.debug('loading collection: ' + slug);
    var limit = 1;
    if (projectsJson) limit = 5;

    try {
      var result = await makeOpenSeaAPIRequestWithRetries(
        `https://api.opensea.io/api/v1/assets?offset=0&limit=${limit}&collection=${slug}`
      );

      if (!result.data?.assets?.length) {
        throw new Error(`error: could not find any assets with slug: ${slug}`);
      }
    } catch (e) {
      iso.logError(e);
      iso.error('error: during lookup assets with slug: ' + slug);

      options?.onCollectionError?.(e);

      continue;
    }

    var projectLookup = projectsJson?.lookup[slug];
    var asset_owner: string | undefined = projectLookup?.useOwner;
    if (!asset_owner) {
      for (var asset of result.data.assets) {
        var address = asset.owner?.address;
        if (address != '0x0000000000000000000000000000000000000000') {
          asset_owner = address;
          break;
        }
      }
    }

    if (!asset_owner) {
      try {
        // we lookup the single asset by itself to try to get the owner from top_ownerships
        iso.info('loading single asset for ' + slug);
        var firstAsset = result.data.assets[0] as OpenSeaAsset;
        var singleResult = await makeOpenSeaAPIRequestWithRetries(
          `https://api.opensea.io/api/v1/asset/${firstAsset.asset_contract.address}/${firstAsset.token_id}`
        );
        if (singleResult.data) {
          var singleAsset = singleResult.data as OpenSeaAsset;
          if (singleAsset.top_ownerships && singleAsset.top_ownerships.length) {
            var topOwner = singleAsset.top_ownerships[0];
            asset_owner = topOwner?.owner?.address;
          }
        }
      } catch (e) {
        iso.logError(e);
        iso.error('error: during lookup assets with slug: ' + slug);

        if (options?.onCollectionError) {
          options.onCollectionError(e);
        }
        continue;
      }
    }

    if (!asset_owner) {
      iso.warn(
        'error: could not get stats because could not find any asset_owners for slug: ' + slug + ', skipping for now'
      );
      continue;
    } else {
      try {
        //console.log('finding from asset_owner ' + asset_owner)
        var offset = 0;
        var limit = 300;

        // get collections this guy has
        var result = await makeOpenSeaAPIRequestWithRetries(
          `https://api.opensea.io/api/v1/collections?offset=${offset}&limit=${limit}&asset_owner=${asset_owner}`
        );
        // find the specified collection
        if (!result.data?.length) {
          iso.error('error: could not find any collections for slug ' + slug + ' with owner ' + asset_owner);
          continue;
        }

        for (var collection of result.data) {
          // now match any that we are looking for
          if (slugsFinding[collection.slug]) {
            // ok we found one!
            iso.info('found collection ' + collection.slug);
            // prepare standard info

            var collectionData: CollectionData = {
              slug: collection.slug,
              image_url: collection.image_url,
              details: {
                name: collection.name,
                blockchain: Blockchain.ETHEREUM, // OpenSea API v1 only returns results for ethereum
                created_date: collection.created_date,
                description: collection.description,
                discord_url: collection.discord_url,
                external_url: collection.external_url,
                large_image_url: collection.large_image_url,
                banner_image_url: collection.banner_image_url,
                medium_username: collection.medium_username,
                twitter_username: collection.twitter_username,
                instagram_username: collection.instagram_username,
              },
            };

            var srcStats = collection.stats;
            collectionData.seven_day_volume = srcStats.seven_day_volume;
            collectionData.stats = {
              one_day_volume: srcStats.one_day_volume,
              one_day_change: srcStats.one_day_change,
              one_day_sales: srcStats.one_day_sales,
              one_day_average_price: srcStats.one_day_average_price,
              seven_day_volume: srcStats.seven_day_volume,
              seven_day_change: srcStats.seven_day_change,
              seven_day_sales: srcStats.seven_day_sales,
              seven_day_average_price: srcStats.seven_day_average_price,
              total_volume: srcStats.total_volume,
              total_sales: srcStats.total_sales,
              total_supply: srcStats.total_supply,
              num_owners: srcStats.num_owners,
              average_price: srcStats.average_price,
              market_cap: srcStats.market_cap,
              floor_price: srcStats.floor_price,
            };

            slugsFound[collectionData.slug] = collectionData;
            delete slugsFinding[collectionData.slug];
          }
        }
      } catch (e) {
        iso.logError(e);
        iso.error('error: during lookup assets with slug: ' + slug);

        if (options?.onCollectionError) {
          options.onCollectionError(e);
        }
        continue;
      }
    }
  }

  for (var slug in slugsFound) {
    var collectionData = slugsFound[slug];

    if (slug == 'cryptopunks' || slug == 'meebits') {
      if (collectionData.details) {
        collectionData.details.discord_url = 'https://discord.gg/tQp4pSE';
        collectionData.details.twitter_username = 'larvalabs';
      }
    }

    collectionDatas.push(collectionData);
  }

  for (var slug in slugsFinding) {
    iso.warn('loading no stats for ' + slug);

    // The OpenSea v1 API will throws an error if trying to get data
    // for anything else than an ethereum collection
    if (projectsJson?.lookup[slug]?.chain !== 'ethereum') {
      continue;
    }

    try {
      var find = await getCollectionDataSingle(slug, false);
      if (find) {
        collectionDatas.push(find);
      } else {
        iso.error('could not load at all for ' + slug);
      }
    } catch (e) {
      iso.logError(e);
      iso.error('error: could not load at all for ' + slug);

      if (options?.onCollectionError) {
        options.onCollectionError(e);
      }
    }
  }

  return collectionDatas;
}

function valueIsNotNull<TValue>(value: TValue | null): value is TValue {
  return value !== null;
}

async function fetchCollectionData(slug: string): Promise<CollectionWithStats | null> {
  try {
    const url = `https://api.opensea.io/api/v1/collection/${slug}`;
    const result = await makeOpenSeaAPIRequestWithRetries<StatsEndpointReturn>(url);

    if (!result.data?.collection) {
      return null;
    }

    return result.data.collection;
  } catch (error) {
    iso.error(error);
    return null;
  }
}

interface GetCollectionDataArgs {
  collections: { slug: string; openSeaSlug?: string; blockchain?: Blockchain }[];
  options?: { onCollectionError: (err: unknown) => void };
}

export async function getCollectionData({ collections, options }: GetCollectionDataArgs): Promise<CollectionData[]> {
  const promises = collections.map(async (collection) => {
    const slug = collection.openSeaSlug || collection.slug;

    iso.debug('loading collection: ' + slug);
    const collectionData = await fetchCollectionData(slug);

    if (!collectionData) {
      const error = new Error(`error: could not find any stats for collection with slug: ${slug}`);
      iso.error(error);
      options?.onCollectionError?.(error);
      return null;
    }

    return {
      slug: collectionData.slug,
      image_url: collectionData.image_url,
      details: {
        name: collectionData.name,
        blockchain: collection.blockchain,
        created_date: collectionData.created_date,
        description: collectionData.description,
        discord_url: collectionData.discord_url,
        external_url: collectionData.external_url,
        large_image_url: collectionData.large_image_url,
        banner_image_url: collectionData.banner_image_url,
        medium_username: collectionData.medium_username,
        twitter_username: collectionData.twitter_username,
        instagram_username: collectionData.instagram_username,
      },
      seven_day_volume: collectionData.stats.seven_day_volume,
      stats: collectionData.stats,
    } as CollectionData;
  });

  const collectionDatas: (CollectionData | null)[] = await Promise.all(promises);

  return collectionDatas.filter(valueIsNotNull);
}
