
















import AppVue from '@/components/AppVue';
import { Component } from 'nuxt-property-decorator';

@Component({
  data() {
    return {
      ad: null,
    };
  },
  mounted() {
    fetch(`${process.env.DATA_ORIGIN}/api/sda/728x90`)
      .then(res => res.json())
      // @ts-ignore
      .then(ad => (this.ad = ad));
  },
})
export default class Leaderboard extends AppVue {}
